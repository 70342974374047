<script setup>
import { useRoute } from "vue-router";
import ProyectsTable from "../../components/Proyects/ProyectsTable.vue";

const route = useRoute();
console.log("RUTA EDIT PROYECT PAPA");
</script>
<template>
  <div class="">
    <h5 class="create-title my-4">Proyectos Registrados</h5>
    <div>
      <ProyectsTable></ProyectsTable>
    </div>
  </div>
</template>
