<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import imgLogo from '@/assets/logo-nateevo.svg'
const router = useRouter();
const showMenu = localStorage.getItem("a") === "aptd1";
const showMenuA = localStorage.getItem("r") === "admin0" || showMenu;
console.log(showMenu, showMenuA);
const menuItems = [
  {
    title: "Usuarios",
    route: "/dashboard/users",
    isShow: showMenuA,
    content: [{ title: "Crear", route: "/dashboard/users/create" }],
  },
  {
    title: "Contratos",
    route: "/dashboard/contracts",
    isShow: showMenuA,
    content: [{ title: "Crear", route: "/dashboard/contracts/create" }],
  },
  {
    title: "Reglas",
    route: "/dashboard/rules",
    isShow: true,
    content: [{ title: "Crear", route: "/dashboard/rules/create" }],
  },
  {
    title: "Proyectos",
    route: "/dashboard/proyects",
    isShow: true,
    content: [{ title: "Crear", route: "/dashboard/proyects/create" }],
  },
  {
    title: "Canales",
    route: "/dashboard/channels",
    isShow: true,
    content: [
      { title: "Crear", route: "/dashboard/channels/create" },
      /* { title: "Editar", route: "/dashboard/channels/edit" }, */
    ],
  },
  /*   {
    title: "Data",
    route: "/dashboard/data",
    isShow: showMenuA,
    content: [
      { title: "Estructura de Datos", route: "/dashboard/data/structure" },
      { title: "Esquemas", route: "/dashboard/data/schemes" },
    ],
  }, */
  {
    title: "Clientes",
    route: "/dashboard/clients",
    isShow: showMenu,
    content: [{ title: "Crear", route: "/dashboard/clients/create" }],
  },
  {
    title: "Listas",
    route: "/dashboard/lists",
    isShow: showMenu,
    content: [{ title: "Crear", route: "/dashboard/lists/create" }],
  },
  { title: "Sección API", isShow: true, route: "/dashboard/api" },
  {
    title: "Configuraciones",
    isShow: showMenuA,
    route: "/dashboard/settings",
    /* content:[
        { title: 'Credenciales', route: '/dashboard/settings/credentials'},
    ]  */
  },
];

const activeItem = ref(-1);
function toggleItem(index, afterToggle) {
  activeItem.value = activeItem.value === index ? -1 : index;
  if (afterToggle) afterToggle();
}

function handleButtonClick(contentItem) {
  console.log("Botón clickeado:", contentItem.title);
  // redirigir a página específica
  router.push(contentItem.route);
}
</script>
<template>
  <aside class="menu">
    <img :src="imgLogo" width="200">
    <a class="globals-a" href="/dashboard" title="Go to homepage">
      <h3 class="menu-title" style="text-wrap: wrap">Golden Contactability</h3>
    </a>
    <h5
      class="text-uppercase"
      style="
        font-size: large;
        padding-left: 10px;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        letter-spacing: 4px;
      "
    >
      Menu
    </h5>
    <div>
      <ul class="accordion">
        <li
          class="accordion-item"
          v-for="(item, index) in menuItems"
          :key="index"
        >
          <button
            v-if="item.isShow"
            class="accordion-button"
            type="button"
            @click="toggleItem(index, () => router.push(item.route))"
          >
            {{ item.title }}
            <!-- <i class="accordion-icon" :class="{ 'rotate': activeItem === index }"></i> -->
          </button>
          <div
            v-if="activeItem === index"
            class="accordion-collapse collapse show"
          >
            <div class="accordion-body d-flex flex-column">
              <button
                class="menu-button"
                v-for="(contentItem, contentIndex) in item.content"
                :key="contentIndex"
                @click="handleButtonClick(contentItem)"
              >
                {{ contentItem.title }}
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </aside>
</template>
<style scoped>
/* .accordion-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(0deg);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #ffffff transparent transparent transparent;
  transition: transform 0.3s ease;
} */

/* .rotate {
  transform: translateY(-50%) rotate(-180deg);
} */
</style>
