<script setup>
import { useRoute } from 'vue-router';
import CreateClientForm from '../../components/Clients/CreateClientForm.vue';

const route = useRoute()
console.log("RUTA CREATE Client PAPA")

</script>
<template>
    <div v-if="route.name === 'DashboardClientCreate'" class="">
        <h5 class="create-title my-4">Crear Cliente</h5>
        <div>
            <CreateClientForm></CreateClientForm>
        </div>
    </div>
</template>