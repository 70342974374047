<script setup>
import { ref, onMounted, nextTick } from "vue";
import {
  CalendarDaysIcon,
  ChartBarIcon,
  CheckCircleIcon,
  CursorArrowRippleIcon,
  ExclamationCircleIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  LinkIcon,
  PlusCircleIcon,
  ShareIcon,
  Square3Stack3DIcon,
  UserGroupIcon,
  XCircleIcon,
} from "@heroicons/vue/24/solid";
import { useRoute } from "vue-router";
import { getUser } from "@/services/auth";
import { getReportsbyChannels } from "@/services/reports"
console.log("RUTA Home");
const route = useRoute();

const currentDate = new Date().toLocaleDateString();


const arrayReport1 = ref([])
const isDropdownOpen = ref(false);
/* const selectedKey = ref(null); */
const user = ref(null);

//Traer datos del usuario
onMounted(async () => {
  const authToken = localStorage.getItem("authToken");
  try {
    user.value = await getUser(authToken)
    const arrayReportOBJ = await getReportsbyChannels()
    arrayReport1.value = arrayReportOBJ.data
    console.log('arrayReport1', arrayReport1.value)

  } catch (error) {
    console.error(error);
  }
});
/* const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
}; */
/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});
/* function selectKey(key) {
  selectedKey.value = key;
} */
const selectedTab = ref("proyectos"); // inicializa con el primer botón seleccionado

function selectTab(tab) {
  selectedTab.value = tab;
}

const numeroContenedor1 = ref(null);
const numero1 = ref(null);
const numeroContenedor2 = ref(null);
const numero2 = ref(null);
const numeroContenedor3 = ref(null);
const numero3 = ref(null);
const numeroContenedor4 = ref(null);
const numero4 = ref(null);

function animateCount(element, finalValue) {
  let count = 0;
  const interval = setInterval(() => {
    count += 100;
    element.textContent = count.toLocaleString();
    if (count >= finalValue) {
      clearInterval(interval);
      element.textContent = finalValue.toLocaleString();
    }
  }, 10);
}

onMounted(() => {
  nextTick(() => {
    numeroContenedor1.value.classList.add("animar");
    animateCount(numero1.value, 34000);
  });

  nextTick(() => {
    setTimeout(() => {
      numeroContenedor2.value.classList.add("animar");
      animateCount(numero2.value, 12000);
    }, 500);
  });

  nextTick(() => {
    setTimeout(() => {
      numeroContenedor3.value.classList.add("animar");
      animateCount(numero3.value, 5000);
    }, 1000);
  });
  nextTick(() => {
    setTimeout(() => {
      numeroContenedor4.value.classList.add("animar");
      animateCount(numero4.value, 1000);
    }, 1000);
  });
});
</script>
<template>
  <div v-if="route.name === 'Home'" class="d-flex justify-content-end">
    <div style="width: 75%; padding: 2rem">
      <header>
        <h4 class="mb-4" style="color: #182631">
          ¡Hola de nuevo
          <span style="font-weight: 700">{{ user ? user.name : "" }}</span
          >!
        </h4>
        <div class="d-flex align-items-center justify-content-between gap-3">
          <h3 style="color: #182631">Uso del Servicio de Contactabilidad</h3>
        </div>
      </header>
      <main>
        <div>
          <div
            class="d-flex justify-content-between align-items-center mt-2"
          ></div>
          <div class="d-flex justify-content-between">
            <div class="card" style="justify-content: center">
              <div class="d-flex justify-content-center align-items-center">
                <UserGroupIcon
                  style="width: 40px; height: 40px"
                ></UserGroupIcon>
                <h5 style="margin-bottom: 0; text-align: center">
                  Uso de Servicio de Contactabilidad
                </h5>
              </div>
              <div
                class="numero-contenedor"
                :ref="(el) => (numeroContenedor1 = el)"
              >
                <span class="numero" :ref="(el) => (numero1 = el)">0</span>
              </div>
            </div>
            <div class="card" style="justify-content: center">
              <div
                class="d-flex justify-content-center align-items-center gap-3"
              >
                <ChartBarIcon style="width: 40px; height: 40px"></ChartBarIcon>
                <h5 style="margin-bottom: 0; text-align: center">
                  Uso de Servicio de Seguimiento
                </h5>
              </div>
              <div
                class="numero-contenedor"
                :ref="(el) => (numeroContenedor2 = el)"
              >
                <span class="numero" :ref="(el) => (numero2 = el)">0</span>
              </div>
            </div>
            <div>
              <div class="card" style="justify-content: center">
                <div
                  class="d-flex gap-4 justify-content-center align-items-center"
                >
                  <HandThumbUpIcon
                    style="width: 30px; height: 30px"
                  ></HandThumbUpIcon>
                  <h6 style="margin-bottom: 0">Total de aceptados</h6>
                </div>
                <div
                  class="numero-contenedor"
                  :ref="(el) => (numeroContenedor3 = el)"
                >
                  <span class="numero-2" :ref="(el) => (numero3 = el)">0</span>
                </div>
              </div>
              <div
                class="card"
                style="justify-content: center; background: #243949"
              >
                <div
                  class="d-flex gap-4 justify-content-center align-items-center"
                >
                  <HandThumbDownIcon
                    style="width: 30px; height: 30px"
                  ></HandThumbDownIcon>
                  <h6 style="margin-bottom: 0; text-wrap: nowrap">
                    Total de <span style="font-weight: 700">no</span> aceptados
                  </h6>
                </div>
                <div
                  class="numero-contenedor"
                  :ref="(el) => (numeroContenedor4 = el)"
                >
                  <span class="numero-2" :ref="(el) => (numero4 = el)">0</span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-flex justify-content-between mt-4"
            style="height: 16rem"
          >
            <div class="d-flex gap-4 position-relative">
              <!-- *CARD -->
              <div
                class="card"
                style="
                  background: rgb(216, 224, 228);
                  color: #243949;
                  width: 30%;
                  height: 230px;
                "
              >
                <h5 style="font-weight: 800; margin-top: 1rem">PROYECTOS</h5>
                <!-- !CARROUSEL -->
                <div
                  id="carouselExampleSlidesOnly"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <CheckCircleIcon
                          style="width: 30px; height: 30px"
                        ></CheckCircleIcon>
                        <h5 style="font-size: medium; margin-bottom: 0">
                          Cuentas con
                          <span style="font-weight: 700">{{ arrayReport1.projects }}</span> Proyectos
                          registrados
                        </h5>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <CursorArrowRippleIcon
                          style="width: 30px; height: 30px"
                        ></CursorArrowRippleIcon>
                        <h5 style="font-size: medium; margin-bottom: 0">
                          <span style="font-weight: 700">20</span> Proyectos
                          cuentan con prioridades
                        </h5>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <UserGroupIcon
                          style="width: 30px; height: 30px"
                        ></UserGroupIcon>
                        <h5 style="font-size: medium; margin-bottom: 0">
                          Tus proyectos cuentan con
                          <span style="font-weight: 700">20</span> Clientes
                          distintos
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <p class="m-0">En total</p>
                  <a href="/dashboard/proyects" class="second-link"
                    >IR AL LISTADO</a
                  >
                </div>
              </div>

              <!-- *CARD -->
              <div
                class="card"
                style="
                  background: rgb(216, 224, 228);
                  color: #243949;
                  width: 30%;
                  height: 230px;
                "
              >
                <h5 style="font-weight: 800; margin-top: 1rem">CANALES</h5>
                <!-- !CARROUSEL -->
                <div
                  id="carouselExampleSlidesOnly"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <CheckCircleIcon
                          style="width: 30px; height: 30px"
                        ></CheckCircleIcon>
                        <h5 style="font-size: medium; margin-bottom: 0">
                          Cuentas con
                          <span style="font-weight: 700">{{ arrayReport1.channels }}</span> Canales
                          registrados
                        </h5>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <LinkIcon style="width: 30px; height: 30px"></LinkIcon>
                        <h5 style="font-size: medium; margin-bottom: 0">
                          Tienes
                          <span style="font-weight: 700">10</span> Proyectos
                          distintos vinculados a tus Canales.
                        </h5>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <PlusCircleIcon
                          style="width: 30px; height: 30px"
                        ></PlusCircleIcon>
                        <h5 style="font-size: medium; margin-bottom: 0">
                          Tienes
                          <span style="font-weight: 700">4</span> Canales nuevos
                          registrados en los últimos 30 días
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <p class="m-0">En total</p>
                  <a href="/dashboard/channels" class="second-link"
                    >IR AL LISTADO</a
                  >
                </div>
              </div>

              <!-- *CARD -->
              <div
                class="card"
                style="
                  background: rgb(216, 224, 228);
                  color: #243949;
                  width: 30%;
                  height: 230px;
                "
              >
                <h5 style="font-weight: 800; margin-top: 1rem">REGLAS</h5>
                <!-- !CARROUSEL -->
                <div
                  id="carouselExampleSlidesOnly"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <CheckCircleIcon
                          style="width: 30px; height: 30px"
                        ></CheckCircleIcon>
                        <h5 style="font-size: medium; margin-bottom: 0">
                          Cuentas con
                          <span style="font-weight: 700">{{ arrayReport1.rules }}</span> Reglas
                          registradas
                        </h5>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <LinkIcon style="width: 30px; height: 30px"></LinkIcon>
                        <h5 style="font-size: medium; margin-bottom: 0">
                          Tienes
                          <span style="font-weight: 700">10</span> Proyectos
                          distintos vinculados a tus Reglas.
                        </h5>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div
                        class="d-flex gap-2 justify-content-between align-items-center my-2"
                      >
                        <div class="d-flex flex-column">
                          <div
                            class="d-flex gap-3 justify-content-center align-items-center"
                          >
                            <CalendarDaysIcon
                              style="width: 20px; height: 20px"
                            ></CalendarDaysIcon>
                            <p style="margin-bottom: 0 !important">
                              <span style="font-weight: 700">20</span>
                              Reglas vigentes.
                            </p>
                          </div>
                          <div
                            class="d-flex gap-3 justify-content-center align-items-center"
                          >
                            <XCircleIcon
                              style="width: 20px; height: 20px"
                            ></XCircleIcon>
                            <p style="margin-bottom: 0 !important">
                              <span style="font-weight: 700">20</span>
                              Reglas vencidas.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <p class="m-0">En total</p>
                  <a href="/dashboard/rules" class="second-link"
                    >IR AL LISTADO</a
                  >
                </div>
              </div>

              <div
                class="card"
                style="
                  background: rgb(195, 115, 115);
                  position: absolute;
                  right: -1%;
                  top: -12%;
                  max-width: 15rem;
                  height: 4rem;
                "
              >
                <div
                  class="d-flex gap-2 justify-content-around align-items-center"
                >
                  <ExclamationCircleIcon
                    style="width: 30px; height: 30px"
                  ></ExclamationCircleIcon>
                  <h6
                    style="
                      font-size: smaller;
                      text-wrap: wrap;
                      margin-bottom: 0;
                    "
                  >
                    Tienes
                    <span style="font-weight: 800">5</span> Reglas por expirar
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <!-- !TABLA -->
          <article class="article">
            <div
              class="d-flex align-items-center justify-content-between position-relative"
            >
              <div
                style="width: 100%; display: flex; flex-direction: row-reverse"
              >
                <div class="button-group">
                  <button class="button" @click="selectTab('proyectos')">
                    Proyectos
                  </button>
                  <button class="button" @click="selectTab('canales')">
                    Canales
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex gap-3 justify-content-around">
              <div v-if="selectedTab === 'proyectos'" class="match-rate">
                <div class="d-flex gap-3 align-items-center my-3">
                  <Square3Stack3DIcon
                    style="width: 30px; height: 30px"
                  ></Square3Stack3DIcon>
                  <h5
                    style="color: #182631; font-weight: 700; margin-bottom: 0"
                  >
                    Proyectos
                  </h5>
                </div>
                <div class="rounded-lg table-container" style="width: auto">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Solicitudes</th>
                        <th>Aceptadas</th>
                        <th>Rechazadas</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="text-align: start">Proyecto 1</td>
                        <td>2300</td>
                        <td>1200</td>
                        <td>7000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="selectedTab === 'canales'" class="match-rate-2">
                <div class="d-flex gap-3 align-items-center my-3">
                  <ShareIcon style="width: 30px; height: 30px"></ShareIcon>
                  <h5
                    style="
                      color: rgb(216, 224, 228);
                      font-weight: 700;
                      margin-bottom: 0;
                    "
                  >
                    Canales
                  </h5>
                </div>

                <div class="rounded-lg table-container" style="width: auto">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="th-second">Nombre</th>
                        <th class="th-second">Solicitudes</th>
                        <th class="th-second">Aceptadas</th>
                        <th class="th-second">Rechazadas</th>
                        <th class="th-second">Proyectos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="td-second" style="text-align: start">
                          Proyecto 1
                        </td>
                        <td class="td-second">2300</td>
                        <td class="td-second">1200</td>
                        <td class="td-second">7000</td>
                        <td class="td-second">Proyecto 1</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </article>
        </div>
      </main>
    </div>
  </div>
</template>
<style scoped>
.td-second {
  color: rgb(216, 224, 228);
}
th {
  text-wrap: nowrap;
}
.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #517fa4;
  color: whitesmoke;
  padding: 0px 10px 0px 10px;
  font-size: smaller;
  margin: 0 5px 0 5px;
}
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.button-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(239, 242, 245);
  padding: 5px;
  border-radius: 50px;
}

.button {
  background: transparent;
  color: #243949;
  border: none;
  padding: 5px 10px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  text-wrap: nowrap;
  font-weight: 700;
}

.button:hover {
  background-color: rgb(216, 224, 228);
  border-radius: 50px;
}

.card {
  background: #182631;
  border-radius: 20px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  max-width: 25rem;
  justify-content: space-between;
}
.link {
  color: rgb(216, 224, 228);
  text-decoration: none;
  font-weight: 600;
  &:hover {
    color: #99bbd6;
    transition: all ease-in-out 0.3s;
  }
}
.second-link {
  color: rgb(95, 109, 117);
  text-decoration: none;
  font-weight: 600;
  &:hover {
    color: #243949;
    transition: all ease-in-out 0.3s;
  }
}
.article {
  background-color: rgb(239, 242, 245);
  border-radius: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
}
.match-rate {
  background-color: rgb(216, 224, 228);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
}
.match-rate-2 {
  background-color: #243949;
  color: rgb(216, 224, 228);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
}
.collect-users {
  padding: 15px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background: transparent;
}

th {
  background-color: #243949;
  color: #ddd;
}
.th-second {
  background-color: #ddd;
  color: #243949;
}

.article-table {
  background-color: #243949;
  border-radius: 15px;
  color: white;
  margin-top: 40px;
  padding: 10px;
}

.numero-contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
}

.numero {
  font-size: x-large;
  font-weight: bold;
  animation: contar 2s ease-in-out;
}
.numero-2 {
  font-size: large;
  font-weight: bold;
  animation: contar 2s ease-in-out;
}

@keyframes contar {
  0% {
    content: "0";
  }
  100% {
    content: "34,000";
  }
}

.rounded-lg {
  border-radius: 10px;
  overflow: hidden;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
</style>
