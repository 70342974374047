<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import {
  DocumentCheckIcon,
  PencilSquareIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/vue/24/solid";
import EditRuleForm from "@/components/Rules/EditRuleForm";
import { getRules, deleteRules } from "@/services/rules";
import { getProjects } from "@/services/projects";
import { Toast } from "bootstrap";

const isDropdownOpen = ref(false);
const showEditRuleForm = ref(false);
const offcanvasEditRuleFormRef = ref(null);
/* const { data } = defineProps(); */

const selectedRule = ref(null);
const rules = ref([]);
const projects = ref([]);
const showSuccessMessage = ref(false);
const isRuleEdited = ref(false);
const isLoading = ref(true);

onMounted(async () => {
  isLoading.value = true;
  const clientId = localStorage.getItem("cx");
  console.log("Client ID:", clientId);
  if (!clientId) {
    console.error("Client ID not found in localStorage");
    isLoading.value = false;
    return;
  }
  try {
    const response = await getRules(clientId);
    console.log("Fetched rules:", response);
    if (response.data.length) {
      rules.value = response.data;
    }
    const projectsResponse = await getProjects(clientId);
    console.log("Fetched projects:", projectsResponse);
    if (projectsResponse.data.length) {
      projects.value = projectsResponse.data;
    }
  } catch (error) {
    console.error("Error fetching rules:", error);
  } finally {
    isLoading.value = false; // Detener el spinner independientemente del resultado
  }
});

const getProjectNameById = (projectId) => {
  const project = projects.value.find((p) => p.projectsId === projectId);
  return project ? project.name : "";
};

const getFrequency = (rule) => {
  let frequency = "";
  if (rule.monday) frequency += "Lun, ";
  if (rule.tuesday) frequency += "Mar, ";
  if (rule.wednesday) frequency += "Mié, ";
  if (rule.thursday) frequency += "Jue, ";
  if (rule.friday) frequency += "Vie, ";
  if (rule.saturday) frequency += "Sáb, ";
  if (rule.sunday) frequency += "Dom, ";
  if (frequency.length > 0) {
    frequency = frequency.slice(0, -2);
  }
  return frequency;
};

// concatenar el horario
/* const getSchedule = (rule) => {
  if (rule.startSchedule && rule.endSchedule) {
    return `De ${rule.startSchedule} a ${rule.endSchedule}`;
  } else {
    return "―";
  }
}; */

// concatenar la vigencia
const getValidityPeriod = (rule) => {
  if (rule.startDate && rule.endDate) {
    const startDate = new Date(rule.startDate);
    const endDate = new Date(rule.endDate);

    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formatter = new Intl.DateTimeFormat("es-ES", options);

    const formattedStartDate = formatter.format(startDate);
    const formattedEndDate = formatter.format(endDate);

    return `${formattedStartDate} – ${formattedEndDate}`;
  } else {
    return "Sin Vigencia";
  }
};

/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const deletedRuleName = ref("");
const deleteToast = ref(null);

onMounted(() => {
  deleteToast.value.addEventListener("shown.bs.toast", () => {
    setTimeout(() => {
      deleteToast.value.classList.remove("show");
    }, 3000); // Ocultar el toast después de 3 segundos
  });
  console.log("DELETE TOAST:", deleteToast.value);
  console.log("DELETE TOAST ID:", deleteToast.value.id);
});

const deleteRule = async (element) => {
  try {
    console.log("delete", element);
    await deleteRules(element.rulesId);

    deletedRuleName.value = element.name;
    const bsToast = new Toast(deleteToast.value);
    bsToast.show();
    console.log(`Rule ${element.rulesId} deleted successfully`);
  } catch (error) {
    console.error("Error deleting rule:", error);
  }
};

function toggleEditRuleForm(rule) {
  showEditRuleForm.value = !showEditRuleForm.value;

  selectedRule.value = rule;
  console.log("Selected Rule:", selectedRule.value);

  setTimeout(() => {
    showEditRuleForm.value = true;
  }, 0);
  showSuccessMessage.value = false;
}

const handleKeySaved = async (newData) => {
  console.log("Datos de la regla actualizada:", newData);
  //Actualizar regla en tabla
  const clientId = localStorage.getItem("cx");
  if (!clientId) {
    console.error("Client ID not found in localStorage");
    return;
  }

  try {
    const response = await getRules(clientId);
    if (response.data.length) {
      rules.value = response.data;
    }
  } catch (error) {
    console.error("Error fetching updated rules:", error);
  }

  showEditRuleForm.value = false;
  isRuleEdited.value = true;
  showSuccessMessage.value = true;
};

const closeOffcanvas = () => {
  showEditRuleForm.value = false;
  if (!isRuleEdited.value) {
    showSuccessMessage.value = false;
  }
};

const handleEscape = (event) => {
  if (event.key === "Escape") {
    closeOffcanvas();
  }
};
onMounted(() => {
  window.addEventListener("keydown", handleEscape);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscape);
});

// Watcher para detectar cambios en showEditRuleForm
watch(showEditRuleForm, (newValue) => {
  if (!newValue) {
    setTimeout(() => {
      const offcanvasElement = document.querySelector("#offcanvasEditRuleForm");
      offcanvasElement.classList.add("show"); // Mostrar el offcanvas
    }, 100);
  }
});
</script>
<template>
  <div class="rounded-lg table-container">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Nombre</th>
          <th>Frecuencia</th>
          <!--th>Horario</th-->
          <th>Proyecto Asignado</th>
          <th>Vigencia</th>
          <th colspan="2">Modalidad de Contacto</th>
          <!-- <th colspan="3">
            Veces a contactar por
            <span style="text-decoration: underline; font-weight: 800"
              >Semana</span
            >
          </th> -->
          <th class="actions-column"></th>
        </tr>
        <tr class="under-th">
          <th></th>
          <th></th>
          <!--th></th-->
          <th></th>
          <th></th>
          <th></th>

          <th>Mensual</th>
          <th>Semanal</th>

          <!-- <th>Email</th>
          <th>SMS/Push</th>
          <th>Call Center</th> -->
          <th class="actions-column"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td colspan="7" class="text-center">
            <div
              class="spinner-border mt-4"
              style="width: 3rem; height: 3rem; color: #517fa4"
              role="status"
            >
              <span class="visually-hidden">Cargando...</span>
            </div>
            <p
              class="mt-3"
              style="color: #243949; font-size: large; font-weight: 600"
            >
              Cargando Reglas, por favor espera...
            </p>
          </td>
        </tr>
        <!-- mensaje si no hay contratos -->
        <tr v-else-if="rules.length === 0">
          <td
            colspan="7"
            class="text-center"
            style="color: #243949; font-size: large; font-weight: 600"
          >
            No hay reglas disponibles.
          </td>
        </tr>
        <tr v-for="rule in rules" :key="rule.id">
          <td>
            <div class="d-flex justify-content-center">
              <DocumentCheckIcon
                style="
                  color: #517fa4;
                  width: 25px;
                  height: 25px;
                  margin-right: 5px;
                "
              ></DocumentCheckIcon>
            </div>
          </td>
          <td style="font-weight: 600; color: #243949; text-align: start">
            {{ rule.name }}
          </td>
          <td style="font-weight: 400; color: gray; font-size: smaller">
            {{ getFrequency(rule) }}
          </td>
          <!--td>{{ getSchedule(rule) }}</td-->
          <td style="text-transform: capitalize">
            {{ getProjectNameById(rule.projectsId) }}
          </td>
          <td style="text-align: center; text-transform: capitalize">
            {{ getValidityPeriod(rule) }}
          </td>

          <td style="text-align: center" v-if="!rule.isMonth"></td>
          <td style="text-align: center" v-else>
            <CheckCircleIcon
              style="
                color: #517fa4;
                width: 25px;
                height: 25px;
                margin-right: 5px;
              "
            />
          </td>

          <td style="text-align: center" v-if="!rule.isMonth">
            <CheckCircleIcon
              style="
                color: #517fa4;
                width: 25px;
                height: 25px;
                margin-right: 5px;
              "
            />
          </td>
          <td style="text-align: center" v-else></td>

          <td
            class="d-flex gap-3 actions-column mx-2"
            style="margin-top: 3px; border-bottom: none"
          >
            <button
              class="buttons-group"
              @click="toggleEditRuleForm(rule)"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasEditRuleForm"
              aria-controls="offcanvasEditRuleForm"
            >
              <PencilSquareIcon
                class="text-blue-500 d-flex justify-content-center"
                style="color: #517fa4; width: 20px; height: 20px"
              ></PencilSquareIcon>
            </button>

            <button class="buttons-group delete" @click="deleteRule(rule)">
              <TrashIcon
                class="text-blue-500 d-flex justify-content-center"
                style="width: 20px; height: 20px"
              ></TrashIcon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- ... -->
  <div
    class="toast align-items-center position-fixed top-50 end-0 p-2 w-auto"
    style="background: #243949; color: white; width: auto"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    id="deleteToast"
    ref="deleteToast"
  >
    <div class="d-flex">
      <div class="toast-body">
        Se ha eliminado la regla
        <span
          style="
            font-weight: 600;
            text-decoration: underline;
            text-transform: capitalize;
          "
        >
          {{ deletedRuleName }}
        </span>
        con éxito.
      </div>
      <button
        type="button"
        class="btn-close me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div
    class="offcanvas offcanvas-end"
    style="width: 45%"
    data-bs-scroll="true"
    tabindex="-1"
    id="offcanvasEditRuleForm"
    aria-labelledby="offcanvasEditRuleFormLabel"
    ref="offcanvasEditRuleFormRef"
  >
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body" v-if="showEditRuleForm">
      <EditRuleForm
        :offcanvasRef="offcanvasEditRuleFormRef"
        :newKeyData="selectedRule"
        @keySaved="handleKeySaved"
        @cancelEdit="closeOffcanvas"
      ></EditRuleForm>
    </div>
    <!-- *Mensaje -->
    <div
      v-if="showSuccessMessage"
      class="offcanvas-body"
      style="text-align: center; margin-top: 8rem"
    >
      <CheckCircleIcon
        style="width: 5rem; height: 5rem; color: #517fa4"
      ></CheckCircleIcon>
      <h4 class="create-title mt-3">La Regla se editó con éxito.</h4>
      <button
        class="globals-border-button"
        data-bs-dismiss="offcanvas"
        style="margin-top: 3rem"
      >
        Salir
      </button>
    </div>
  </div>
</template>
<style scoped>
.actions-column {
  position: sticky;
  right: 0;
  margin-left: 4px;
  margin-right: 4px;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
}
.delete:hover {
  background-color: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
}
.rounded-lg {
  border-radius: 10px;
  overflow: hidden;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px 15px 6px 15px;
  margin-top: 3px;
  margin: 4px;
  text-align: start;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

th {
  background-color: #ddd;
  color: #243949;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 4px;
  margin: 3px 2px 3px 2px;
}

.under-th {
  height: 10px;
  font-size: x-small;
  padding: 2px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
</style>
