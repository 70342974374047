<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import CreateChannel from "./CreateChannel.vue";
import EditChannel from "./EditChannel.vue";

const route = useRoute();

const showCreateChannel = computed(() => {
  return route.name === "DashboardChannelCreate";
});

const showEditChannel = computed(() => {
  return (
    !showCreateChannel.value &&
    (route.name === "DashboardChannels" ||
      route.name === "DashboardChannelsEdit")
  );
});

console.log("DASHBOARD CLIENTS");
</script>
<template>
  <div class="d-flex justify-content-end" style="">
    <div class="px-4" style="width: 75%">
      <h2 class="dashboard-title">Canales</h2>
      <CreateChannel v-if="showCreateChannel" :route="route"></CreateChannel>
      <EditChannel v-if="showEditChannel" :route="route"></EditChannel>
    </div>
  </div>
</template>
<style scoped></style>
