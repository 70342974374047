<script setup>
/* import { useRoute } from "vue-router"; */
import RulesTable from "@/components/Rules/RulesTable";

/* const route = useRoute(); */
console.log("RUTA CREATE RULE PAPA");
</script>
<template>
  <div class="">
    <h5 class="create-title my-4">Reglas Registradas</h5>
    <div>
      <RulesTable></RulesTable>
    </div>
  </div>
</template>
