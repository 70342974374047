<script setup>
import { ref, onMounted, watchEffect } from "vue";
import Avatar from "./common/Avatar.vue";
import { useRouter } from "vue-router";
import { getUser } from "@/services/auth";
import { CameraIcon } from "@heroicons/vue/24/solid";

const avatarApi = ref("https://api.dicebear.com/8.x/open-peeps/svg");
const isDropdownOpen = ref(false);
const router = useRouter();
const user = ref(null);

const userName = ref("");
const userEmail = ref("");

watchEffect(() => {
  if (user.value) {
    userName.value = user.value.name;
    userEmail.value = user.value.email;
  } else {
    userName.value = "";
    userEmail.value = "";
  }
});
//Traer datos del usuario
onMounted(async () => {
  const authToken = localStorage.getItem("authToken"); // Obtener el token de sessionStorage
  try {
    user.value = await getUser(authToken);
  } catch (error) {
    console.error(error);
  }
});

function toggleDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value;
}
/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});
/* Logout */
const logout = () => {
  isDropdownOpen.value = false;
  localStorage.removeItem("authToken")
  localStorage.removeItem("r")
  localStorage.removeItem('a')
  localStorage.clear();
  router.push("/login");
};

const isModalOpen = ref(false);

const selectedImage = ref(null);
const imageInput = ref(null);

function selectImage() {
  imageInput.value.click();
}

function handleImageChange(event) {
  selectedImage.value = event.target.files[0];
  avatarApi.value = URL.createObjectURL(selectedImage.value);
}

function handleClick() {
  isModalOpen.value = true;
}

function closeModal() {
  isModalOpen.value = false;
  location.reload();
}

const isEditing = ref(false);

function handleEditClick() {
  isEditing.value = true;
}
</script>
<template>
  <header>
    <nav class="mx-5">
      <div class="container-fluid">
        <div
          class="d-flex gap-5 justify-content-between align-items-center w-100"
        >
          <div class="">
            <h1 class="m-0 header-logo">GC</h1>
          </div>
          <div class="collapse d-flex justify-content-around w-auto h-100">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <div
                  class="dropdown-toggle d-flex gap-3 justify-content-center align-items-center"
                  @click="toggleDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Avatar :src="avatarApi" alt="avatar"></Avatar>
                  <div class="d-flex flex-column">
                    <span class="header-name" style="font-weight: 600">{{
                      user ? user.name : ""
                    }}</span>
                    <span
                      class="header-type-user"
                      style="font-weight: 400; font-size: x-small"
                      >Usuario</span
                    >
                  </div>
                </div>
                <ul
                  class="dropdown-menu dropdown-menu-dark mt-3 px-4 z-3"
                  :class="{ show: isDropdownOpen }"
                >
                  <li>
                    <a class="dropdown-item" @click="handleClick">Perfil</a>
                  </li>
                  <li>
                    <div class="d-flex justify-content-center px-4">
                      <button
                        type="submit"
                        class="btn login-button"
                        @click="logout()"
                      >
                        Cerrar sesión
                      </button>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <!-- Modal -->
  <div class="modal" :class="{ show: isModalOpen }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div style="display: flex; flex-direction: row-reverse">
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <!-- <div class="modal-header">
          <div
            class="d-flex gap-3 flex-column w-auto justify-content-evenly align-items-center"
          >
            <Avatar
              :src="avatarApi"
              alt="avatar"
              style="width: 6rem; height: 6rem"
            ></Avatar>
            <h6 style="font-weight: 800; font-size: large">
              {{ user ? user.name : "" }}
            </h6>
          </div>
        </div> -->
        <div class="modal-body">
          <div id="carouselExample" class="carousel slide">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="modal-header">
                  <div
                    class="d-flex gap-3 flex-column w-auto justify-content-evenly align-items-center"
                  >
                    <Avatar
                      :src="avatarApi"
                      alt="avatar"
                      style="width: 6rem; height: 6rem"
                    ></Avatar>
                    <h6 style="font-weight: 800; font-size: large">
                      {{ user ? user.name : "" }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex w-100" style="font-weight: 600">
                  <!-- !hardcodeado -->
                  Administrador
                </div>
                <div class="d-flex w-100" style="font-weight: 600">
                  {{ user ? user.email : "" }}
                </div>
              </div>
              <div class="carousel-item">
                <form class="d-flex flex-column gap-3">
                  <div
                    class="d-flex gap-3 flex-column w-auto justify-content-evenly align-items-center"
                  >
                    <div style="position: relative">
                      <div
                        style="
                          border-radius: 50%;
                          width: 6rem;
                          height: 6rem;
                          background: linear-gradient(
                            to right,
                            #243949 0%,
                            #517fa4 100%
                          );
                          object-fit: fill;
                        "
                      >
                        <img
                          :src="avatarApi"
                          alt="avatar"
                          style="
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                          "
                          @click="selectImage"
                        />
                      </div>
                      <CameraIcon
                        style="
                          color: #182631;
                          width: 25px;
                          height: 25px;
                          margin-right: 5px;
                          position: absolute;
                          bottom: 0%;
                          right: -8%;
                        "
                      ></CameraIcon>
                    </div>
                    <!-- <Avatar
                      :src="avatarApi"
                      alt="avatar"
                      style="width: 6rem; height: 6rem"
                      @click="selectImage"
                    ></Avatar> -->
                    <input
                      type="file"
                      ref="imageInput"
                      @change="handleImageChange"
                      style="display: none"
                    />
                    <div class="d-flex gap-2" style="text-align: center">
                      <h6 style="text-align: center; margin-top: 5px">
                        Nombre:
                      </h6>
                      <input class="globals-input px-2" v-model="userName" />
                    </div>
                  </div>

                  <!-- !hardcodeado -->
                  <div class="d-flex gap-2" style="text-align: center">
                    <h6 style="text-align: center; margin-top: 5px">
                      Usuario:
                    </h6>
                    <select
                      id="type_user"
                      class="form-select form-select-details"
                    >
                      <option value="">Selecciona un tipo de usuario</option>
                      <option>Administrador</option>
                      <option>Editor</option>
                    </select>
                  </div>

                  <div class="d-flex gap-2" style="text-align: center">
                    <h6 style="text-align: center; margin-top: 5px">Email:</h6>
                    <input
                      class="globals-input px-2 w-100"
                      v-model="userEmail"
                    />
                  </div>
                  <!-- ... -->
                  <!-- <button class="globals-button" @click.prevent="saveChanges">
                    Guardar cambios
                  </button> -->
                </form>
              </div>
            </div>
            <div class="d-flex justify-content-evenly gap-4 my-4 w-100 mt-6">
              <button class="globals-border-button" @click="closeModal">
                Cerrar
              </button>
              <button
                v-if="!isEditing"
                type="button"
                class="globals-button"
                @click="handleEditClick"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
              >
                Editar
              </button>
              <button
                v-else
                type="button"
                class="globals-button"
                @click="saveChanges"
              >
                Guardar cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
li {
  margin: 1rem 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
}

.show {
  display: block;
}
/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: none;
}

.modal.show {
  display: block;
}

.modal-dialog {
  max-width: 500px;
  margin: 40px auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.modal-content {
  display: flex;
  justify-content: center;
  padding: 20px;
  border: none;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
}

.modal-title {
  font-weight: bold;
  font-size: 18px;
}
.modal-body {
  display: flex;
  flex-direction: column;
}

.btn-close {
  background-color: transparent;
  border: none;
  padding: 2%;
  font-size: 13px;
  cursor: pointer;
}

.btn-close:hover {
  background-color: #ccc;
}
</style>
