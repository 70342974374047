import axios from "axios";
import { dataEnv } from "../../config/index.js";
const API_BASE_URL = dataEnv().API_URL;

/**
 * Function to create rule
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createClient = (payload) => {
  return axios.post(`${API_BASE_URL}/client/create`, payload);
};
/**
 * Function to get rule
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getClients = () => {
  return axios.get(`${API_BASE_URL}/clients`);
};

/**
 * Function to delete client
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deleteClients = (clients_id, payload) => {
  return axios.delete(`${API_BASE_URL}/clients/${clients_id}`, payload);
};

/**
 * Function to edit client
 * @param {Object} payload
 * @return AxiosPromise
 */
export const editClient = (clientId, payload) => {
  return axios.patch(`${API_BASE_URL}/client/${clientId}`, payload);
};
