<script setup>
import { ref, onMounted, defineProps, onUnmounted, watch } from "vue";
import {
  DocumentCheckIcon,
  PencilSquareIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/vue/24/solid";

import CreateContractForm from "./CreateContractForm.vue";
import { getContracts } from "@/services/contracts";

const isDropdownOpen = ref(false);
const offcanvasEditContractFormRef = ref(null);
const selectedContract = ref(null);
const showEditContractForm = ref(false);
const showSuccessMessage = ref(false);
const isContractEdited = ref(false);
const { data } = defineProps();
const contracts = ref([]);
const isLoading = ref(true);

onMounted(async () => {
  isLoading.value = true;
  const clientId = localStorage.getItem("cx");
  if (!clientId) {
    console.error("Client ID not found in localStorage");
    isLoading.value = false;
    return;
  }
  try {
    const response = await getContracts();
    console.log("Fetched Contracts:", response);
    if (response.data.length) {
      contracts.value = response.data;
    }
    console.log("Contratos:", contracts.value);
  } catch (error) {
    console.error("Error fetching contracts:", error);
  } finally {
    isLoading.value = false; // Detener el spinner independientemente del resultado
  }
});

function toggleEditContractForm(contract) {
  showEditContractForm.value = false;

  selectedContract.value = contract;
  console.log("Selected Contract:", selectedContract.value);

  setTimeout(() => {
    showEditContractForm.value = true;
  }, 0);
  showSuccessMessage.value = false;
}

const handleKeySaved = (newData) => {
  /* console.log("Datos del contrato actualizado:", data); */
  const index = contracts.value.findIndex(
    (contract) => contract.id === newData.id
  );
  if (index !== -1) {
    contracts.value[index] = newData;
  } else {
    contracts.value.push(newData);
  }
  showEditContractForm.value = false;
  isContractEdited.value = true;
  showSuccessMessage.value = true;
};

const closeOffcanvas = () => {
  showEditContractForm.value = false;
  if (!isContractEdited.value) {
    showSuccessMessage.value = false;
  }
};

/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const handleEscape = (event) => {
  if (event.key === "Escape") {
    closeOffcanvas();
  }
};
onMounted(() => {
  window.addEventListener("keydown", handleEscape);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscape);
});
// Watcher para detectar cambios en showEditRuleForm
watch(showEditContractForm, (newValue) => {
  if (!newValue) {
    setTimeout(() => {
      const offcanvasElement = document.querySelector(
        "#offcanvasEditContractForm"
      );
      offcanvasElement.classList.add("show"); // Mostrar el offcanvas
    }, 100);
  }
});
</script>
<template>
  <div class="rounded-lg table-container">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Contrato</th>
          <th>Tipo de Plan</th>
          <th>Fecha de Pago</th>
          <th>Cliente</th>
          <th>Nombre de Contacto</th>
          <th class="actions-column"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td colspan="7" class="text-center">
            <div
              class="spinner-border mt-4"
              style="width: 3rem; height: 3rem; color: #517fa4"
              role="status"
            >
              <span class="visually-hidden">Cargando...</span>
            </div>
            <p
              class="mt-3"
              style="color: #243949; font-size: large; font-weight: 600"
            >
              Cargando Contratos, por favor espera...
            </p>
          </td>
        </tr>
        <!-- mensaje si no hay contratos -->
        <tr v-else-if="contracts.length === 0">
          <td
            colspan="7"
            class="text-center"
            style="color: #243949; font-size: large; font-weight: 600"
          >
            No hay contratos disponibles.
          </td>
        </tr>
        <tr v-for="contract in contracts" :key="contract.id">
          <td>
            <div class="d-flex justify-content-center">
              <DocumentCheckIcon
                style="
                  color: #517fa4;
                  width: 25px;
                  height: 25px;
                  margin-right: 5px;
                "
              ></DocumentCheckIcon>
            </div>
          </td>
          <td style="font-weight: 600; color: #243949; text-align: start">
            {{ contract.contractTitle }}
          </td>
          <td style="font-weight: 400; color: gray; font-size: smaller">
            {{ contract.planType }}
          </td>
          <td style="text-transform: capitalize">
            {{
              new Date(contract.paymentDate).toLocaleDateString("es-ES", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })
            }}
          </td>
          <td>{{ contract.clientName }}</td>
          <td>{{ contract.contactName }}</td>

          <td
            class="d-flex gap-3 actions-column mx-2"
            style="margin-top: 3px; border-bottom: none"
          >
            <button
              class="buttons-group"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasEditContractForm"
              aria-controls="offcanvasEditContractForm"
              @click="toggleEditContractForm(contract)"
            >
              <PencilSquareIcon
                class="text-blue-500 d-flex justify-content-center"
                style="color: #517fa4; width: 20px; height: 20px"
              ></PencilSquareIcon>
            </button>

            <button class="buttons-group delete">
              <TrashIcon
                class="text-blue-500 d-flex justify-content-center"
                style="width: 20px; height: 20px"
              ></TrashIcon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- ... -->
  <!-- <div
    class="toast align-items-center position-fixed top-50 end-0 p-2 w-auto"
    style="background: #243949; color: white; width: auto"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    id="deleteToast"
    ref="deleteToast"
  >
    <div class="d-flex">
      <div class="toast-body">
        Se ha eliminado la regla
        <span
          style="
            font-weight: 600;
            text-decoration: underline;
            text-transform: capitalize;
          "
        >
          {{ deletedRuleName }}
        </span>
        con éxito.
      </div>
      <button
        type="button"
        class="btn-close me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
  </div> -->
  <div
    class="offcanvas offcanvas-end"
    style="width: 45%"
    data-bs-scroll="true"
    tabindex="-1"
    id="offcanvasEditContractForm"
    aria-labelledby="offcanvasEditContractFormLabel"
    ref="offcanvasEditContractFormRef"
  >
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body px-5" v-if="showEditContractForm">
      <CreateContractForm
        :offcanvasRef="offcanvasEditContractFormRef"
        :contractData="selectedContract"
        @updateContract="handleKeySaved"
        @cancelEdit="closeOffcanvas"
      ></CreateContractForm>
    </div>

    <div
      v-if="showSuccessMessage"
      class="offcanvas-body"
      style="text-align: center; margin-top: 8rem"
    >
      <CheckCircleIcon
        style="width: 5rem; height: 5rem; color: #517fa4"
      ></CheckCircleIcon>
      <h4 class="create-title mt-3">El Contrato se editó con éxito.</h4>
      <button
        class="globals-border-button"
        data-bs-dismiss="offcanvas"
        style="margin-top: 3rem"
      >
        Salir
      </button>
    </div>
  </div>
</template>
<style scoped>
.actions-column {
  position: sticky;
  right: 0;
  margin-left: 4px;
  margin-right: 4px;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
}
.delete:hover {
  background-color: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
}
.rounded-lg {
  border-radius: 10px;
  overflow: hidden;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px 15px 6px 15px;
  margin-top: 3px;
  margin: 4px;
  text-align: start;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

th {
  background-color: #ddd;
  color: #243949;
  text-align: start;
  padding-top: 3px;
  padding-bottom: 4px;
  margin: 3px 2px 3px 2px;
}

.under-th {
  height: 10px;
  font-size: x-small;
  padding: 2px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
</style>
