import axios from "axios";

import { dataEnv } from "../../config/index.js";
const API_BASE_URL = dataEnv().API_URL;

/**
 * Function to create channel
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createChannel = (payload) => {
  return axios.post(`${API_BASE_URL}/channels`, payload);
};

/**
 * Function to get channels
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getChannels = (clientId) => {
  return axios.get(`${API_BASE_URL}/channels/${clientId}/client`);
};

/**
 * Function to edit channel
 * @param {Object} payload
 * @return AxiosPromise
 */
export const editChannels = (payload) => {
  return axios.patch(`${API_BASE_URL}/channels`, payload);
};

/**
 * Function to delete channel
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deleteChannel = (channelsId, payload) => {
  return axios.delete(`${API_BASE_URL}/channels/${channelsId}`, payload);
};

/**
 * Function to get channels per project
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getProjectChannels = (projectsId) => {
  return axios.get(`${API_BASE_URL}/channels/${projectsId}/project`);
};

/**
 * Function to post contact_times per channel
 * @param {Object} payload
 * @return AxiosPromise
 */
export const postTimesToContactChannels = (payload) => {
  return axios.post(`${API_BASE_URL}/channel_rules`, payload);
};

/**
 * Function to get channels per channel_rules_id
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getChannelsRule = (channel_rules_id) => {
  return axios.get(`${API_BASE_URL}/channels_rules/${channel_rules_id}/rule`);
};
