<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import CreateRule from "./CreateRule.vue";
import EditRule from "./EditRule.vue";

const route = useRoute();

const showCreateRule = computed(() => {
  return route.name === "DashboardRulesCreate";
});

const showEditRule = computed(() => {
  return (
    !showCreateRule.value &&
    (route.name === "DashboardRules" || route.name === "DashboardRulesEdit")
  );
});

console.log("DASHBOARD RULES");
</script>
<template>
  <div class="d-flex justify-content-end" style="">
    <div class="px-4" style="width: 75%">
      <h2 class="dashboard-title">Reglas</h2>
      <CreateRule v-if="showCreateRule" :route="route"></CreateRule>
      <EditRule v-if="showEditRule" :route="route"></EditRule>
    </div>
  </div>
</template>
<style scoped></style>
