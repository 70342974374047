<script setup>
import { useRouter } from "vue-router";
import { CheckCircleIcon } from "@heroicons/vue/24/solid";

const router = useRouter();

function goToCreateContract() {
  router.go(0);
}
function goToContracts() {
  router.push({ name: "DashboardContracts" });
}
console.log("Nombre de la ruta actual:", router.currentRoute.value.name);
</script>
<template>
  <div style="background: white">
    <article class="d-flex flex-column justify-content-center gap-3 pt-5">
      <div class="d-flex justify-content-center">
        <CheckCircleIcon class="icon"></CheckCircleIcon>
      </div>
      <div class="d-flex flex-column text-center">
        <h5 class="create-title my-3" style="padding-top: 2rem; color: #243949">
          Se ha generado tu contrato exitosamente.
        </h5>
        <p style="color: rgb(125, 143, 152)">
          Genera un nuevo contrato o ve los que se han creado.
        </p>
      </div>
      <div class="d-flex justify-content-between my-2">
        <button
          class="globals-border-button"
          style="font-weight: 700"
          @click="goToContracts"
        >
          Ir a Contratos
        </button>
        <button
          class="globals-button text-nowrap w-auto px-3"
          style="font-weight: 700"
          @click="goToCreateContract"
        >
          Generar Nuevo Contrato
        </button>
      </div>
    </article>
  </div>
</template>
<style scoped>
.icon {
  color: #243949;
  height: 12rem;
  width: 12rem;
}
</style>
