<script setup>
import CreateRuleForm from "../../components/Rules/CreateRuleForm.vue";
import { useRoute } from "vue-router";

const route = useRoute();
console.log("RUTA CREATE RULE PAPA");
</script>
<template>
  <div v-if="route.name === 'DashboardRulesCreate'" class="">
    <h5 class="create-title my-4">Crear Regla</h5>
    <div>
      <CreateRuleForm></CreateRuleForm>
    </div>
  </div>
</template>
