<script setup>
import { ref, defineEmits, onMounted, watch, computed } from "vue";
import csvIcon from "@/assets/csvIcon.svg";

import CreatingFont from "@/components/Lists/Schemes/CreatingFont.vue";
import Papa from "papaparse";
import CardFontList from "@/components/common/CardFontList.vue";
import { getProjects } from "@/services/projects";
import { getProjectChannels } from "@/services/channels";
import { getCategoryMessage } from "@/services/categoryMessage";

const file = ref(null);
const title = ref(false);
const filename = ref("");
const delimiter = ref("");

let csvData = ref([]);

let columnTitles = ref([]);
const showSecondSection = ref(false);
const nameConnection = ref("");
const fontDescription = ref("");
const fontCategory = ref("");
const sendDate = ref("");

const showThirdSection = ref(false);
const creatingFontVisible = ref(false);

const columnPreviews = ref({});
const selectedColumn = ref(null);
const selectedColumnData = ref({});

const selectedAttributes = ref(null);
const selectedNameAttributes = ref(null);

const projects = ref([]);
const selectedProjectId = ref(null);
const channels = ref([]);
const messages = ref([]);

const selectedChannelId = ref(null);
const selectedMessageId = ref(null);

const filteredChannels = computed(() => {
  return channels.value;
});

onMounted(async () => {
  const clientId = localStorage.getItem("cx");
  console.log("Client ID:", clientId);
  if (!clientId) {
    console.error("Client ID not found in localStorage");
    return;
  }
  try {
    const response = await getProjects(clientId);
    console.log("Fetched Projects:", response.data);
    if (response.data.length) {
      projects.value = response.data;
    }
    console.log("PROYECTS:", projects.value);
  } catch (error) {
    console.error("Error fetching rules:", error);
  }
});

// actualizar canales y mensajes cuando se selecciona un proyecto
watch(selectedProjectId, async (newProjectId) => {
  console.log("Proyecto seleccionado:", newProjectId);
  if (newProjectId) {
    try {
      const response = await getProjectChannels(newProjectId);
      console.log("Canales obtenidos:", response.data);
      channels.value = response.data;

      const messagesResponse = await getCategoryMessage(newProjectId);
      console.log("Mensajes obtenidos:", messagesResponse.data);
      messages.value = messagesResponse.data;
    } catch (error) {
      console.error("Error al obtener los canales:", error);
    }
  } else {
    channels.value = [];
    messages.value = [];
  }
});

// Subir y procesar csv
const handleFileChange = (event) => {
  file.value = event.target.files[0];
  updateFilename();
  handleSuccess(file);
};

const updateFilename = () => {
  if (file.value) {
    filename.value = file.value.name;
  }
};
// Procesar archivo CSV
const handleSuccess = (file) => {
  if (file) {
    console.log("Archivo subido exitosamente", file);
    Papa.parse(file.value, {
      header: title.value,
      skipEmptyLines: true,
      delimiter: delimiter.value === "" ? "," : delimiter,
      complete: (results) => {
        csvData.value = results.data;

        if (!title.value) {
          // Extraer la primera fila como títulos
          const firstRow = results.data[0];
          columnTitles.value = Object.keys(firstRow).map(
            (key) => firstRow[key]
          );

          // Convertir el resto de las filas en objetos usando los nuevos títulos
          csvData.value = results.data.slice(1).map((row) => {
            let obj = {};
            columnTitles.value.forEach((title, index) => {
              obj[title] = row[Object.keys(row)[index]];
            });
            return obj;
          });
        } else {
          // Si tiene títulos, usar los títulos del CSV
          columnTitles.value = results.meta.fields || [];
        }

        // Asignar directamente a columnPreviews.value
        columnPreviews.value = {};
        columnTitles.value.forEach((title) => {
          columnPreviews.value[title] = csvData.value
            .slice(0, 3)
            .map((row) => row[title]);
        });

        console.log("Column Previews:", columnPreviews.value);
      },
      error: (error) => {
        console.log("Error al procesar el archivo CSV:", error);
      },
    });
  } else {
    console.log("Error al subir el archivo");
  }
};

const submitForm = () => {
  if (file.value && !showSecondSection.value) {
    showSecondSection.value = true;
  } else if (file.value && showSecondSection.value) {
    console.log("Uploading file:", file.value);
    console.log("Title:", title.value);
    console.log("Delimiter:", delimiter.value);
    console.log("CSV Data:", csvData);
    // enviar datos a bd
  }
};

onMounted(() => {
  scrollToSecondSection();
});

const submitSecondForm = () => {
  console.log("Submit del segundo formulario");
  console.log("nameConnection:", nameConnection.value);
  console.log("showThirdSection:", showThirdSection.value);
  if (nameConnection.value && !showThirdSection.value) {
    showThirdSection.value = true;
  } else if (nameConnection.value && showThirdSection.value) {
    console.log("Connection name:", nameConnection.value);
    console.log("Description:", fontDescription.value);
    console.log("Send Data:", sendDate.value);
  }
};

const submitThirdForm = () => {
  if (
    nameConnection.value &&
    showThirdSection.value &&
    selectedColumnData.value.length > 0
  ) {
    console.log("Selected column data:");
  }
};

const emits = defineEmits(["closeModal"]);

const resetAndCloseModal = () => {
  reset();
  emits("closeModal");
};

const reset = () => {
  file.value = null;
  title.value = false;
  filename.value = "";
  delimiter.value = "";
  nameConnection.value = "";
  fontDescription.value = "";
  fontCategory.value = "";
  sendDate.value = "";
  showSecondSection.value = false;
};

// Verificar campos step 1
const areFieldsComplete = () => {
  return !!file.value && !!filename.value;
};
console.log("corroborando:", areFieldsComplete());
// Verificar campos step 2
const areFieldsCompleteSecond = () => {
  return !!nameConnection.value && !!sendDate.value;
};
console.log("corroborando step2:", areFieldsCompleteSecond());

const scrollToSecondSection = () => {
  setTimeout(() => {
    const secondSection = document.getElementById("secondSection");
    if (secondSection) {
      window.scrollTo({
        top: secondSection.offsetTop,
        behavior: "smooth",
      });
    }
  }, 200);
};

watch(showSecondSection, (newValue) => {
  if (newValue) {
    scrollToSecondSection();
  }
});

const scrollToThirdSection = () => {
  setTimeout(() => {
    const thirdSection = document.getElementById("thirdSection");
    if (thirdSection) {
      window.scrollTo({
        top: thirdSection.offsetTop,
        behavior: "smooth",
      });
    }
  }, 200);
};

watch(showThirdSection, (newValue) => {
  if (newValue) {
    scrollToThirdSection();
  }
});

watch(title, (newValue) => {
  console.log("El valor de title ha cambiado a:", newValue);
});

const isDropdownOpen = ref(false);

/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const handleSelectAttribute = (selectedTitle) => {
  selectedColumn.value = selectedTitle;
  selectedNameAttributes.value = selectedTitle;
  selectedAttributes.value = columnTitles.value.indexOf(selectedTitle);

  // Guardar solo la última columna seleccionada
  selectedColumnData.value = {
    [selectedTitle]: csvData.value.map((row) => row[selectedTitle]),
  };

  console.log("Índice de columna seleccionada:", selectedAttributes.value);
  console.log("Nombre de columna seleccionada:", selectedNameAttributes.value);
};

const createFont = () => {
  if (
    areFieldsComplete() &&
    areFieldsCompleteSecond() &&
    selectedAttributes.value !== null &&
    selectedNameAttributes.value
  ) {
    const fontData = {
      file: file.value,
      title: title.value,
      filename: filename.value,
      csvData: csvData,
      delimiter: delimiter.value,
      nameConnection: nameConnection.value,
      fontDescription: fontDescription.value,
      fontCategory: fontCategory.value,
      sendDate: sendDate.value,
      selectedAttributes: selectedAttributes.value, //index
      selectedNameAttributes: selectedNameAttributes.value,
      projectsId: selectedProjectId.value,
      channelsId: selectedChannelId.value,
      categoryMessageId: selectedMessageId.value,
    };

    console.log("Información de la fuente:", fontData);
    creatingFontVisible.value = true;
  } else {
    console.log(
      "Todos los campos deben estar completos y se debe seleccionar una columna antes de crear la fuente."
    );
  }
};
</script>

<template>
  <div style="background: white">
    <article>
      <h5 class="create-title mt-1 mb-4">
        Listas / Selecciona la plataforma / Ajustes CSV
      </h5>
      <section>
        <div>
          <div class="d-flex gap-2 align-items-center m-0">
            <p class="circle pt-1">1</p>
            <p style="margin: 0; font-weight: 600; color: #243949">
              Selecciona el archivo de tu fuente
            </p>
          </div>
        </div>
        <!-- *Primer formulario -->
        <form @submit.prevent="submitForm">
          <div
            style="
              padding-top: 2rem;
              padding-bottom: 2rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
            "
          >
            <div class="font-section">
              <img
                :src="csvIcon"
                alt="CSV icon"
                style="width: 70px; height: 70px"
              />
              <!-- !Aqui sube archivo CSV -->
              <label
                for="fileInput"
                class="file-label d-flex flex-column justify-content-center gap-2"
              >
                <input
                  id="fileInput"
                  type="file"
                  accept=".csv"
                  @change="handleFileChange"
                  style="display: none"
                />
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 3rem"
                >
                  <span class="font-archive text-truncate">{{ filename }}</span>
                </div>
                <div
                  class="d-flex flex-column align-items-center justify-content-center gap-1 w-100"
                >
                  <span class="file-btn">Seleccionar archivo</span>
                  <p class="font-footnote">
                    Solo archivos CSV. El tamaño no debe exceder los 12MB.
                  </p>
                </div>
              </label>
            </div>
            <div class="font-section">
              <label class="globals-label" for="title"
                >¿Las columnas tienen título?</label
              >
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  :value="false"
                  v-model="title"
                />
                <label class="form-check-label" for="inlineRadio1">No</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  :value="true"
                  v-model="title"
                />
                <label class="form-check-label" for="inlineRadio2">Si</label>
              </div>
            </div>
            <div
              class="font-section gap-5 d-flex flex-column"
              style="height: auto; padding: 20px"
            >
              <div class="d-flex gap-3 p-3">
                <div
                  class="d-flex flex-column justify-content-center"
                  style="width: 50%"
                >
                  <label class="globals-label mb-2" for="delimiter"
                    >Indica el delimitador</label
                  >
                  <input
                    class="form-control globals-input"
                    type="text"
                    id="delimiter"
                    v-model="delimiter"
                    name="delimiter"
                    placeholder="Ej. ! , -"
                  />
                </div>
                <div style="width: 50%; display: flex; align-items: center">
                  <p
                    class="pt-3"
                    style="
                      margin: 0;
                      font-size: smaller;
                      color: #8e9fad;
                      text-align: left;
                    "
                  >
                    Indícanos el carácter delimitador por el cual están
                    divididas tus columnas. Por lo general, coma o tabulación.
                    El valor predeterminado es coma.
                  </p>
                </div>
              </div>
              <button
                class="globals-button"
                type="submit"
                @click="submitForm"
                :disabled="!areFieldsComplete()"
                :class="{ 'disabled-button': !areFieldsComplete() }"
              >
                Cargar Fuente
              </button>
            </div>
          </div>
        </form>
      </section>
      <!-- *Second Section -->
      <section id="secondSection" v-if="showSecondSection" class="mt-5">
        <div>
          <div class="d-flex gap-2 align-items-center m-0">
            <p class="circle pt-1">2</p>
            <p style="margin: 0; font-weight: 600; color: #243949">
              Configura los datos de tu fuente
            </p>
          </div>
        </div>
        <!-- *Segundo formulario -->
        <form @submit.prevent="submitSecondForm">
          <div
            style="
              height: auto;
              padding-top: 2rem;
              padding-bottom: 2rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
            "
          >
            <div class="font-section" style="height: auto">
              <div class="d-flex flex-column w-100 p-4 gap-4">
                <div class="d-flex flex-column justify-content-center">
                  <label class="globals-label" for=""
                    >Nombre de la conexión<span class="required">*</span></label
                  >
                  <input
                    v-model="nameConnection"
                    class="form-control globals-input"
                    type="text"
                    id="connectionName"
                    name="connectionName"
                    placeholder="Ingresa Nombre"
                    required
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <label class="globals-label" for=""
                    >Descripción de la fuente
                  </label>
                  <textarea
                    v-model="fontDescription"
                    class="form-control globals-input"
                    type="text"
                    id="connectionDescription"
                    name="connectionDescription"
                    placeholder="Ingresa una breve descripción."
                    required
                  />
                </div>

                <div class="mb-3 d-flex flex-column gap-2">
                  <label class="globals-label" for=""
                    >Selecciona un Proyecto<span class="required"
                      >*</span
                    ></label
                  >
                  <select
                    id="week"
                    class="form-select form-select-details text-capitalize"
                    v-model="selectedProjectId"
                  >
                    <option
                      value="0"
                      style="font-weight: 400; color: #99bbd6"
                      disabled
                      selected
                    >
                      Selecciona un proyecto
                    </option>
                    <option
                      v-for="project in projects"
                      :key="project.projectsId"
                      :value="project.projectsId"
                    >
                      {{ project.name }}
                    </option>
                  </select>
                </div>

                <div class="mb-3 d-flex flex-column gap-2">
                  <label class="globals-label" for=""
                    >Selecciona un Canal<span class="required">*</span></label
                  >
                  <select
                    id="week"
                    class="form-select form-select-details text-capitalize"
                    v-model="selectedChannelId"
                  >
                    <option
                      value="0"
                      style="font-weight: 400; color: #99bbd6"
                      disabled
                      selected
                    >
                      Selecciona un Canal
                    </option>
                    <option
                      v-for="channel in filteredChannels"
                      :key="channel.channelsId"
                      :value="channel.channelsId"
                    >
                      {{ channel.name }}
                    </option>
                  </select>
                </div>

                <div class="mb-3 d-flex flex-column gap-2">
                  <label class="globals-label" for=""
                    >Tipo de Mensaje<span class="required">*</span></label
                  >
                  <select
                    id="week"
                    class="form-select form-select-details text-capitalize"
                    v-model="selectedMessageId"
                  >
                    <option
                      value="0"
                      style="font-weight: 400; color: #99bbd6"
                      disabled
                      selected
                    >
                      Tipo de Mensaje
                    </option>
                    <option
                      v-for="message in messages"
                      :key="message.id"
                      :value="message.id"
                    >
                      {{ message.name }}
                    </option>
                  </select>
                </div>

                <div class="d-flex flex-column justify-content-center">
                  <label class="globals-label" for=""
                    >Fecha de Envío<span class="required">*</span></label
                  >
                  <input
                    v-model="sendDate"
                    class="form-control globals-input"
                    type="date"
                    id="connectionName"
                    name="connectionName"
                    placeholder="Ingresa Nombre"
                    required
                  />
                </div>
                <div class="d-flex justify-content-center w-100">
                  <button
                    class="globals-button"
                    type="submit"
                    @click="submitSecondForm"
                    :disabled="!areFieldsCompleteSecond()"
                    :class="{ 'disabled-button': !areFieldsCompleteSecond() }"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <!-- *Third Section -->
      <section id="thirdSection" v-if="showThirdSection" class="mt-5">
        <div>
          <div class="d-flex gap-2 align-items-center m-0">
            <p class="circle pt-1">3</p>
            <p style="margin: 0; font-weight: 600; color: #243949">
              Atributos de la fuente
            </p>
          </div>
        </div>
        <!-- *Tercer Formulario -->
        <form @submit.prevent="submitThirdForm">
          <div
            style="
              height: auto;
              padding-top: 2rem;
              padding-bottom: 2rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
            "
          >
            <div
              class="font-section flex-column"
              style="height: auto; width: 80%"
            >
              <div class="d-flex w-100 my-3">
                <p
                  style="
                    width: 70%;
                    color: rgb(125, 143, 152);
                    font-size: larger;
                    font-weight: 600;
                    margin-bottom: 0;
                    margin-left: 3rem;
                  "
                >
                  Selecciona la columna de tu CSV que desees agregar a la lista.
                </p>
                <!-- <div class="w-auto">
                  <div class="dropdown z-2 form-control globals-input">
                    <label
                      class="globals-label text-center"
                      style="font-size: medium"
                      for="connectionType"
                      >Estatus</label
                    >
                    <div
                      class="dropdown-toggle d-flex gap-3 align-items-center"
                      style="font-size: small; gap: 10px; color: #99bbd6"
                      @click="toggleDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{ selectedKey ? selectedKey : "Todos" }}
                    </div>
                    <ul
                      class="dropdown-menu mt-3 px-4"
                      :class="{ show: isDropdownOpen }"
                    >
                      <li>
                        <a class="dropdown-item" @click="selectKey('Tipo 1')"
                          >Tipo 1</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" @click="selectKey('Tipo 2')"
                          >Tipo 2</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" @click="selectKey('Tipo 3')"
                          >Tipo 3</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" @click="selectKey('Tipo 4')"
                          >Tipo 4</a
                        >
                      </li>
                    </ul>
                  </div>
                </div> -->
                <!-- <div style="margin-top: 20px">
                  <input
                    class="form-control globals-input input-with-icon"
                    style="height: 3.5rem"
                    type="text"
                    id="searchAttribute"
                    name="searchAttribute"
                    placeholder="Buscar Atributos"
                    v-model="searchTerm"
                  />
                  <div class="position-relative search-icon">
                    <MagnifyingGlassIcon
                      class="text-blue-500 d-flex justify-content-center"
                      style="width: 20px; height: 20px"
                    ></MagnifyingGlassIcon>
                  </div>
                </div> -->
              </div>
              <div class="card-grid-container">
                <CardFontList
                  v-for="(column, index) in columnTitles"
                  :key="index"
                  :attribute="column"
                  :selectedColumnData="selectedColumnData[column] || []"
                  :columnPreviews="columnPreviews[column] || []"
                  :selectedColumn="selectedColumn"
                  @selectColumn="handleSelectAttribute"
                >
                </CardFontList>
              </div>
            </div>
          </div>
        </form>
      </section>
      <!-- TODO:Enviar/descartar formulario  -->
      <div class="d-flex gap-5 py-4 justify-content-center">
        <button
          class="globals-border-button"
          style="font-weight: 700"
          type="button"
          @click="resetAndCloseModal()"
        >
          DESCARTAR
        </button>
        <button
          class="globals-button"
          style="font-weight: 700"
          type="submit"
          @click="createFont"
          :disabled="!areFieldsComplete() || !areFieldsCompleteSecond()"
          :class="{
            'disabled-button':
              !areFieldsComplete() || !areFieldsCompleteSecond(),
          }"
        >
          CREAR FUENTE
        </button>
      </div>
    </article>
    <div
      class="modal-background"
      v-if="creatingFontVisible"
      @click.self="creatingFontVisible = false"
    ></div>
    <div class="modal-content" v-if="creatingFontVisible">
      <CreatingFont />
    </div>
  </div>
</template>
<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin-left: 3.5rem;
  z-index: 100;
  background: white;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.card-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.card-grid-item {
  border: 1px solid #d5d4d4;
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
}
.input-with-icon {
  padding-right: 40px;
}

.search-icon {
  position: absolute;
  top: -40px;
  transform: translateX(80%);
  pointer-events: none;
}
.required {
  color: red;
  margin-left: 5px;
}
.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #243949;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bolder;
  margin: 0;
}

.custom-select {
  position: relative;
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  cursor: pointer;
}

.arrow-down {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
}
</style>
