<script setup>
import { createAttribute } from '@/services/data';
import { KeyIcon } from '@heroicons/vue/24/solid';
import { ref, onMounted, defineEmits, defineProps, reactive } from 'vue';

const isDropdownOpen = ref(false);
const selectedKey = ref(null);
const keys = ref({});
const newKeyData = ref(null);

const keyCreated = ref(false);

/* const { offcanvasRef } = defineProps(['offcanvasRef']); */
const emit = defineEmits(['keySaved']);
const props = defineProps(['newKeyData']);

function toggleDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value;
} 

function selectKey(key) {
  selectedKey.value = key.name;
  keyData.keytypes_id = key.id;
}

const keyData= reactive ({
  name: '',
  keytypes_id: '',
  clients_id: '',
  user_id: '',
  description: ''
})
function handleSubmit() {
    console.log('Form submitted!');
    console.log('Key Data:', keyData);
    
    const payload = {
      name: keyData.name,
      keytypes_id: keyData.keytypes_id,
      clients_id: localStorage.getItem('cx'),
      user_id: 1,
      description: keyData.description
    };
    console.log('Payload:', payload);
    // Llamar a la función para crear usuario en bd
    createAttribute(payload)
        .then(response => {
        console.log('Key creada exitosamente:', response.data);
        keyCreated.value = true;
        // EmitIr el evento
        emit('keySaved', response.data);
          // Cerrar el componente
          isDropdownOpen.value = false;
        })
        .catch(error => {
        console.error('Error al crear key:', error);
        if (error.response) {
            // El servidor respondió con un código de error
            console.log('Código de estado HTTP:', error.response.status);
            console.log('Respuesta del servidor:', error.response.data);
        } else if (error.request) {
            // La solicitud se hizo pero no se recibió respuesta
            console.log('No se recibió respuesta del servidor');
        } else {
            // Error al configurar la solicitud
            console.log('Error al configurar la solicitud:', error.message);
        }
        });
}

/* Click fuera del dropdown */
onMounted(() => { 
  document.addEventListener('click', (event) => {
    if (!event.target.closest('.dropdown')) {
      isDropdownOpen.value = false;
    }
  });
}); 
</script>
<template>
        <div class="modal-form">
            <article>
                <div class="mb-4">
                    <h2 class="create-title">Nueva Llave</h2>
                    <h5>Configura los datos de tu llave</h5>
                </div>
                <form class="my-3" @submit.prevent="handleSubmit">
                    <div class="mb-3">
                        <label for="nombre" class="form-label globals-label" style="font-size: 16px;">Nombre de la Llave</label>
                        <input v-model="keyData.name" type="name" class="form-control globals-input" id="nombreLlave" ref="nombreLlave"  placeholder="Asigna un nombre a tu Llave">
                    </div>
                    <div>
                        <div class="mb-3">
                            <label for="tipoLlave" class="form-label globals-label" style="font-size: 16px;">Tipo de Llave</label>
                            <div class="dropdown z-2 form-control globals-input">
                                <div class="dropdown-toggle d-flex gap-3 align-items-center" style="font-size: small; gap: 10px; color: #99bbd6;"
                                @click="toggleDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ selectedKey ? selectedKey : 'Selecciona una Llave' }}
                                </div>
                                <ul class="dropdown-menu  mt-3 px-4" :class="{ show: isDropdownOpen }">
                                  <li><a class="dropdown-item" @click="selectKey({ id: 1, name: 'Email' })">Email</a></li>
                                    <li><a class="dropdown-item" @click="selectKey({ id: 2, name: 'Phone' })">Phone</a></li>
                                    <li><a class="dropdown-item" @click="selectKey({ id: 3, name: 'Custom Key' })">Customer Id</a></li>
                                    <!-- <li><a class="dropdown-item" @click="selectKey('Mobile Advertising Id')">Mobile Advertising Id</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="descripcion" class="form-label globals-label" style="font-size: 16px;">Descripción</label>
                        <textarea v-model="keyData.description" id="descripcion" ref="descripcion" class="form-control globals-input rows-5 overflow-auto" style="border-radius: 10px;" placeholder="Escribe una breve descripción"></textarea>
                    </div>
                    <div class="d-flex justify-content-center my-5" style="max-height: 2.8rem;">
                            <button class="globals-button" @click="handleSubmit">
                                <KeyIcon class="text-blue-500 d-inline-flex justify-content-center" style="width: 20px; height: 20px; margin-right: 5px;"></KeyIcon>
                                Crear Llave
                            </button>
                    </div>
                </form>
            </article>
        </div>
</template>
<style scoped>
.modal-form {
  background-color: white;
  padding: 20px;
  width: 100%;
}

</style>