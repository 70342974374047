<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getLogin } from "@/services/auth";

const router = useRouter();

const email = ref("");
const password = ref("");
const error = ref(null);

const login = () => {
  const structureObject = {
    email: email.value,
    password: password.value,
  };
  getLogin(structureObject)
    .then((response) => {
      console.log("Respuesta del servidor:", response.data);
      if (response.data && response.data.authToken) {
        localStorage.setItem("authToken", response.data.authToken);
        localStorage.setItem("a", response.data.isAdmin);
        localStorage.setItem("r",response.data.role);
        localStorage.setItem("cx", response.data.clients_id);
        router.push("/dashboard");
      }
    })
    .catch((e) => {
      error.value = "Usuario y/o contraseña incorrectos";
      console.log("ERROR.VALUE:", error.value);
      console.error("Error:", error);
    });
};
</script>
<template>
  <article class="global-container login">
    <div class="login-card">
      <div class="card-body">
        <h6 class="login-pre-title">Bienvenido a</h6>
        <h5 class="login-title">Golden Contactability</h5>
        <p class="login-sub-title">Inicia sesión para comenzar.</p>
        <form @submit.prevent="login">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email</label>
            <input
              type="email"
              class="form-control login-input"
              id="exampleInputEmail1"
              v-model="email"
            />
            <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label"
              >Password</label
            >
            <input
              type="password"
              class="form-control login-input"
              id="exampleInputPassword1"
              v-model="password"
            />
          </div>
          <div class="mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label class="form-check-label" for="exampleCheck1"
              >Recordarme Siempre</label
            >
          </div>

          <div v-if="error" class="error-message">
            {{ error }}
          </div>

          <div class="d-flex justify-content-center">
            <button type="submit" class="btn login-button">
              Iniciar sesión
            </button>
          </div>
        </form>
        <!-- <div class="d-flex flex-column gap-2 text-center mt-3">
                <p class="m-0" style="font-size: small;">¿No estás registrado?</p>
                <div class="w-auto">
                    <a href="" class="login-a">Regístrate para crear una cuenta</a>
                </div>
            </div> -->
      </div>
    </div>
  </article>
</template>
<style scoped lang="scss">
.error-message {
  text-align: center;
  color: red;
  margin: 5px 0;
}
</style>
