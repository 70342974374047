<script setup>
import { reactive, ref, defineProps, defineEmits, watch } from "vue";
import {
  createProject,
  editProject,
  getProjectDetail,
} from "@/services/projects";
import CreatedProyect from "@/components/Proyects/CreatedProyect.vue";
import { CheckCircleIcon, TrashIcon } from "@heroicons/vue/24/solid";

const showPriorityManagement = ref(false);
const communicationTypeRef = ref(null);
const scoreRef = ref(null);
const codeRef = ref(null);
const communicationsList = ref([]);

const channelsList = ref([]);
const channelNameRef = ref(null);
const channelDescriptionRef = ref(null);

const projectCreated = ref(false);
const timesContactDaily = ref();
const isEditMode = ref(false);

const emit = defineEmits(["updateProject"]);

const props = defineProps({
  projectData: Object,
});

const projectData = reactive({
  name: "",
  limitPoints: "",
  communications: [],
  channels: [],
});

const step = ref(1);

/* function handleNextStep() {
  step.value++;
}

function handlePreviousStep() {
  step.value--;
} */

const togglePriority = (value) => {
  if (value) {
    showPriorityManagement.value = true;
  } else {
    showPriorityManagement.value = false;
    projectData.limitPoints = null;
    communicationsList.value = [];
  }
};

//Edicion
watch(
  () => props.projectData,
  async (newVal) => {
    if (newVal && Object.keys(newVal).length > 0) {
      const projectsId = newVal.projectsId;

      if (projectsId) {
        try {
          const projectDetail = await getProjectDetail(projectsId);
          console.log("Detalles del proyecto:", projectDetail.data);

          Object.assign(projectData, {
            name: projectDetail.data.name || "",
            limitPoints: projectDetail.data.limitPoints || "",
          });
          timesContactDaily.value = projectDetail.data.timesContactDaily || "";

          communicationsList.value = (
            projectDetail.data.communications || []
          ).map((communication) => ({
            type: communication.name,
            score: communication.points,
            code: communication.acronym,
          }));

          channelsList.value = projectDetail.data.channels || [];

          showPriorityManagement.value = !!projectDetail.data.limitPoints;

          isEditMode.value = true;
        } catch (error) {
          console.error("Error al obtener los detalles del proyecto:", error);
        }
      } else {
        console.log("No se encontró un id en projectData");
        resetForm();
      }
    } else {
      isEditMode.value = false;
      resetForm();
    }
  },
  { immediate: true }
);

//Validacion
const isSubmitting = ref(false);

const stepErrors = reactive({
  step1: false,
  step2: false,
  step3: false,
});

function validateStep1() {
  if (!projectData.name) {
    stepErrors.step1 = true;
    return false;
  }
  stepErrors.step1 = false;
  return true;
}

function validateStep2() {
  if (channelsList.value.length === 0) {
    stepErrors.step2 = true;
    return false;
  }
  stepErrors.step2 = false;
  return true;
}

function validateStep3() {
  // No hay campos obligatorios en el paso 3
  stepErrors.step3 = false;
  return true;
}
function handleNextStep() {
  if (step.value === 1 && validateStep1()) {
    step.value++;
  } else if (step.value === 2 && validateStep2()) {
    step.value++;
  } else if (step.value === 3) {
    validateStep3(); // no tiene validación obligatoria
  }
}

function handlePreviousStep() {
  step.value--;
}

async function handleSubmitProject() {
  if (isSubmitting.value || !projectData.name) {
    return;
  }

  isSubmitting.value = true;

  const channels = channelsList.value.length ? channelsList.value : [];

  const payload = {
    projectId: isEditMode.value ? props.projectData.projectsId : undefined,
    name: projectData.name,
    clientId: localStorage.getItem("cx"),
    timesContactDaily: timesContactDaily.value,
    limitPoints: showPriorityManagement.value ? projectData.limitPoints : null, // Solo envía limitPoints si está activado
    communicationsList: showPriorityManagement.value
      ? communicationsList.value
      : [], // Solo envía las comunicaciones si está activado
    channels: channels,
  };

  console.log("Payload enviado:", payload);

  try {
    if (isEditMode.value) {
      const response = await editProject(payload);
      emit("updateProject", response.data);
      resetForm();
    } else {
      await createProject(payload);
      projectCreated.value = true;
      resetForm();
    }
  } catch (error) {
    if (error.response) {
      console.error(
        "Error en la creación/edición del proyecto:",
        error.response
      );
    } else {
      console.error("Error en la creación/edición del proyecto:", error);
      console.error("Detalles del error:", error.response.data);
    }
  } finally {
    isSubmitting.value = false;
  }
}

function resetForm() {
  projectData.name = "";
  timesContactDaily.value = "";
  projectData.limitPoints = "";
  communicationsList.value = [];
  channelsList.value = [];
  showPriorityManagement.value = false;
  step.value = 1;
}

function addCommunication() {
  if (communicationTypeRef.value && scoreRef.value && codeRef.value) {
    const communication = {
      type: communicationTypeRef.value.value,
      score: parseInt(scoreRef.value.value),
      code: codeRef.value.value,
    };

    if (communication.type && communication.score && communication.code) {
      communicationsList.value.push(communication);
      console.log("Comunicación agregada:", communication);
    } else {
      console.warn("Faltan datos en la comunicación");
    }
    // Limpiar los inputs
    communicationTypeRef.value.value = "";
    scoreRef.value.value = "";
    codeRef.value.value = "";
  }
}
function removeCommunication(index) {
  communicationsList.value.splice(index, 1);
}

function addChannel() {
  if (channelNameRef.value && channelDescriptionRef.value) {
    const channel = {
      name: channelNameRef.value.value,
      description: channelDescriptionRef.value.value,
      clientsId: localStorage.getItem("cx"),
    };

    if (!Array.isArray(channelsList.value)) {
      channelsList.value = [];
    }

    channelsList.value.push(channel);
    // Limpiar los inputs
    channelNameRef.value.value = "";
    channelDescriptionRef.value.value = "";
    console.log("Canal agregado:", channel);
  }
}
console.log("Canales agregados", channelsList.value);

function removeChannel(index) {
  channelsList.value.splice(index, 1);
}
</script>
<template>
  <div>
    <article>
      <h2 class="create-title text-capitalize fs-3 mb-4">
        {{ isEditMode ? `Editar Proyecto: ${projectData.name}` : "" }}
      </h2>
      <!-- *Stepper -->
      <div class="stepper">
        <div
          class="step"
          :class="{ active: step === 1 }"
          :style="[
            step >= 1
              ? 'background-color: #99bbd6; border-radius: 20px 0 0 20px; transition: width 0.5s ease-in-out;'
              : '',
          ]"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              background: #243949;
              color: whitesmoke;
              border-radius: 50%;
              width: 1.4rem;
              height: 1.4rem;
            "
          >
            <span style="font-weight: 800; font-size: smaller">1</span>
          </div>
          Datos
        </div>
        <div
          class="step"
          :class="{ active: step === 2 }"
          :style="
            step >= 2
              ? 'background-color: #99bbd6; transition: width 0.5s ease-in-out'
              : ''
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              background: #243949;
              color: whitesmoke;
              border-radius: 50%;
              width: 1.4rem;
              height: 1.4rem;
            "
          >
            <span style="font-weight: 800; font-size: smaller">2</span>
          </div>
          Canales
        </div>
        <div
          class="step"
          :class="{ active: step === 3 }"
          :style="
            step >= 3
              ? 'background-color: #99bbd6; border-radius: 0 20px 20px 0; transition: width 0.5s ease-in-out;'
              : ''
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              background: #243949;
              color: whitesmoke;
              border-radius: 50%;
              width: 1.4rem;
              height: 1.4rem;
            "
          >
            <span style="font-weight: 800; font-size: smaller">3</span>
          </div>
          Prioridades
        </div>
      </div>
      <section v-if="step === 1">
        <form @submit.prevent="handleNextStep" style="margin-top: 5%">
          <p
            class="form-label globals-label"
            style="font-weight: 800; text-align: start; font-size: x-large"
          >
            Captura los datos del Proyecto
          </p>
          <div class="mb-4">
            <label
              for="exampleInputEmail1"
              class="form-label globals-label"
              style="font-size: 16px"
              >Nombre</label
            >
            <input
              type="text"
              class="form-control globals-input"
              id="exampleInputEmail1"
              placeholder="Título"
              v-model="projectData.name"
            />
          </div>
          <div class="mb-4">
            <label
              for="exampleInputEmail3"
              class="form-label globals-label pt-2 text-nowrap"
              style="width: 50%; font-size: 16px"
              >Límite <span style="text-decoration: underline">diario</span> de
              contactabilidad por todos los canales:
            </label>
            <input
              type="number"
              id="times"
              v-model="timesContactDaily"
              class="form-control form-select-details"
              style="width: 50%"
              min="1"
              max="5"
            />
            <span class="note-text">*El límite diario es opcional</span>
          </div>
          <div class="d-flex justify-content-center">
            <p v-if="stepErrors.step1" class="text-danger m-0">
              El Nombre es obligatorio.
            </p>
          </div>
          <div class="d-flex justify-content-center my-2">
            <button class="globals-border-button" @click="handleNextStep">
              Siguiente
            </button>
          </div>
        </form>
      </section>

      <section v-if="step === 2">
        <form @submit.prevent="handleNextStep" style="margin-top: 5%">
          <div
            class="d-flex flex-column align-items-center justify-content-center gap-4 my-4"
          >
            <div class="d-flex flex-column justify-content-start w-100">
              <p
                class="form-label globals-label"
                style="font-weight: 800; text-align: start; font-size: x-large"
              >
                Asignar Canales
              </p>
              <p
                class="form-label globals-label"
                style="font-weight: 600; text-align: start"
              >
                Crea un nuevo canal y asígnalo a tu proyecto.
              </p>

              <!-- *Agregar nuevo canal -->
              <section class="base mt-4">
                <form @submit.prevent class="w-100 p-3">
                  <p
                    class="form-label globals-label"
                    style="font-weight: 600; text-align: start"
                  >
                    Agregar nuevo Canal
                  </p>
                  <div>
                    <div class="mb-3">
                      <label
                        class="form-label globals-label"
                        style="font-size: 16px"
                        >Nombre del Canal</label
                      >
                      <input
                        class="form-control globals-input"
                        id="exampleInputEmail1"
                        placeholder="Nombre del canal"
                        :ref="(el) => (channelNameRef = el)"
                      />
                    </div>
                    <div>
                      <label
                        for="exampleInputEmail2"
                        class="form-label globals-label"
                        style="font-size: 16px"
                        >Descripción</label
                      >
                      <div class="mb-3 d-flex gap-2">
                        <textarea
                          type="text"
                          class="form-control globals-input"
                          id="exampleInputEmail2"
                          placeholder=""
                          :ref="(el) => (channelDescriptionRef = el)"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-center my-2">
                    <button
                      class="globals-border-button"
                      style="
                        background: transparent;
                        font-weight: 600;
                        text-wrap: nowrap;
                        width: auto;
                      "
                      @click="addChannel"
                    >
                      Guardar y Agregar Nuevo Canal
                    </button>
                  </div>
                </form>
              </section>
              <div>
                <p
                  class="form-label globals-label"
                  style="font-weight: 600; text-align: start; margin-top: 1rem"
                >
                  Canales Agregados:
                </p>
                <div>
                  <div id="dataList" class="d-flex">
                    <ul style="padding-left: 0%">
                      <li
                        v-for="(channel, index) in channelsList"
                        :key="index"
                        class="d-flex gap-2 mt-3 align-items-center pb-3"
                        style="
                          border-bottom: rgba(125, 143, 152, 0.2) solid 1px;
                        "
                      >
                        <CheckCircleIcon
                          class="text-blue-500 d-flex justify-content-center"
                          style="color: #517fa4; width: 20px; height: 20px"
                        ></CheckCircleIcon>
                        <label
                          for="exampleInputEmail3"
                          class="form-label globals-label pt-2 text-nowrap"
                          style="width: 10rem; font-size: 16px"
                        >
                          {{ channel.name }}
                        </label>
                        <div
                          class="d-flex align-items-center gap-2"
                          style="margin-left: 20px"
                        >
                          <label>Descripción: </label>
                          <div
                            class="form-control form-select-details"
                            style="
                              width: 20rem;
                              min-height: 2.5rem;
                              display: flex;
                              align-items: center;
                              overflow-x: auto;
                              -webkit-scrollbar: none;
                            "
                          >
                            {{ channel.description }}
                          </div>
                        </div>
                        <button
                          class="buttons-group delete"
                          @click="removeChannel(index)"
                          type="button"
                        >
                          <TrashIcon
                            class="text-blue-500 d-flex justify-content-center"
                            style="width: 20px; height: 20px"
                          ></TrashIcon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="stepErrors.step2" class="text-danger">
              Debes agregar al menos un canal.
            </p>
          </div>
          <div class="d-flex justify-content-evenly my-4 pb-6">
            <button class="globals-border-button" @click="handlePreviousStep">
              Anterior
            </button>
            <button class="globals-border-button" @click="handleNextStep">
              Siguiente
            </button>
          </div>
        </form>
      </section>

      <section v-if="step === 3" style="height: auto; overflow: auto">
        <form @submit.prevent="handleSubmitProject" style="margin-top: 5%">
          <p
            class="form-label globals-label"
            style="font-weight: 800; text-align: start; font-size: x-large"
          >
            Define Prioridades
          </p>
          <div class="py-3">
            <label
              for="exampleInputEmail2"
              class="form-label globals-label"
              style="font-size: 16px"
              >Administración de Prioridades
            </label>
            <div class="d-flex justify-content-start gap-5">
              <div class="mb-3 form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  :checked="!showPriorityManagement"
                  @change="togglePriority(false)"
                />
                <label class="form-check-label">No</label>
              </div>
              <div class="mb-3 form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  v-model="showPriorityManagement"
                  :checked="showPriorityManagement"
                  @change="togglePriority(true)"
                />
                <label class="form-check-label">Si</label>
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center justify-content-center gap-4 my-4"
              v-if="showPriorityManagement"
            >
              <div class="d-flex flex-column justify-content-start w-100">
                <p
                  class="form-label globals-label"
                  style="font-weight: 600; text-align: start"
                >
                  Administración de Prioridades
                </p>
                <div>
                  <label
                    for="exampleInputEmail3"
                    class="form-label globals-label pt-2 text-nowrap"
                    style="width: auto; font-size: 16px"
                    >Límite
                    <span style="text-decoration: underline">diario</span> de
                    puntos:
                  </label>
                  <input
                    type="number"
                    id="times"
                    class="form-control form-select-details w-50"
                    style="width: 100%"
                    v-model="projectData.limitPoints"
                  />
                </div>
                <div>
                  <p
                    class="form-label globals-label"
                    style="
                      font-weight: 600;
                      text-align: start;
                      margin-top: 1rem;
                    "
                  >
                    Comunicaciones agregadas:
                  </p>
                  <div>
                    <div id="dataList" class="d-flex">
                      <ul style="padding-left: 0%">
                        <li
                          v-for="(communication, index) in communicationsList"
                          :key="index"
                          class="d-flex gap-4 mt-3 pb-3"
                          style="
                            border-bottom: rgba(125, 143, 152, 0.2) solid 1px;
                          "
                        >
                          <label
                            for="exampleInputEmail3"
                            class="form-label globals-label pt-2 text-nowrap"
                            style="
                              width: auto;
                              min-width: 8rem;
                              font-size: 16px;
                            "
                            >{{ communication.type }}
                          </label>
                          <div
                            class="form-control form-select-details"
                            style="
                              min-width: 8rem;
                              display: flex;
                              align-items: center;
                            "
                          >
                            Puntaje:
                            <span style="font-weight: 600; margin-left: 10px">{{
                              communication.score
                            }}</span>
                          </div>
                          <div
                            class="form-control form-select-details"
                            style="
                              min-width: 10rem;
                              display: flex;
                              align-items: center;
                            "
                          >
                            Código:
                            <span style="font-weight: 600; margin-left: 10px">{{
                              communication.code
                            }}</span>
                          </div>
                          <button
                            class="buttons-group delete"
                            @click="removeCommunication(index)"
                            type="button"
                          >
                            <TrashIcon
                              class="text-blue-500 d-flex justify-content-center"
                              style="width: 20px; height: 20px"
                            ></TrashIcon>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <section class="base">
                <form @submit.prevent class="w-100 p-3">
                  <p
                    class="form-label globals-label"
                    style="font-weight: 600; text-align: start"
                  >
                    Comunicación Canales
                  </p>
                  <div>
                    <label
                      for="exampleInputEmail3"
                      class="form-label globals-label pt-2 text-nowrap"
                      style="width: auto; font-size: 16px"
                      >Tipo de comunicación:
                    </label>
                    <input
                      for="communicationType"
                      type="text"
                      id="times"
                      class="form-control form-select-details"
                      style="width: 100%"
                      :ref="(el) => (communicationTypeRef = el)"
                    />
                  </div>
                  <div>
                    <label
                      for="exampleInputEmail3"
                      class="form-label globals-label pt-2 text-nowrap"
                      style="width: auto; font-size: 16px"
                      >Puntaje:
                    </label>
                    <input
                      for="score"
                      type="number"
                      id="times"
                      class="form-control form-select-details"
                      style="width: 100%"
                      :ref="(el) => (scoreRef = el)"
                    />
                  </div>
                  <div>
                    <label
                      for="exampleInputEmail3"
                      class="form-label globals-label pt-2 text-nowrap"
                      style="width: auto; font-size: 16px"
                      >Código:
                    </label>
                    <input
                      for="code"
                      type="text"
                      id="times"
                      class="form-control form-select-details"
                      style="width: 100%"
                      min="1"
                      max="4"
                      oninput="if(this.value.length > 4) this.value = this.value.slice(0, 4);"
                      :ref="(el) => (codeRef = el)"
                    />
                    <span class="note-text"
                      >*Código de máximo 4 caracteres</span
                    >
                  </div>
                  <div class="d-flex justify-content-center my-2">
                    <button
                      class="globals-border-button"
                      style="
                        background: transparent;
                        font-weight: 600;
                        text-wrap: nowrap;
                        width: auto;
                      "
                      @click="addCommunication"
                    >
                      Guardar y Agregar Nueva
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
          <div class="d-flex justify-content-evenly my-4">
            <button class="globals-border-button" @click="handlePreviousStep">
              Anterior
            </button>
            <button
              class="globals-button"
              @click="handleSubmitProject"
              :disabled="isSubmitting"
            >
              {{ isEditMode ? "Guardar Cambios" : "Crear Proyecto" }}
            </button>
          </div>
        </form>
      </section>
    </article>
    <div v-if="projectCreated" class="modal-background">
      <div class="modal-content">
        <CreatedProyect></CreatedProyect>
      </div>
    </div>
  </div>
</template>
<style scoped>
::-webkit-scrollbar {
  width: 5px; /* scrollbar */
  height: 10px;
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc; /* color del thumb (la parte que se mueve) */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* fondo del track (la parte que no se mueve) */
}
.step.active {
  width: 30%;
  height: 100%;
  transition: background-color 0.8s ease-in-out;
}
.step::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0; /* ancho inicial */
  height: 100%;
  transition: width 0.5s ease-in-out;
}
.step:first-child.active::before {
  border-radius: 20px 0 0 20px;
}

.step:not(:first-child):not(:last-child).active::before {
  border-radius: 0;
}

.step:last-child.active::before {
  border-radius: 0 20px 20px 0;
}
.step.active::before {
  width: 100%;
}

.step {
  display: inline-flex;
  color: #243949;
  height: 100%;
  gap: 8px;
  position: relative;
  padding: 3px 15px;
  width: 100%;
  background: transparent;
}
.stepper {
  /* border: 2px solid black; */
  background: rgb(242, 242, 242);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* border: 1px solid rgba(213, 213, 213, 0.489); */
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-center;
  width: 80%;
}
.note-text {
  color: rgb(125, 143, 152);
  font-size: 0.7em;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  gap: 10px;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin-left: 3.5rem;
  z-index: 100;
  background: white;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.base {
  display: flex;
  background-color: rgb(239, 242, 245);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 70%;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
}
</style>
