<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router'
import DataStructure from './DataStructure.vue';
import Schemes from './Schemes.vue';
import DetailScheme from './DetailScheme.vue';
/* import Data from './Data.vue'; */
/* import Fonts from './Fonts.vue';
import DetailFont from './DetailFont.vue'; */

const route = useRoute()

const showDataStructure = computed(() => {
  return route.name === 'DataStructure';
});

const showSchemes = computed(() => {
  return route.name === 'DataSchemes';
});

const showDetailScheme = computed(() => {
  return route.name === 'DetailScheme';
});

console.log("DASHBOARD DATA");
</script>
<template>
    <div class="d-flex justify-content-end" style="">
        <div class="px-4" style="width: 75%;">
            <h2 class="dashboard-title" v-show="!showDetailScheme">Data</h2>
            <DataStructure v-if="showDataStructure" :route="route"></DataStructure>
            <Schemes v-if="showSchemes" :route="route"></Schemes>
            <DetailScheme v-if="showDetailScheme" :route="route"></DetailScheme>
        </div>
    </div>
</template>
<style scoped>
</style>