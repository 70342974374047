<script setup>
import { ref, onMounted, reactive, defineProps, defineEmits } from "vue";
import { getProjects } from "@/services/projects";
import { createChannel, editChannels } from "@/services/channels";
import CreatedChannel from "@/components/Channels/CreatedChannel.vue";

const props = defineProps({
  channelDataProp: Object,
});
const isSubmitting = ref(false);
const emit = defineEmits(["updateChannel", "cancelEdit"]);

const projects = ref([]);
const isEditMode = ref(false);
const channelCreated = ref(false);

const missingFieldsError = ref(false);
const formSubmitted = ref(false);

const channelData = reactive({
  name: "",
  description: "",
  projects_id: "",
  clients_id: localStorage.getItem("cx"),
});

onMounted(async () => {
  const clientId = localStorage.getItem("cx");
  const response = await getProjects(clientId);
  console.log("Fetched Projects:", response.data);
  if (response.data.length) {
    projects.value = response.data;
  }
  console.log("PROYECTS:", projects.value);

  if (props.channelDataProp && Object.keys(props.channelDataProp).length > 0) {
    Object.assign(channelData, {
      id: props.channelDataProp.channelsId,
      name: props.channelDataProp.name || "",
      description: props.channelDataProp.description || "",
      projects_id: props.channelDataProp.project.projectsId || "",
    });
    isEditMode.value = true;
  }
});

// Validación
function validateForm() {
  formSubmitted.value = true;
  let isValid = true;

  console.log("channelData.projects_id:", channelData.projects_id);

  if (!channelData.name) {
    isValid = false;
    console.log("El campo 'name' es obligatorio");
  }

  if (!channelData.description) {
    isValid = false;
    console.log("El campo 'description' es obligatorio");
  }

  if (!channelData.projects_id) {
    isValid = false;
    console.log("El campo 'projects_id' es obligatorio");
  }

  if (!isValid) {
    missingFieldsError.value = true;
    console.log("Faltan campos obligatorios");
    return false;
  } else {
    missingFieldsError.value = false;
    return true;
  }
}

async function handleSubmit() {
  console.log("Form submitted!");
  console.log("Channel Data:", channelData);
  formSubmitted.value = true;

  if (!validateForm()) {
    return;
  }

  const payload = {
    id: channelData.id,
    name: channelData.name,
    description: channelData.description,
    projects_id: channelData.projects_id,
    clients_id: channelData.clients_id,
  };
  console.log("Payload:", payload);

  try {
    if (isEditMode.value) {
      isSubmitting.value = true;
      const response = await editChannels(payload);
      console.log("Canal actualizado exitosamente", response.data);
      emit("updateChannel", response.data);
    } else {
      const response = await createChannel(payload);
      console.log("Canal creado exitosamente:", response.data);
      channelCreated.value = true;
    }
  } catch (error) {
    console.error("Error al crear/editar canal", error);
    if (error.response) {
      // El servidor respondió con un código de error
      console.log("Código de estado HTTP:", error.response.status);
      console.log("Respuesta del servidor:", error.response.data);
    } else if (error.request) {
      // La solicitud se hizo pero no se recibió respuesta
      console.log("No se recibió respuesta del servidor");
    } else {
      // Error al configurar la solicitud
      console.log("Error al configurar la solicitud:", error.message);
    }
  }
}
</script>
<template>
  <div>
    <article>
      <h2 class="create-title mb-3">
        {{ isEditMode ? `Editar Canal: ${channelData.name}` : "" }}
      </h2>
      <form @submit.prevent="handleSubmit">
        <div class="mb-3">
          <label
            for="exampleInputEmail1"
            class="form-label globals-label"
            style="font-size: 16px"
            >Nombre del Canal</label
          >
          <input
            type="email"
            class="form-control globals-input"
            id="exampleInputEmail1"
            placeholder="Nombre del canal"
            v-model="channelData.name"
          />
        </div>
        <div>
          <label
            for="exampleInputEmail2"
            class="form-label globals-label"
            style="font-size: 16px"
            >Descripción</label
          >
          <div class="mb-3 d-flex gap-2">
            <textarea
              type="email"
              class="form-control globals-input"
              id="exampleInputEmail2"
              placeholder=""
              v-model="channelData.description"
            />
          </div>
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail3" class="form-label globals-label"
            >Asigna a un proyecto:</label
          >
          <select
            id="project"
            class="form-select form-select-details"
            v-model="channelData.projects_id"
          >
            <option
              v-for="project in projects"
              :key="project.projectsId"
              :value="project.projectsId"
            >
              {{ project.name }}
            </option>
          </select>
        </div>
      </form>
      <div style="margin-top: 4rem">
        <div
          v-if="formSubmitted && missingFieldsError"
          class="text-danger text-center my-3 mt-4"
        >
          Todos los campos son obligatorios. Por favor, completa antes de
          continuar.
        </div>
        <div class="d-flex my-6 justify-content-around">
          <!-- <button class="globals-button" @click="handleSubmit">
            Crear Canal
          </button> -->
          <button
            v-if="isEditMode"
            @click="cancelEdit"
            class="globals-border-button"
            data-bs-dismiss="offcanvas"
          >
            Cancelar
          </button>
          <button type="submit" class="globals-button" @click="handleSubmit">
            {{ isEditMode ? "Guardar Cambios" : "Crear Canal" }}
          </button>
        </div>
      </div>
    </article>
    <div v-if="channelCreated" class="modal-background">
      <div class="modal-content">
        <CreatedChannel></CreatedChannel>
      </div>
    </div>
  </div>
</template>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(150px, 1fr)
  ); /* Ajusta el ancho de las columnas automáticamente */
  gap: 10px; /* Espacio entre los elementos */
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin-left: 3.5rem;
  z-index: 100;
  background: white;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
</style>
