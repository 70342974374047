<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
/* import DataStructure from './DataStructure.vue';
import Schemes from './Schemes.vue';
import DetailScheme from './DetailScheme.vue'; */
/* import Data from './Data.vue'; */
/* import Fonts from './Fonts.vue';
import DetailFont from './DetailFont.vue'; */
import ListsView from "./ListsView.vue";
import CreateFontStep1 from "@/components/Lists/Schemes/CreateFontStep1.vue";

const route = useRoute();

/* const showDataStructure = computed(() => {
  return route.name === "DataStructure";
}); */

const showCreateList = computed(() => {
  return route.name === "CreateList";
});

const showLists = computed(() => {
  return (
    !showCreateList.value &&
    (route.name === "DashboardLists" || route.name === "DashboardListInfo")
  );
});

/* const showDetailScheme = computed(() => {
  return route.name === "DetailScheme";
}); */

console.log("DASHBOARD LISTS");
</script>
<template>
  <div class="d-flex justify-content-end" style="">
    <div class="px-4" style="width: 75%">
      <h2 class="dashboard-title">Listas</h2>
      <!--  <DataStructure v-if="showDataStructure" :route="route"></DataStructure> -->
      <ListsView v-if="showLists" :route="route"></ListsView>
      <CreateFontStep1 v-if="showCreateList" :route="route"></CreateFontStep1>
      <!-- <DetailScheme v-if="showDetailScheme" :route="route"></DetailScheme> -->
    </div>
  </div>
</template>
<style scoped></style>
