import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Login";
import Dashboard from "@/views/Dashboard";
import Home from "@/views/Home.vue";
import Users from "@/views/Users/Users.vue";
import CreateUser from "@/views/Users/CreateUser.vue";
import EditUser from "@/views/Users/EditUser";
import Rules from "@/views/Rules/Rules";
import CreateRule from "@/views/Rules/CreateRule";
import EditRule from "@/views/Rules/EditRule";
import Proyects from "@/views/Proyects/Proyects";
import CreateProyect from "@/views/Proyects/CreateProyect";
import EditProyect from "@/views/Proyects/EditProyect";
import Clients from "@/views/Clients/Clients";
import CreateClient from "@/views/Clients/CreateClient";
import EditClient from "@/views/Clients/EditClient";
import Channels from "@/views/Channels/Channels";
import CreateChannel from "@/views/Channels/CreateChannel";
import EditChannel from "@/views/Channels/EditChannel";
import Profile from "@/views/Settings/Profile.vue";
import Credentials from "@/views/Settings/Credentials.vue";
import Data from "@/views/Data/Data.vue";
import DataStructure from "@/views/Data/DataStructure.vue";
import Schemes from "@/views/Data/Schemes.vue";
import DetailScheme from "@/views/Data/DetailScheme.vue";
import ApiSection from "@/views/API_section/ApiSection.vue";
import Contracts from "@/views/Contracts/Contracts.vue";
import CreateContract from "@/views/Contracts/CreateContract.vue";
import Lists from "@/views/Lists/Lists.vue";
import { isAuthenticated } from "@/services/auth";
import CreateList from "@/views/Lists/ListsView.vue";
import CreateFontStep1 from "@/components/Lists/Schemes/CreateFontStep1.vue";
import ListsView from "@/views/Lists/ListsView.vue";

const routes = [
  {
    path: "/",
    redirect: {
      name: "Login",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "users",
        name: "DashboardUsers",
        component: Users,
        children: [
          {
            path: "create",
            name: "DashboardUsersCreate",
            component: CreateUser,
          },
          {
            path: "edit",
            name: "DashboardUsersEdit",
            component: EditUser,
          },
        ],
      },
      {
        path: "contracts",
        name: "DashboardContracts",
        component: Contracts,
        children: [
          {
            path: "create",
            name: "DashboardContractCreate",
            component: CreateContract,
          },
        ],
      },
      {
        path: "rules",
        name: "DashboardRules",
        component: Rules,
        children: [
          {
            path: "create",
            name: "DashboardRulesCreate",
            component: CreateRule,
          },
          {
            path: "edit",
            name: "DashboardRulesEdit",
            component: EditRule,
          },
        ],
      },
      {
        path: "proyects",
        name: "DashboardProyects",
        component: Proyects,
        children: [
          {
            path: "create",
            name: "DashboardProyectCreate",
            component: CreateProyect,
          },
          {
            path: "edit",
            name: "DashboardProyectEdit",
            component: EditProyect,
          },
          /*           {
            path: 'fonts/detail',
            name: 'DetailFont',
            component: DetailFont
          } */
        ],
      },
      {
        path: "data",
        name: "DashboardData",
        component: Data,
        children: [
          {
            path: "structure",
            name: "DataStructure",
            component: DataStructure,
          },
          {
            path: "schemes",
            name: "DataSchemes",
            component: Schemes,
          },
          {
            path: "schemes/detail",
            name: "DetailScheme",
            component: DetailScheme,
          },
        ],
      },
      {
        path: "clients",
        name: "DashboardClients",
        component: Clients,
        children: [
          {
            path: "create",
            name: "DashboardClientCreate",
            component: CreateClient,
          },
          {
            path: "edit",
            name: "DashboardClientEdit",
            component: EditClient,
          },
        ],
      },
      {
        path: "channels",
        name: "DashboardChannels",
        component: Channels,
        children: [
          {
            path: "create",
            name: "DashboardChannelCreate",
            component: CreateChannel,
          },
          {
            path: "edit",
            name: "DashboardChannelEdit",
            component: EditChannel,
          },
        ],
      },
      {
        path: "lists",
        name: "DashboardLists",
        component: Lists,
        children: [
          {
            path: "create",
            name: "CreateList",
            component: CreateFontStep1,
          },
          {
            path: "info",
            name: "DashboardListInfo",
            component: ListsView,
          },
        ],
      },
      {
        path: "settings",
        name: "DashboardSettings",
        component: Profile,
        children: [
          {
            path: "credentials",
            name: "Credentials",
            component: Credentials,
          },
        ],
      },
      {
        path: "api",
        name: "DashboardApiSection",
        component: ApiSection,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!isAuthenticated()) {
    if (to.path === "/login" || to.path === "/") {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    if (to.path === "/login" || to.path === "/") {
      next({
        path: "/dashboard",
      });
    } else {
      next();
    }
  }
});

export default router;
