<script setup>

import { computed } from "vue";
import CreateUser from "@/views/Users/CreateUser.vue";
import EditUser from "@/views/Users/EditUser.vue";
import { useRoute } from 'vue-router'

const route = useRoute()

const showCreateUser = computed(() => {
  return route.name === 'DashboardUsersCreate';
});

const showEditUser = computed(() => {
  return !showCreateUser.value && (route.name === 'DashboardUsers' || route.name === 'DashboardUsersEdit');
});

console.log("DASHBOARD USERS");
console.log("DASHBOARD USERS 2");
</script>
<template>
    <div class="d-flex justify-content-end" style="">
        <div class="px-4" style="width: 75%;">
            <h2 class="dashboard-title">Usuarios</h2>
            <CreateUser  v-if="showCreateUser" :route="route" ></CreateUser>
            <EditUser v-if="showEditUser" :route="route"></EditUser>
        </div>
    </div>
</template>
<style scoped>
</style>
