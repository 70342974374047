<script setup>
import { useRouter } from 'vue-router';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';

const router = useRouter();

function goToCreateRule() {
  router.go(0);
}
function goToRules() {
  router.push({ name: 'DashboardProyectEdit'});
}
console.log("Nombre de la ruta actual:", router.currentRoute.value.name);
</script>
<template>
    <div style="background: white;">
        <article class="d-flex flex-column justify-content-center gap-3 pt-5">
          <div class="d-flex justify-content-center">
            <CheckCircleIcon class="icon"></CheckCircleIcon>
          </div>
          <div class="d-flex flex-column text-center">
            <h5 class="create-title my-3" style="padding-top: 2rem; color: #243949;">
              El Proyecto se ha creado exitosamente.
            </h5>
            <p style="color: rgb(125, 143, 152);">
              Puedes continuar creando Proyectos o ver los que se han creado y editarlos.
            </p>
          </div>
            <div class="d-flex justify-content-around my-2">
              <button
                class="globals-border-button"
                style="font-weight: 700;"
                @click="goToRules">
                    Ir a Proyectos
              </button>
              <button
                class="globals-button"
                style="font-weight: 700;"
                @click="goToCreateRule">
                    Crear Nuevo Proyecto
              </button>
            </div>
        </article>
    </div>
</template>
<style scoped>
.icon{
  color: #243949;
  height: 12rem;
  width: 12rem;
}

</style>
