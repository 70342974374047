<script setup>
import { useRoute } from "vue-router";
import ChannelsTable from "@/components/Channels/ChannelsTable.vue";

const route = useRoute();
console.log("RUTA Edit Channel PAPA");
</script>
<template>
  <div class="">
    <h5 class="create-title my-4">Canales Registrados</h5>
    <div>
      <ChannelsTable></ChannelsTable>
    </div>
  </div>
</template>
