<script setup>
import { ref, onMounted, defineEmits, defineProps, computed, watch } from "vue";
import { editRules, getRuleDetail } from "@/services/rules";

const props = defineProps(["newKeyData"]);
const isDropdownOpen = ref(false);

const daysEnglish = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
const daysSpanish = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const days = daysEnglish;

const showDates = ref(false);

const emit = defineEmits(["keySaved", "cancelEdit"]);
console.log("props", props.newKeyData);

const formData = ref({
  rulesId:
    props.newKeyData && props.newKeyData.rulesId
      ? props.newKeyData.rulesId
      : "",
  name: "",
  selectedDays: {
    monday:
      props.newKeyData &&
      props.newKeyData.days &&
      props.newKeyData.days.includes("monday")
        ? true
        : false,
    tuesday:
      props.newKeyData &&
      props.newKeyData.days &&
      props.newKeyData.days.includes("tuesday")
        ? true
        : false,
    wednesday:
      props.newKeyData &&
      props.newKeyData.days &&
      props.newKeyData.days.includes("wednesday")
        ? true
        : false,
    thursday:
      props.newKeyData &&
      props.newKeyData.days &&
      props.newKeyData.days.includes("thursday")
        ? true
        : false,
    friday:
      props.newKeyData &&
      props.newKeyData.days &&
      props.newKeyData.days.includes("friday")
        ? true
        : false,
    saturday:
      props.newKeyData &&
      props.newKeyData.days &&
      props.newKeyData.days.includes("saturday")
        ? true
        : false,
    sunday:
      props.newKeyData &&
      props.newKeyData.days &&
      props.newKeyData.days.includes("sunday")
        ? true
        : false,
  },
  startSchedule:
    props.newKeyData && props.newKeyData.startSchedule
      ? props.newKeyData.startSchedule
      : null,
  endSchedule:
    props.newKeyData && props.newKeyData.endSchedule
      ? props.newKeyData.endSchedule
      : null,

  noExpiration:
    props.newKeyData &&
    (props.newKeyData.startDate === null || props.newKeyData.endDate === null),
  hasExpiration:
    props.newKeyData &&
    props.newKeyData.startDate !== null &&
    props.newKeyData.endDate !== null,
  startDate:
    props.newKeyData && props.newKeyData.startDate
      ? props.newKeyData.startDate
      : null,
  endDate:
    props.newKeyData && props.newKeyData.endDate
      ? props.newKeyData.endDate
      : null,
  projectsId: props.newKeyData.projectsId,
  project: {
    name: "",
    projectsId: null,
    limitPoints: null,
    timesContactDaily: null,
  },
  rulesChannels: [
    {
      id: null,
      rulesId: null,
      channelsId: null,
      timesContact: null,
    },
  ],
  timesContactDaily:
    props.newKeyData && props.newKeyData.timesContactDaily
      ? props.newKeyData.timesContactDaily
      : "",
  /*   monthlyContact: props.newKeyData && props.newKeyData.isMonth === true,
  weeklyContact: props.newKeyData && props.newKeyData.isWeek === true, */
  weeklyContact: null,
  monthlyContact: null,
  channels: [],
  channelTimesContact: {},
});
console.log("FOrm Data:", formData);

onMounted(async () => {
  try {
    const response = await getRuleDetail(props.newKeyData.rulesId);
    const ruleData = response.data;
    console.log("RULE DETAIL:", ruleData);

    const channelsFromRule = ruleData.rulesChannels.map((channelData) => ({
      channelsId: channelData.channelsId,
      name: channelData.channels?.name || "Canal desconocido",
      timesContact: channelData.timesContact || 0,
    }));

    formData.value = {
      ...formData.value,
      name: ruleData.name,
      startDate: formatDate(ruleData.startDate),
      endDate: formatDate(ruleData.endDate),
      startSchedule: ruleData.startSchedule,
      endSchedule: ruleData.endSchedule,
      monthlyContact: ruleData.isMonth,
      weeklyContact: ruleData.isWeek,
      timesContactDaily: ruleData.timesContactDaily,
      projectsId: ruleData.projectsId,
      project: {
        limitPoints: ruleData.project.limitPoints,
        name: ruleData.project.name,
        projectsId: ruleData.project.projectsId,
        timesContactDaily: ruleData.project.timesContactDaily,
      },
      channels: channelsFromRule,
      channelTimesContact: ruleData.rulesChannels.reduce((times, channel) => {
        times[channel.channelsId] = channel.timesContact || 0;
        return times;
      }, {}),
      /* selectedDays: {
        monday: ruleData.days.includes("monday"),
        tuesday: ruleData.days.includes("tuesday"),
        wednesday: ruleData.days.includes("wednesday"),
        thursday: ruleData.days.includes("thursday"),
        friday: ruleData.days.includes("friday"),
        saturday: ruleData.days.includes("saturday"),
        sunday: ruleData.days.includes("sunday"),
      }, */
    };

    // Actualiza el texto de la modalidad de contacto
    textModCont.value = formData.value.weeklyContact ? "SEMANA" : "MES";
    console.log("Monthly Contact:", formData.value.monthlyContact);
    console.log("Weekly Contact:", formData.value.weeklyContact);
  } catch (error) {
    console.error("Error fetching rule details:", error);
  }
});

watch(formData.value.hasExpiration, (newValue) => {
  showDates.value = newValue;
});

function formatDate(date) {
  if (!date) return null;
  const d = new Date(date);
  const year = d.getFullYear();
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

onMounted(() => {
  if (props.newKeyData) {
    console.log("Props newKeyData:", props.newKeyData);
    const daysOfWeek = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    daysOfWeek.forEach((day) => {
      if (props.newKeyData[day]) {
        console.log(day, "is true");
        formData.value.selectedDays[day] = true;
      }
    });

    formData.value.startDate = formatDate(props.newKeyData.startDate);
    formData.value.endDate = formatDate(props.newKeyData.endDate);

    formData.value.monthlyContact = props.newKeyData.isMonth === 1;
    formData.value.weeklyContact = props.newKeyData.isWeek === 1;

    textModCont.value = formData.value.weeklyContact ? "SEMANA" : "MES";
  }
});
const selectedDays = computed(() => {
  return Object.keys(formData.value.selectedDays).filter(
    (day) => formData.value.selectedDays[day]
  );
});

const updateSelectedDays = (day) => {
  console.log("Selected day:", day);
  formData.value.selectedDays[day] = !formData.value.selectedDays[day];
  formData.value = { ...formData.value };
};

const translateDay = (day) => {
  const index = daysEnglish.indexOf(day);
  return daysSpanish[index];
};

//Editar y guardar la nuevainfo en la bd
function saveRuleAndEmit() {
  const updatedChannels = formData.value.channels.map((channel) => {
    return {
      ...channel,
      timesContact: formData.value.channelTimesContact[channel.channelsId],
    };
  });

  console.log("updatedChannles", updatedChannels);
  const updatedRuleData = {
    rulesId: formData.value.rulesId,
    startDate: formData.value.startDate,
    endDate: formData.value.endDate,
    monday: formData.value.selectedDays.monday,
    tuesday: formData.value.selectedDays.tuesday,
    wednesday: formData.value.selectedDays.wednesday,
    thursday: formData.value.selectedDays.thursday,
    friday: formData.value.selectedDays.friday,
    saturday: formData.value.selectedDays.saturday,
    sunday: formData.value.selectedDays.sunday,
    isMonth: formData.value.monthlyContact ? 1 : 0,
    isWeek: formData.value.weeklyContact ? 1 : 0,
    timesContactDaily: formData.value.timesContactDaily,
    startSchedule: formData.value.startSchedule,
    endSchedule: formData.value.endSchedule,
    projectsId: formData.value.projectsId,
    project: {
      limitPoints: formData.value.project.limitPoints,
      name: formData.value.project.name,
      projectsId: formData.value.project.projectsId,
      timesContactDaily: formData.value.project.timesContactDaily,
    },
    name: formData.value.name,
    channels: updatedChannels,
  };

  // Verificar si la regla tiene vigencia o no
  if (formData.value.hasExpiration) {
    updatedRuleData.startDate = formData.value.startDate;
    updatedRuleData.endDate = formData.value.endDate;
  } else {
    updatedRuleData.startDate = null;
    updatedRuleData.endDate = null;
  }
  console.log("Datos a enviar al servidor:", updatedRuleData);
  // LlamaR al servicio editRules con el ID  y los datos actualizados
  editRules(updatedRuleData)
    .then((response) => {
      console.log("Regla actualizada con éxito:", updatedRuleData);
      // EmitIr el evento
      emit("keySaved", response.data);
      // Cerrar el componente
      isDropdownOpen.value = false;
      console.log("Dropdown status:", isDropdownOpen.value);
    })
    .catch((error) => {
      console.error("Error al actualizar la regla:", error);
    });
}

/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const cancelEdit = () => {
  emit("cancelEdit"); // Emitir evento para cancelar la edición
};

// EDITAR MODALIDAD
const textModCont = ref(formData.value.weeklyContact ? "SEMANA" : "MES");
const handleContactChange = (contactType) => {
  if (contactType === "monthly") {
    formData.value.monthlyContact = true;
    formData.value.weeklyContact = false;
    textModCont.value = "MES";
  } else if (contactType === "weekly") {
    formData.value.weeklyContact = true;
    formData.value.monthlyContact = false;
    textModCont.value = "SEMANA";
  }
};
const errorMessages = ref({});

function validateAndUpdate(event, property) {
  const inputValue = parseInt(event.target.value, 10);
  if (formData.value.monthlyContact === 1) {
    if (inputValue > 31) {
      formData.value[property] = 31;
      errorMessages.value[
        property
      ] = `No es posible contactar más de 31 veces en un mes`;
    } else {
      formData.value[property] = inputValue;
      errorMessages.value[property] = "";
    }
  }
}
function validateAndUpdateWeek(event, property) {
  const inputValue = parseInt(event.target.value, 10);
  if (formData.value.weeklyContact === 1) {
    if (inputValue > 7) {
      formData.value[property] = 7;
      errorMessages.value[
        property
      ] = `No es posible contactar más de 7 veces en una semana`;
    } else {
      formData.value[property] = inputValue;
      errorMessages.value[property] = "";
    }
  }
}
</script>
<template>
  <div class="modal-form">
    <article>
      <div class="mb-4">
        <h2 class="create-title">Editar Regla</h2>
      </div>
      <form class="my-3">
        <div class="mb-3">
          <label
            class="form-label globals-label"
            style="font-weight: 600; font-size: 16px"
            >Nombre de la Regla</label
          >
          <input
            type="text"
            class="form-control globals-input"
            v-model="formData.name"
            placeholder="Nombre"
          />
        </div>

        <section class="my-4">
          <p class="form-check-label" style="font-weight: 600">
            Selecciona la frecuencia
          </p>
          <div class="grid-container">
            <div
              class="mb-3 form-check"
              v-for="(day, index) in days"
              :key="index"
            >
              <input
                type="checkbox"
                class="form-check-input"
                :id="'checkbox' + index"
                :value="day"
                :checked="selectedDays.includes(day)"
                @change="updateSelectedDays(day)"
              />
              <label class="form-check-label" :for="'checkbox' + index">{{
                translateDay(day)
              }}</label>
            </div>
          </div>
        </section>

        <div class="py-3">
          <label
            for="exampleInputEmail2"
            class="form-label globals-label"
            style="font-size: 16px"
            >Selecciona la vigencia
          </label>
          <div class="d-flex justify-content-start gap-5">
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="formData.noExpiration"
                :checked="
                  formData.startDate === null || formData.endDate === null
                "
              />
              <label class="form-check-label">Sin Vigencia</label>
            </div>
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="formData.hasExpiration"
                :checked="
                  formData.startDate !== null && formData.endDate !== null
                "
              />
              <label class="form-check-label">Vigencia</label>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center gap-4 my-4"
            v-if="formData.startDate !== null && formData.endDate !== null"
          >
            <p class="form-label globals-label" style="font-weight: 600">
              Vigencia de
            </p>
            <div class="mb-3">
              <input
                type="date"
                class="form-control globals-input"
                id="exampleInputEmail3"
                placeholder="Fecha Inicial"
                v-model="formData.startDate"
              />
            </div>
            <p class="form-label globals-label" style="font-weight: 600">a</p>
            <div class="mb-3">
              <input
                type="date"
                class="form-control globals-input"
                id="exampleInputEmail3"
                placeholder="Fecha Final"
                v-model="formData.endDate"
              />
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label
            for="exampleInputEmail3"
            class="form-label globals-label"
            style="font-size: 16px; font-weight: 600"
            >Proyecto asignado:</label
          >
          <input
            type="text"
            class="form-control globals-input"
            v-model="formData.project.name"
            :disabled="true"
          />
        </div>

        <section class="row gap-4 my-4">
          <div class="py-3">
            <label
              for="exampleInputEmail2"
              class="form-label globals-label"
              style="font-size: 16px"
              >Modalidad de contacto</label
            >
            <div class="d-flex justify-content-start gap-5">
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="formData.monthlyContact"
                  :checked="formData.monthlyContact"
                  @change="handleContactChange('monthly')"
                />
                <label class="form-check-label">Mensual</label>
              </div>
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="formData.weeklyContact"
                  :checked="formData.weeklyContact"
                  @change="handleContactChange('weekly')"
                />
                <label class="form-check-label">Semanal</label>
              </div>
            </div>

            <div
              class="d-flex align-items-center justify-content-center gap-4 my-4"
            >
              <div class="col">
                <p class="form-label globals-label">
                  Veces a contactar por {{ textModCont }}
                </p>

                <div v-for="(channel, index) in formData.channels" :key="index">
                  <div class="mb-3">
                    <label
                      :for="`channel-${index}`"
                      class="form-label globals-label pt-2 text-nowrap"
                      style="width: 50%; font-size: 16px"
                      >{{ channel.name }}</label
                    >
                    <input
                      type="number"
                      :id="`channel-${index}`"
                      class="form-control form-select-details"
                      style="width: 50%"
                      min="1"
                      max="31"
                      :value="formData.channelTimesContact[channel.channelsId]"
                      @input="
                        formData?.weeklyContact === 1
                          ? validateAndUpdateWeek($event, channel.channelsId)
                          : validateAndUpdate($event, channel.channelsId)
                      "
                      v-model.lazy="
                        formData.channelTimesContact[channel.channelsId]
                      "
                    />
                    <span
                      v-if="errorMessages[channel.channelsId]"
                      class="text-error"
                    >
                      {{ errorMessages[channel.channelsId] }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
      <div
        class="d-flex justify-content-center my-5 gap-4"
        style="max-height: 2.8rem"
      >
        <button
          class="globals-border-button"
          data-bs-dismiss="offcanvas"
          @click="cancelEdit"
        >
          Cancelar
        </button>
        <button class="globals-button" @click="saveRuleAndEmit()">
          Guardar Cambios
        </button>
      </div>
    </article>
  </div>
</template>
<style scoped>
.text-error {
  color: red;
  font-size: 0.8em;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  gap: 10px;
}
.modal-form {
  background-color: white;
  padding: 20px;
  width: 100%;
}
</style>
