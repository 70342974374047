<script setup>
import { createUser } from "@/services/users";
import { ref, reactive, onMounted } from "vue";
import CreatedUser from "@/components/Users/CreatedUser.vue";
import { getClients } from "@/services/clients";

const userCreated = ref(false);

const userData = reactive({
  name: "",
  email: "",
  clients_id: "",
  password: "",
  password2: "",
  role: 0,
});
const roles = ref([
  {
    id: 1,
    title: "Administrador",
  },
  {
    id: 2,
    title: "Editor",
  },
]);

const emailError = ref("");
const clients = ref([]);
onMounted(async () => {
  const response = await getClients();
  console.log("Fetched Projects:", response.data);
  if (response.data.length) {
    clients.value = response.data;
  }
  console.log("CLIENTS:", clients.value);
});

function handleSubmitUser() {
  console.log("Form submitted!");
  console.log("User Data:", userData);
  // Validar el formato del email
  if (!validateEmail()) {
    const payload = {
      name: userData.name,
      email: userData.email,
      clients_id: userData.clients_id,
      cat_roles_id: userData.role,
      password: userData.password
    };
    console.log("Payload:", payload);
    // Llamar a la función para crear usuario en bd
    createUser(payload)
      .then((response) => {
        console.log("Usuario creado exitosamente:", response.data);
        userCreated.value = true;
      })
      .catch((error) => {
        console.error("Error al crear usuario:", error);
        if (error.response) {
          // El servidor respondió con un código de error
          console.log("Código de estado HTTP:", error.response.status);
          console.log("Respuesta del servidor:", error.response.data);
        } else if (error.request) {
          // La solicitud se hizo pero no se recibió respuesta
          console.log("No se recibió respuesta del servidor");
        } else {
          // Error al configurar la solicitud
          console.log("Error al configurar la solicitud:", error.message);
        }
      });
  } else {
    console.error("El correo electrónico no es válido.");
  }
}

// Función para validar el formato del correo electrónico
function validateEmail() {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(userData.email)) {
    emailError.value = "Por favor, ingresa un correo electrónico válido.";
  } else {
    emailError.value = "";
    userCreated.value = true;
  }
  console.log("EMAILERROR STATUS:", emailError.value);
}
function resetEmailError() {
  emailError.value = "";
}
</script>
<template>
  <div>
    <article>
      <form @submit.prevent="handleSubmitUser">
        <div class="mb-3">
          <label
            for="exampleInputName"
            class="form-label globals-label"
            style="font-size: 16px"
            >Nombre (s)</label
          >
          <input
            type="text"
            class="form-control globals-input"
            id="exampleInputText"
            placeholder="Nombre (s)"
            v-model="userData.name"
          />
        </div>
        <!-- <div>
                    <label for="exampleInputEmail2" class="form-label globals-label" style="font-size: 16px;">Apellidos</label>
                    <div class="mb-3 d-flex gap-2">
                        <input type="email" class="form-control globals-input" id="exampleInputEmail2"  placeholder="Apellido Paterno">
                        <input type="email" class="form-control globals-input" id="exampleInputEmail2"  placeholder="Apellido Materno">
                    </div>
                </div> -->
        <div class="mb-3">
          <label
            for="exampleInputEmail3"
            class="form-label globals-label"
            style="font-size: 16px"
            >Email</label
          >
          <input
            type="email"
            class="form-control globals-input"
            id="exampleInputEmail3"
            placeholder="Email"
            v-model="userData.email"
            @input="resetEmailError"
          />
          <!-- Mostrar mensaje de error-->
          <span v-if="emailError" class="text-danger" style="font-size: 14px">{{
            emailError
          }}</span>
        </div>
        <div class="mb-3">
          <label
            for="exampleInputName"
            class="form-label globals-label"
            style="font-size: 16px"
            >Contraseña</label
          >
          <input
            type="password"
            class="form-control globals-input"
            id="exampleInputText"
            placeholder="Contraseña"
            v-model="userData.password"
          />
        </div>
        <div class="mb-3">
          <label
            for="exampleInputName"
            class="form-label globals-label"
            style="font-size: 16px"
            >Verifica contraseña</label
          >
          <input
            type="password"
            class="form-control globals-input"
            id="exampleInputText"
            placeholder="Contraseña"
            v-model="userData.password2"
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail3" class="form-label globals-label"
            >Cliente</label
          >
          <select
            id="client"
            class="form-select form-select-details"
            v-model="userData.clients_id"
          >
            <optgroup label="Selecciona un cliente">
              <option
                v-for="client in clients"
                :key="client.id"
                :value="client.id"
              >
                {{ client.companyName }}
              </option>
            </optgroup>
          </select>
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail3" class="form-label globals-label"
            >Tipo de Usuario</label
          >
          <select
            id="client"
            class="form-select form-select-details"
            v-model="userData.role"
          >
            <optgroup label="Selecciona el tipo de usuario">
              <option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.title }}
              </option>
            </optgroup>
          </select>
        </div>
        <div class="d-flex justify-content-center my-5">
          <button
            class="globals-button"
            type="submit"
            :disabled="emailError || !userData.name || !userData.email"
          >
            Registrar
          </button>
        </div>
      </form>
    </article>
    <div v-if="userCreated" class="modal-background">
      <div class="modal-content">
        <CreatedUser></CreatedUser>
      </div>
    </div>
  </div>
</template>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(150px, 1fr)
  ); /* Ajusta el ancho de las columnas automáticamente */
  gap: 10px; /* Espacio entre los elementos */
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin-left: 3.5rem;
  z-index: 100;
  background: white;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
</style>
