import axios from 'axios'

axios.interceptors.request.use((config) => {
  // const token = localStorage.getItem('token')
  const token = localStorage.getItem('authToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (err) => {
  return Promise.reject(err)
})

axios.interceptors.response.use((response) => {
  return response
}, (err) => {
  console.log('err', err)
  if (err.response.status === 401) {
    localStorage.removeItem('token')
    location.href = '/'
  }
  return Promise.reject(err)
})

/* export default axios */
