<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import {
  PencilSquareIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/vue/24/solid";
import { getClients, deleteClients } from "@/services/clients";
import EditClientForm from "./EditClientForm.vue";

const showEditClientForm = ref(false);
const isDropdownOpen = ref(false);
const offcanvasEditClientFormRef = ref(null);

const selectedClient = ref(null);

const showDeleteAlert = ref(false);
const deletedClientName = ref("");
const clients = ref([]);
const showSuccessMessage = ref(false);
const isClientEdited = ref(false);

onMounted(async () => {
  const clientsResponse = await getClients();
  console.log("Fetched clients:", clientsResponse);
  if (clientsResponse.data.length) {
    clients.value = clientsResponse.data;
  }
});

const deleteClientFunction = async (id, name) => {
  try {
    await deleteClients(id);
    clients.value = clients.value.filter((client) => client.id !== id);
    deletedClientName.value = name;
    showDeleteAlert.value = true;
    setTimeout(() => {
      showDeleteAlert.value = false;
    }, 3000);
  } catch (error) {
    console.error("Error al eliminar el cliente:", error);
  }
};

function toggleEditClientForm(client) {
  selectedClient.value = client;
  console.log("Selected Client:", selectedClient.value);
  showEditClientForm.value = !showEditClientForm.value;
  showSuccessMessage.value = false;
}
/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const closeOffcanvas = () => {
  showEditClientForm.value = false;
  if (!isClientEdited.value) {
    showSuccessMessage.value = false;
  }
};
const handleKeySaved = async (newData) => {
  const updatedClientIndex = clients.value.findIndex(
    (client) => client.id === newData.id
  );
  if (updatedClientIndex !== -1) {
    clients.value[updatedClientIndex] = newData;
  } else {
    clients.value.push(newData);
  }

  showEditClientForm.value = false;
  isClientEdited.value = true;
  showSuccessMessage.value = true;

  try {
    const clientsResponse = await getClients();
    if (clientsResponse.data.length) {
      clients.value = clientsResponse.data;
    }
  } catch (error) {
    console.error("Error al recargar los clientes:", error);
  }
};

const handleEscape = (event) => {
  if (event.key === "Escape") {
    closeOffcanvas();
  }
};
onMounted(() => {
  window.addEventListener("keydown", handleEscape);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscape);
});

watch(showEditClientForm, (newValue) => {
  if (!newValue) {
    setTimeout(() => {
      const offcanvasElement = document.querySelector(
        "#offcanvasEditClientForm"
      );
      offcanvasElement.classList.add("show");
    }, 100);
  }
});
</script>
<template>
  <div class="rounded-lg table-container" style="max-width: 50rem">
    <table class="table">
      <thead>
        <tr>
          <th>Nombre de la Empresa</th>
          <th>Nombre de Contacto</th>
          <th>Teléfono de Contacto</th>
          <th>Email de Contacto</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="client in clients" :key="client.id">
          <td style="text-transform: capitalize">{{ client.companyName }}</td>
          <td style="text-transform: capitalize">{{ client.contactName }}</td>
          <td>{{ client.phone }}</td>
          <td>{{ client.email }}</td>
          <td
            class="d-flex gap-3 actions-column mx-2 justify-content-around"
            style="margin-top: 3px; border-bottom: none"
          >
            <button
              class="buttons-group"
              @click="toggleEditClientForm(client)"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasEditClientForm"
              aria-controls="offcanvasEditClientForm"
            >
              <PencilSquareIcon
                class="text-blue-500 d-flex justify-content-center"
                style="color: #517fa4; width: 23px; height: 23px"
              ></PencilSquareIcon>
            </button>
            <button
              class="buttons-group delete"
              @click="deleteClientFunction(client.id)"
            >
              <TrashIcon
                class="text-blue-500 d-flex justify-content-center"
                style="width: 23px; height: 23px"
              ></TrashIcon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="showDeleteAlert"
      class="alert alert-dark alert-dismissible fade show"
      role="alert"
    >
      El cliente {{ deletedClientName }} ha sido eliminado exitosamente.
      <button
        type="button"
        class="btn-close"
        @click="showDeleteAlert = false"
        aria-label="Close"
      ></button>
    </div>
    <!-- Alerta de eliminación -->
  </div>
  <div
    class="offcanvas offcanvas-end"
    style="width: 45%"
    data-bs-scroll="true"
    tabindex="-1"
    id="offcanvasEditClientForm"
    aria-labelledby="offcanvasEditClientFormLabel"
    ref="offcanvasEditClientFormRef"
  >
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="closeOffcanvas"
      ></button>
    </div>
    <div class="offcanvas-body" v-if="showEditClientForm">
      <EditClientForm
        :offcanvasRef="offcanvasEditClientFormRef"
        :newKeyData="selectedClient"
        @keySaved="handleKeySaved"
        @cancelEdit="closeOffcanvas"
      ></EditClientForm>
    </div>
    <!-- *Mensaje -->
    <div
      v-if="showSuccessMessage"
      class="offcanvas-body"
      style="text-align: center; margin-top: 8rem"
    >
      <CheckCircleIcon
        style="width: 5rem; height: 5rem; color: #517fa4"
      ></CheckCircleIcon>
      <h4 class="create-title mt-3">El Cliente se editó con éxito.</h4>
      <button
        class="globals-border-button"
        data-bs-dismiss="offcanvas"
        style="margin-top: 3rem"
      >
        Salir
      </button>
    </div>
  </div>
</template>
<style scoped>
.actions-column {
  position: sticky;
  right: 0;
  margin-left: 4px;
  margin-right: 4px;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
}
.delete:hover {
  background-color: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
}
.rounded-lg {
  border-radius: 10px;
  overflow: hidden;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px 8px 6px 8px;
  margin-top: 3px;
  margin: 4px;
  text-align: start;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

th {
  background-color: #ddd;
  color: #243949;
  text-align: start;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 5px 2px 5px 2px;
}

.under-th {
  height: 10px;
  font-size: x-small;
  padding: 2px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
</style>
