<script setup>
import { editUser } from "@/services/users";
import { getClients } from "@/services/clients";
import { ref, onMounted, defineEmits, defineProps } from "vue";
const props = defineProps(["newKeyData"]);
const emit = defineEmits(["keySaved", "cancelEdit"]);

const isDropdownOpen = ref(false);
const clients = ref([]);
onMounted(async () => {
  const response = await getClients();
  console.log("Fetched Projects:", response.data);
  if (response.data.length) {
    clients.value = response.data;
  }
  console.log("CLIENTS:", clients.value);
});

const userData = ref({
  id: props.newKeyData && props.newKeyData.id ? props.newKeyData.id : "",
  name: props.newKeyData && props.newKeyData.name ? props.newKeyData.name : "",
  email:
    props.newKeyData && props.newKeyData.email ? props.newKeyData.email : "",
  clients_id:
    props.newKeyData && props.newKeyData.clients_id
      ? props.newKeyData.clients_id
      : "",
});

//Editar y guardar la nuevainfo en la bd
function saveUserAndEmit(userId) {
  const updatedUserData = {
    name: userData.value.name,
    email: userData.value.email,
    clients_id: userData.value.clients_id,
  };
  console.log("Datos a enviar al servidor:", updatedUserData);
  // LlamaR al servicio editRules con el ID  y los datos actualizados
  editUser(userId, updatedUserData)
    .then((response) => {
      console.log("Regla actualizada con éxito:", updatedUserData);
      // EmitIr el evento
      emit("keySaved", response.data);
      // Cerrar el componente
      isDropdownOpen.value = false;
      console.log("Dropdown status:", isDropdownOpen.value);
    })
    .catch((error) => {
      console.error("Error al actualizar la regla:", error);
    });
}

/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const cancelEdit = () => {
  emit("cancelEdit"); // Emitir evento para cancelar la edición
};
</script>
<template>
  <div>
    <article>
      <h5 class="create-title my-4">Editar Usuario</h5>
      <form>
        <div class="mb-3">
          <label
            for="exampleInputEmail1"
            class="form-label globals-label"
            style="font-size: 16px"
            >Nombre (s)</label
          >
          <input
            type="text"
            class="form-control globals-input"
            id="name"
            placeholder="Nombre (s)"
            v-model="userData.name"
          />
        </div>

        <div class="mb-3">
          <label
            for="exampleInputEmail3"
            class="form-label globals-label"
            style="font-size: 16px"
            >Email</label
          >
          <input
            type="email"
            class="form-control globals-input"
            id="email"
            placeholder="Email"
            v-model="userData.email"
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail3" class="form-label globals-label"
            >Cliente</label
          >
          <select
            id="client"
            class="form-select form-select-details"
            v-model="userData.clients_id"
          >
            <option
              v-for="client in clients"
              :key="client.id"
              :value="client.id"
            >
              {{ client.name }}
            </option>
          </select>
        </div>
      </form>
      <div class="d-flex justify-content-around my-5">
        <button
          class="globals-border-button"
          @click="cancelEdit"
          data-bs-dismiss="offcanvas"
        >
          Cancelar
        </button>
        <button class="globals-button" @click="saveUserAndEmit(userData.id)">
          Guardar Cambios
        </button>
      </div>
    </article>
  </div>
</template>
