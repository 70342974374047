<script setup>
import { PlayIcon } from "@heroicons/vue/24/outline";
import { CalendarDaysIcon } from "@heroicons/vue/24/outline";
import { ChevronLeftIcon } from "@heroicons/vue/24/solid";
import { useRoute, useRouter } from "vue-router";
import csvIcon from "@/assets/csvIcon.svg";

const route = useRoute();
const router = useRouter();
const currentDate = new Date().toLocaleDateString();

console.log("RUTA DETAIL Scheme");
console.log(route.name);

function goBack() {
  router.go(-1);
}
</script>
<template>
  <div
    v-if="route.name === 'DetailScheme'"
    class="d-flex flex-column gap-4 my-3"
  >
    <div>
      <button class="buttons-group d-flex" @click="goBack">
        <ChevronLeftIcon
          class="text-blue-500 d-flex justify-content-center"
          style="width: 20px; height: 20px"
        ></ChevronLeftIcon>
        <p class="text">Regresar</p>
      </button>
    </div>
    <div class="info-box">
      <div class="date-box">
        <div class="play-box">
          <PlayIcon style="width: 20px; height: 20px"></PlayIcon>
          <p style="margin: 0; font-weight: 600; text-wrap: nowrap">
            Recolectando datos
          </p>
        </div>
        <div
          class=""
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: center;
            gap: 10px;
          "
        >
          <CalendarDaysIcon
            style="width: 22px; height: 22px"
          ></CalendarDaysIcon>
          <p class="m-0 mt-2">Creación:</p>
          <p class="m-0 mt-2" style="font-weight: 700">{{ currentDate }}</p>
        </div>
      </div>
      <div style="padding: 1rem; display: flex; justify-content: space-between">
        <div class="d-flex flex-column gap-3">
          <div class="d-flex flex-column">
            <p class="m-0">Nombre de la Fuente:</p>
            <p class="m-0" style="font-weight: 700">FUENTE 1</p>
          </div>
          <div class="d-flex flex-column">
            <p class="m-0">Tipo de Fuente:</p>
            <div class="d-flex gap-2 mt-2">
              <img
                :src="csvIcon"
                alt="csv archive"
                class="d-inline-flex justify-content-center"
                style="width: 25px; height: 25px; margin-right: 5px"
              />
              <p class="m-0" style="font-weight: 700">CSV</p>
            </div>
          </div>
          <div class="d-flex justify-content-between gap-2">
            <div class="d-flex flex-column">
              <p class="m-0">Llaves:</p>
              <p class="m-0" style="font-weight: 700">Llave</p>
            </div>
            <div class="d-flex flex-column">
              <p class="m-0">Atributos:</p>
              <p class="m-0" style="font-weight: 700">Atributo</p>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column">
            <p class="m-0">Descripción:</p>
            <p class="m-0">Descripcion de fuente</p>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-between">
          <div class="d-flex flex-column">
            <p class="m-0">Total de contactos:</p>
            <p class="m-0" style="font-weight: 700; font-size: 45px">0</p>
          </div>
          <div class="errors-box">
            <p class="m-0" style="font-weight: 700; font-size: 35px">0</p>
            <div class="d-flex flex-column justify-content-center">
              <p class="m-0">Errores de progreso</p>
              <p class="m-0">
                Hoy <span style="font-weight: 700">{{ currentDate }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <article style="margin-top: 20px">
      <h5 style="color: #243949; margin-bottom: 16px">
        Performance de la Fuente
        <span style="font-weight: 700">Fuente 1</span> por segmento.
      </h5>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Segmento</th>
            <th scope="col">Status</th>
            <th scope="col">Contactos</th>
            <th scope="col">Tipo destino</th>
            <th scope="col">Actualización</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Status 1</td>
            <td>Contacto 1</td>
            <td>Destino 1</td>
            <td>Actualización 1</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Status 2</td>
            <td>Contacto 2</td>
            <td>Destino 2</td>
            <td>Actualización 2</td>
          </tr>
        </tbody>
      </table>
    </article>
  </div>
</template>
<style scoped>
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    color: #99bbd6;
  }
}
.info-box {
  background: #243949;
  border-radius: 20px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  color: white;
  min-height: 300px;
}
.date-box {
  align-items: center;
  background: #182631;
  border-radius: 20px;
  color: white;
  display: flex;
  gap: 10px;
  height: 5rem;
  padding: 1rem;
}
.play-box {
  align-items: center;
  background: rgb(139, 186, 68);
  border-radius: 30px;
  color: white;
  display: inline-flex;
  gap: 5px;
  justify-content: center;
  padding: 5px 15px 5px 15px;
}
.errors-box {
  background: rgb(195, 115, 115);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  padding: 8px 20px 8px 20px;
}
.table {
  --bs-table-color: #243949;
}
</style>
