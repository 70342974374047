<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import FontsTable from "@/components/Data/Schemes/FontsTable";
import CreateFontStep1 from "@/components/Data/Schemes/CreateFontStep1";

console.log("RUTA Schemes");
const route = useRoute();
const showCreateFontStep1 = ref(false);
const keys = ref([]);

function toggleCreateStep1() {
  showCreateFontStep1.value = !showCreateFontStep1.value;
}
</script>
<template>
  <div v-if="route.name === 'DataSchemes'" class="">
    <h5 class="create-title my-4">Esquemas</h5>
    <article>
      <div class="d-flex gap-5 align-items-center my-4 justify-content-between">
        <p
          style="
            margin-top: 20px;
            color: rgb(125, 143, 152);
            text-align: justify;
          "
        >
          Puedes configurar un esquema de tu catálogo, o subir uno nuevo.
        </p>
        <div class="d-flex justify-content-center" style="max-height: 2.8rem">
          <button class="globals-button" @click="toggleCreateStep1">
            Crear Esquema
          </button>
        </div>
      </div>
      <div>
        <FontsTable :data="keys"></FontsTable>
      </div>
      <transition name="slide-fade-up">
        <div
          v-show="showCreateFontStep1"
          class="position-absolute w-auto h-100 create-font-overlay"
          style="top: 7rem"
          @click.self="toggleCreateStep1"
        >
          <div class="position-relative">
            <CreateFontStep1 class=""></CreateFontStep1>
          </div>
        </div>
      </transition>
    </article>
  </div>
</template>
<style scoped>
.slide-fade-up-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-up-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-up-enter-from,
.slide-fade-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.create-font-overlay {
  background-color: white;
}
</style>
