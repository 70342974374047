import axios from "axios";

import { dataEnv } from "../../config/index.js";
const API_BASE_URL = dataEnv().API_URL;

/**
 * Function to get message type per project
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getCategoryMessage = (projectsId) => {
  return axios.get(`${API_BASE_URL}/category-message/project/${projectsId}`);
};
