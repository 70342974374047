<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import CreateProyect from "./CreateProyect.vue";
import EditProyect from "./EditProyect.vue";

const route = useRoute();

const showCreateProyect = computed(() => {
  return route.name === "DashboardProyectCreate";
});

const showEditProyect = computed(() => {
  return (
    !showCreateProyect.value &&
    (route.name === "DashboardProyects" ||
      route.name === "DashboardProyectEdit")
  );
});

console.log("DASHBOARD PROYECTS");
</script>
<template>
  <div class="d-flex justify-content-end" style="">
    <div class="px-4" style="width: 75%">
      <h2 class="dashboard-title">Proyectos</h2>
      <CreateProyect v-if="showCreateProyect" :route="route"></CreateProyect>
      <EditProyect v-if="showEditProyect" :route="route"></EditProyect>
    </div>
  </div>
</template>
<style scoped></style>
