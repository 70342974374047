<script setup>
import CreateUserForm from '/src/components/Users/CreateUserForm.vue';
import { useRoute } from 'vue-router';

const route = useRoute()
console.log("RUTA CREATE USER PAPA")

</script>
<template>
    <div v-if="route.name === 'DashboardUsersCreate'" class="">
        <h5 class="create-title my-4">Crear Usuario</h5>
        <div>
            <CreateUserForm></CreateUserForm>
        </div>
    </div>
</template>
