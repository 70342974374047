<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

function goToFonts() {
  /* router.push({ name: 'DashboardFonts'}); */
  /* router.replace({ name: 'DashboardFonts' }); */
  router.go(0);
}
console.log("Nombre de la ruta actual:", router.currentRoute.value.name);
</script>
<template>
  <div style="background: white">
    <article class="d-flex flex-column justify-content-center gap-3 pt-5">
      <div class="d-flex justify-content-center">
        <div class="loader"></div>
      </div>
      <div class="d-flex flex-column text-center">
        <h5 class="create-title my-3" style="padding-top: 4rem; color: #243949">
          Espera, estamos creando la Fuente de Datos ...
        </h5>
        <p style="color: rgb(125, 143, 152)">
          Este proceso puede tardar unos minutos. En cuanto esté lista la nueva
          fuente, podrás verla en el listado.
        </p>
      </div>
      <div class="d-flex justify-content-center my-2">
        <button
          class="globals-button"
          style="font-weight: 700"
          @click="goToFonts"
        >
          Ir a Listas
        </button>
      </div>
    </article>
  </div>
</template>
<style scoped>
/* loader circle */
.loader {
  width: 40px;
  scale: 4;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #243949 94%, #0000),
    radial-gradient(farthest-side, #517fa4 94%, #0000),
    radial-gradient(farthest-side, #182631 94%, #0000),
    radial-gradient(farthest-side, #99bbd6 94%, #0000), #243949;
  background-size: 105% 105%;
  background-repeat: no-repeat;
  animation: l5 2s infinite;
}
@keyframes l5 {
  0% {
    background-position: 50% -50px, -40px 50%, 50% calc(100% + 50px),
      calc(100% + 50px) 50%;
  }
  20%,
  25% {
    background-position: 50% -50px, -50px 50%, 50% calc(100% + 50px), 50% 50%;
  }
  45%,
  50% {
    background-position: 50% -50px, -50px 50%, 50% 50%, 50% 50%;
  }
  75%,
  75% {
    background-position: 50% -50px, 50% 50%, 50% 50%, 50% 50%;
  }
  95%,
  100% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
}
</style>
