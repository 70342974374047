<script setup>
import { useRoute } from "vue-router";
import CreateProyectForm from "../../components/Proyects/CreateProyectForm.vue";

const route = useRoute();
console.log("RUTA CREATE PROYECT PAPA");
</script>
<template>
  <div v-if="route.name === 'DashboardProyectCreate'" class="">
    <h5 class="create-title my-4">Crear Proyecto</h5>
    <div>
      <CreateProyectForm></CreateProyectForm>
    </div>
  </div>
</template>
