<script setup>
import { ref, onMounted, defineProps } from "vue";
import { RouterLink } from "vue-router";
import { EyeIcon, TrashIcon } from "@heroicons/vue/24/solid";
import csvIcon from "@/assets/csvIcon.svg";

const isDropdownOpen = ref(false);

const { data } = defineProps();

const keys = ref([
  {
    id: 1,
    icono: "icon-1",
    nombreFuente: "Fuente 1",
    descripcion: "Descripcion 1",
    activado: "Activado",
    tipoData: "Tipo Dato 1",
    categoria: "Categoria 1",
  },
  {
    id: 2,
    icono: "icon-2",
    nombreFuente: "Fuente 2",
    descripcion: "Descripcion 2",
    activado: "Activado",
    tipoData: "Tipo Dato 2",
    categoria: "Categoria 2",
  },
]);

/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});
/* Eliminar */
const deleteFont = (id) => {
  keys.value = keys.value.filter((element) => element.id !== id);
};
</script>
<template>
  <div class="rounded-lg">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Nombre del Esquema</th>
          <th>Descripción</th>
          <th>Activado</th>
          <th>Tipo Data</th>
          <th>Categoria</th>
          <th>Actualización</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in keys" :key="item.id">
          <td>
            <div class="d-flex justify-content-center">
              <img
                :src="csvIcon"
                alt="csv archive"
                class="text-blue-500 d-inline-flex justify-content-center"
                style="
                  color: #517fa4;
                  width: 25px;
                  height: 25px;
                  margin-right: 5px;
                "
              />
            </div>
          </td>
          <td>
            <RouterLink :to="{ name: 'DetailScheme' }" class="globals-a">
              {{ item.nombreFuente }}
            </RouterLink>
            <!-- <a class="globals-a" href="">
                  {{ item.nombreFuente }}
                </a> -->
          </td>
          <td>
            {{ item.descripcion }}
          </td>
          <td>{{ item.activado }}</td>
          <td>{{ item.tipoData }}</td>
          <td>{{ item.categoria }}</td>
          <td>{{ item.actualizacion }}</td>
          <td class="d-flex gap-3" style="margin-top: 3px; border-bottom: none">
            <button class="buttons-group">
              <EyeIcon
                class="text-blue-500 d-flex justify-content-center"
                style="color: #517fa4; width: 20px; height: 20px"
              ></EyeIcon>
            </button>
            <button class="buttons-group delete" @click="deleteFont(item.id)">
              <TrashIcon
                class="text-blue-500 d-flex justify-content-center"
                style="width: 20px; height: 20px"
              ></TrashIcon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
}
.delete:hover {
  background-color: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
}
.rounded-lg {
  border-radius: 10px;
  overflow: hidden;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #ddd;
  color: #243949;
}
</style>
