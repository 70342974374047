import axios from "axios";
import { dataEnv } from "../../config/index.js";
const API_BASE_URL = dataEnv().API_URL;

/**
 * Function to create project
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createProject = (payload) => {
  return axios.post(`${API_BASE_URL}/project/create`, payload);
};

/**
 * Function to get proyects
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getProjects = (clientId) => {
  return axios.get(`${API_BASE_URL}/project/${clientId}/client`);
};

/**
 * Function to edit proyect
 * @param {Object} payload
 * @return AxiosPromise
 */
export const editProject = (payload) => {
  return axios.patch(`${API_BASE_URL}/project/update`, payload);
};

/**
 * Function to get proyect detail
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getProjectDetail = (projectsId) => {
  return axios.get(`${API_BASE_URL}/project/${projectsId}/info`);
};

/**
 * Function to delete proyect
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deleteProject = (projects_id, payload) => {
  return axios.delete(`${API_BASE_URL}/projects/${projects_id}`, payload);
};

/**
 * Function to create rule
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getCategoryMessage = (payload) => {
  return axios.post(
    `${API_BASE_URL}/categoryMessage/search/client/project`,
    payload
  );
};
