<script setup>
import { ref, defineProps, defineEmits, onMounted, watch, computed } from "vue";
import { CheckCircleIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  attribute: String,
  selected: Boolean,
  element: Array,
  selectedColumnData: Array,
  columnTitles: Array,
  onColumnLink: Function,
  isAttributeLinked: Boolean,
});

const emits = defineEmits([
  "select",
  "selectedColumnData",
  "update:element",
  "selectedTitle",
]);

onMounted(() => {
  console.log("Props en el componente hijo (CardFontAttribute):", props);
});

watch(
  () => props.selectedColumnData,
  (newVal) => {
    console.log("selectedColumnData actualizado en CardFontAttribute:", newVal);
    // Asegúrate de que newVal contiene los datos correctos
    if (newVal && newVal.length > 0) {
      console.log("Datos válidos recibidos:", newVal);
    } else {
      console.warn("El array de selectedColumnData está vacío.");
    }
  },
  { immediate: true }
);

const isVisible = ref(false);
/* const columnData = props.selectedColumnData; */

// inicializar el estado de la tarjeta
const initializeCardState = () => {
  const isSelected = ref(props.selected);
  const selectedColumnTitle = ref("");
  return { isSelected, selectedColumnTitle };
};

const { isSelected, selectedColumnTitle } = initializeCardState();

function toggleSeleccionado() {
  isSelected.value = !isSelected.value;

  emits("select", { attribute: props.attribute, selected: isSelected.value });
  if (isSelected.value) {
    setTimeout(() => {
      isSelected.value = true;
    }, 100);
  }
}

function unselect() {
  isSelected.value = false;
}
onMounted(() => {
  isVisible.value = true;
});

watch(selectedColumnTitle, (newTitle) => {
  console.log("Selected title:", newTitle);
});

watch(props.selected, (newSelected) => {
  if (!newSelected) {
    resetSelectedTitle();
  }
});
// Restablecer el título
const resetSelectedTitle = () => {
  selectedColumnTitle.value = "";
};

const visibleColumnData = computed(() => {
  return props.selectedColumnData && Array.isArray(props.selectedColumnData)
    ? props.selectedColumnData.slice(0, 3)
    : [];
});

const handleSelectTitle = (title) => {
  console.log("Título seleccionado:", title);
  emits("selectedTitle", title);

  const filteredData = props.element.filter(
    (item) => item.column_title === title
  );

  if (filteredData.length > 0) {
    console.log("Datos filtrados en CardFontAttribute:", filteredData);
    emits("selectedColumnData", filteredData);
  } else {
    console.warn("El array de valores seleccionados está vacío.");
    emits("selectedColumnData", []);
  }
};

const handleColumnLink = () => {
  props.onColumnLink(props.selectedColumnData);
};
</script>
<template>
  <div class="card gap-4">
    <header class="d-flex justify-content-start gap-2 align-items-center">
      <h4 style="font-size: large; font-weight: 600; color: #517fa4">
        {{ attribute }}
      </h4>
    </header>
    <article style="min-height: 4rem">
      <div v-if="!isAttributeLinked">
        <div v-if="isSelected" class="d-flex justify-content-end py-2">
          <button
            style="
              display: flex;
              justify-content: center;
              width: 28px;
              height: 28px;
              padding: 4px;
            "
            class="globals-border-button"
            @click="unselect"
          >
            <XMarkIcon style="color: black; font-weight: 600"></XMarkIcon>
          </button>
        </div>
        <!-- !Aqui titles -->
        <div
          class="column-text p-2"
          style="max-height: 13rem; overflow: auto; scrollbar-gutter: stable"
        >
          <ul v-if="isSelected" class="list-group list-group-flush column-text">
            <li
              v-for="(title, index) in columnTitles"
              :key="index"
              class="list-group-item form-check form-check-inline"
              style="color: rgb(183, 185, 192)"
            >
              <input
                class="form-check-input"
                type="radio"
                :id="`column-${index}`"
                :value="title"
                v-model="selectedColumnTitle"
                @change="handleSelectTitle(title)"
                @click="$emit('selectColumnTitle', title, true)"
              />
              <label
                class="form-check-label text-truncate"
                style="width: 85%; padding-top: 3px; margin-left: 3px"
                :for="`column-${index}`"
                >{{ title }}</label
              >
              <!-- *Aqui debe salir lista de valores -->
              <div v-if="selectedColumnTitle === title" class="px-2">
                <ul class="list-group list-group-flush column-text">
                  <li
                    v-for="(item, index) in visibleColumnData"
                    :key="index"
                    class="list-group-item"
                    style="color: rgb(183, 185, 192)"
                  >
                    {{ item }}
                  </li>
                  <li
                    class="list-group-item ml-2"
                    style="color: rgb(183, 185, 192)"
                  >
                    . . .
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <transition name="fade">
        <div v-if="isAttributeLinked && isSelected" class="px-2">
          <ul class="list-group list-group-flush column-text">
            <li
              v-for="(item, index) in visibleColumnData"
              :key="index"
              class="list-group-item"
              style="color: rgb(183, 185, 192)"
            >
              {{ item }}
            </li>
            <li class="list-group-item ml-2" style="color: rgb(183, 185, 192)">
              . . .
            </li>
          </ul>
        </div>
      </transition>
    </article>
    <footer v-if="!isSelected" class="d-flex gap-2 justify-content-between">
      <p style="width: 80%; font-size: small">
        Vincula la columna con el atributo
        <span style="font-weight: 600">{{ attribute }}</span>
      </p>
      <button
        style="
          display: flex;
          justify-content: center;
          width: 32px;
          height: 32px;
        "
        class="globals-button"
        :class="{ selected: selected }"
        @click="toggleSeleccionado"
      >
        <PlusIcon style="color: white"></PlusIcon>
      </button>
    </footer>
    <!-- *Atributos vinculados -->
    <!-- Mostrar boton "vincular" cuando se selecciona el título -->
    <div
      v-if="selectedColumnTitle"
      class="d-flex justify-content-center align-items-center"
    >
      <button
        v-if="!isAttributeLinked"
        style="
          display: flex;
          justify-content: center;
          width: auto;
          height: 28px;
          align-items: center;
        "
        class="globals-button py-2 px-4"
        @click="handleColumnLink"
      >
        Vincular
      </button>
      <div
        v-else
        class="d-flex justify-content-between align-items-center gap-2"
      >
        <h4 style="font-size: smaller; color: #182631; margin: 0">
          Atributos Vinculados
        </h4>
        <CheckCircleIcon
          style="color: #77a25e; width: 38px; height: 38px"
        ></CheckCircleIcon>
      </div>
    </div>
  </div>
</template>
<style scoped>
.slow-fade-enter-active,
.slow-fade-leave-active {
  transition: opacity 1s ease;
}
.slow-fade-enter, .slow-fade-leave-to /* .slow-fade-leave-active in <2.1.8 */ {
  opacity: -1;
}
.column-text {
  border-radius: 10px;
  font-size: smaller;
  font-weight: 400;
}

.column-text::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.column-text::-webkit-scrollbar-track {
  background-color: transparent;
}

.column-text::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.column-text::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
