<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import {
  PencilSquareIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/vue/24/solid";
import { getUsers, deleteUser } from "@/services/users";
import { getClients } from "@/services/clients";
import EditUserForm from "./EditUserForm.vue";

const showEditUserForm = ref(false);
const isDropdownOpen = ref(false);
const offcanvasEditUserFormRef = ref(null);
const { data } = defineProps();

const selectedUser = ref(null);
const users = ref([]);
const showDeleteAlert = ref(false);
const deletedUserName = ref("");
const clients = ref([]);
const showSuccessMessage = ref(false);
const isUserEdited = ref(false);

onMounted(async () => {
  const client_id = localStorage.getItem('cx')
  const response = await getUsers(client_id);
  console.log("Fetched USERS:", response);
  if (response.data.length) {
    users.value = response.data;
  }
  console.log("USERS:", users.value);
});
// Obtener el nombre del cliente por ID
const getClientNameById = (clientId) => {
  const client = clients.value.find((p) => p.id === clientId);
  return client ? client.companyName : "";
};

const deleteUserFunction = async (id, name) => {
  try {
    await deleteUser(id);
    users.value = users.value.filter((user) => user.id !== id);
    deletedUserName.value = name;
    showDeleteAlert.value = true;
    setTimeout(() => {
      showDeleteAlert.value = false;
    }, 3000); // Ocultar la alerta después de 3 segundos
  } catch (error) {
    console.error("Error al eliminar usuario:", error);
  }
};

function toggleEditUserForm(user) {
  selectedUser.value = user;
  console.log("Selected Rule:", selectedUser.value);
  showEditUserForm.value = !showEditUserForm.value;
  showSuccessMessage.value = false;
}
/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const handleKeySaved = (newData) => {
  console.log("Datos de la regla actualizada:", data);
  newData.value = newData;
  showEditUserForm.value = false;
  isUserEdited.value = true; // Marcar como editado
  showSuccessMessage.value = true; // Mostrar mensaje de éxito
};
// Función para cerrar el offcanvas
const closeOffcanvas = () => {
  showEditUserForm.value = false;
  if (!isUserEdited.value) {
    showSuccessMessage.value = false;
  }
};
// Escuchar el evento de tecla Esc para cerrar el offcanvas
const handleEscape = (event) => {
  if (event.key === "Escape") {
    closeOffcanvas();
  }
};
onMounted(() => {
  window.addEventListener("keydown", handleEscape);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscape);
});

// Watcher para detectar cambios en showEditRuleForm
watch(showEditUserForm, (newValue) => {
  if (!newValue) {
    // Si showEditRuleForm pasa a false (el formulario se cerró),
    // mostrar el mensaje y opciones
    setTimeout(() => {
      const offcanvasElement = document.querySelector("#offcanvasEditUserForm");
      offcanvasElement.classList.add("show"); // Mostrar el offcanvas
    }, 100);
  }
});
</script>
<template>
  <div class="rounded-lg table-container" style="max-width: 50rem">
    <table class="table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Correo electrónico</th>
          <th style="text-align: center">Cliente</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td style="text-align: center">
            {{ user.client }}
          </td>
          <td
            class="d-flex gap-3 actions-column mx-2 justify-content-around"
            style="margin-top: 3px; border-bottom: none"
          >
            <button
              class="buttons-group"
              @click="toggleEditUserForm(user)"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasEditUserForm"
              aria-controls="offcanvasEditUserForm"
            >
              <PencilSquareIcon
                class="text-blue-500 d-flex justify-content-center"
                style="color: #517fa4; width: 23px; height: 23px"
              ></PencilSquareIcon>
            </button>
            <button
              class="buttons-group delete"
              @click="deleteUserFunction(user.id)"
            >
              <TrashIcon
                class="text-blue-500 d-flex justify-content-center"
                style="width: 23px; height: 23px"
              ></TrashIcon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="showDeleteAlert"
      class="alert alert-dark alert-dismissible fade show"
      role="alert"
    >
      ¡El usuario {{ deletedUserName }} ha sido eliminado exitosamente!
      <button
        type="button"
        class="btn-close"
        @click="showDeleteAlert = false"
        aria-label="Close"
      ></button>
    </div>
    <!-- Alerta de eliminación -->
  </div>
  <div
    class="offcanvas offcanvas-end"
    style="width: 45%"
    data-bs-scroll="true"
    tabindex="-1"
    id="offcanvasEditUserForm"
    aria-labelledby="offcanvasEditUserFormLabel"
    ref="offcanvasEditUserFormRef"
  >
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body" v-if="showEditUserForm">
      <EditUserForm
        :offcanvasRef="offcanvasEditUserFormRef"
        :newKeyData="selectedUser"
        @keySaved="handleKeySaved"
        @cancelEdit="closeOffcanvas"
      ></EditUserForm>
    </div>
    <!-- *Mensaje -->
    <div
      v-if="showSuccessMessage"
      class="offcanvas-body"
      style="text-align: center; margin-top: 8rem"
    >
      <CheckCircleIcon
        style="width: 5rem; height: 5rem; color: #517fa4"
      ></CheckCircleIcon>
      <h4 class="create-title mt-3">El Usuario se editó con éxito.</h4>
      <button
        class="globals-border-button"
        data-bs-dismiss="offcanvas"
        style="margin-top: 3rem"
      >
        Salir
      </button>
    </div>
  </div>
</template>
<style scoped>
.actions-column {
  position: sticky;
  right: 0;
  margin-left: 4px;
  margin-right: 4px;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
}
.delete:hover {
  background-color: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
}
.rounded-lg {
  border-radius: 10px;
  overflow: hidden;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px 8px 6px 8px;
  margin-top: 3px;
  margin: 4px;
  text-align: start;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

th {
  background-color: #ddd;
  color: #243949;
  text-align: start;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 5px 2px 5px 2px;
}

.under-th {
  height: 10px;
  font-size: x-small;
  padding: 2px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
</style>
