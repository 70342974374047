<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  defineEmits,
  /*  defineProps , */
} from "vue";
import {
  PencilSquareIcon,
  TrashIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/24/solid";
import { getProjects, deleteProject } from "@/services/projects";
import CreateProyectForm from "./CreateProyectForm.vue";

const showEditProyectForm = ref(false);
const isDropdownOpen = ref(false);
const offcanvasEditProyectFormRef = ref(null);
/* const { data } = defineProps(); */
const emit = defineEmits(["keySaved"]);

const selectedProyect = ref(null);
const proyects = ref([]);
const showDeleteAlert = ref(false);
const deletedProyectName = ref("");
const showSuccessMessage = ref(false);
const isProjectEdited = ref(false);
const isLoading = ref(true);

onMounted(async () => {
  isLoading.value = true;
  const clientId = localStorage.getItem("cx");
  if (!clientId) {
    console.error("Client ID not found in localStorage");
    isLoading.value = false;
    return;
  }
  try {
    const response = await getProjects(clientId);
    console.log("Fetched Projects:", response);
    if (response.data.length) {
      proyects.value = response.data;
    }
    console.log("PROYECTS:", proyects.value);
  } catch (error) {
    console.error("Error fetching projects:", error);
  } finally {
    isLoading.value = false;
  }
});

const deleteProyectFunction = async (id, name) => {
  try {
    await deleteProject(id);
    proyects.value = proyects.value.filter((proyect) => proyect.id !== id);
    deletedProyectName.value = name;
    showDeleteAlert.value = true;
    setTimeout(() => {
      showDeleteAlert.value = false;
    }, 3000);
  } catch (error) {
    console.error("Error al eliminar proyecto:", error);
  }
};

function toggleEditProyectForm(proyect) {
  showEditProyectForm.value = false;

  selectedProyect.value = proyect;
  console.log("Selected Proyect:", selectedProyect.value);

  setTimeout(() => {
    showEditProyectForm.value = true;
  }, 0);
  showSuccessMessage.value = false;
}

onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const handleKeySaved = async (newData) => {
  const index = proyects.value.findIndex(
    (proyect) => proyect.id === newData.id
  );
  if (index !== -1) {
    proyects.value[index] = newData;
  } else {
    proyects.value.push(newData);
  }

  showEditProyectForm.value = false;
  isProjectEdited.value = true;
  showSuccessMessage.value = true;

  await fetchProjects();
};

const fetchProjects = async () => {
  isLoading.value = true;
  const clientId = localStorage.getItem("cx");
  if (!clientId) {
    console.error("Client ID not found in localStorage");
    isLoading.value = false;
    return;
  }
  try {
    const response = await getProjects(clientId);
    console.log("Fetched Projects:", response);
    if (response.data.length) {
      proyects.value = response.data;
    }
  } catch (error) {
    console.error("Error fetching projects:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchProjects);

const closeOffcanvas = () => {
  showEditProyectForm.value = false;
  if (!isProjectEdited.value) {
    showSuccessMessage.value = false;
  }
};

const handleEscape = (event) => {
  if (event.key === "Escape") {
    closeOffcanvas();
  }
};
onMounted(() => {
  window.addEventListener("keydown", handleEscape);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscape);
});

// Watcher para detectar cambios en showEditRuleForm
watch(showEditProyectForm, (newValue) => {
  if (!newValue) {
    setTimeout(() => {
      const offcanvasElement = document.querySelector(
        "#offcanvasEditProyectForm"
      );
      offcanvasElement.classList.add("show");
    }, 100);
  }
});

const cancelEdit = () => {
  emit("cancelEdit");
};

const activeCollapse = ref(null);

const toggleComunicationsCollapse = (id) => {
  if (activeCollapse.value === id) {
    activeCollapse.value = null;
  } else {
    activeCollapse.value = id;
  }
};
</script>
<template>
  <div>
    <div class="rounded-lg table-container" style="width: auto">
      <table class="table">
        <thead>
          <tr>
            <th>Proyecto</th>
            <th>Límite diario de Contactabilidad</th>
            <th>Prioridades</th>
            <th>Límite diario de Puntos</th>
            <th>Comunicaciones Agregadas</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="7" class="text-center">
              <div
                class="spinner-border mt-4"
                style="width: 3rem; height: 3rem; color: #517fa4"
                role="status"
              >
                <span class="visually-hidden">Cargando...</span>
              </div>
              <p
                class="mt-3"
                style="color: #243949; font-size: large; font-weight: 600"
              >
                Cargando proyectos, por favor espera...
              </p>
            </td>
          </tr>
          <!-- mensaje si no hay proyectos -->
          <tr v-else-if="proyects.length === 0">
            <td colspan="7" class="text-center">
              No hay proyectos disponibles.
            </td>
          </tr>

          <tr
            v-else
            v-for="(proyect, index) in proyects"
            :key="proyect.id || index"
          >
            <td style="text-align: start; text-transform: capitalize">
              {{ proyect.name }}
            </td>
            <td>{{ proyect.timesContactDaily }}</td>
            <td style="text-align: center">
              <CheckCircleIcon
                v-if="
                  proyect.communications && proyect.communications.length > 0
                "
                style="
                  color: #517fa4;
                  width: 25px;
                  height: 25px;
                  margin-right: 5px;
                "
              />
              <XCircleIcon
                v-else
                style="
                  color: #182631;
                  width: 25px;
                  height: 25px;
                  margin-right: 5px;
                "
              ></XCircleIcon>
            </td>
            <td>{{ proyect.limitPoints }}</td>
            <td
              v-if="proyect.communications && proyect.communications.length > 0"
            >
              <button
                class="globals-a"
                style="
                  background: rgb(216, 224, 228);
                  border: none;
                  border-radius: 10px;
                  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
                  width: 100%;
                "
                type="button"
                @click="toggleComunicationsCollapse(proyect.id || index)"
              >
                {{ proyect.communications.length }}
              </button>
              <div
                :id="`collapseComunications-${proyect.id}`"
                :class="{
                  'collapse show': activeCollapse === (proyect.id || index),
                  collapse: activeCollapse !== (proyect.id || index),
                }"
              >
                <div class="card card-body d-flex flex-column gap-2">
                  <div
                    class="d-flex gap-2"
                    v-for="comunication in proyect.communications"
                    :key="comunication.id"
                  >
                    <span
                      style="
                        width: 8rem;
                        text-align: start;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ comunication.name }}
                    </span>
                    <span class="pill" style="background: #243949"
                      >Puntaje: {{ comunication.points }}</span
                    >
                  </div>
                </div>
              </div>
            </td>
            <td v-else>No hay comunicaciones</td>
            <td
              class="d-flex gap-3 actions-column mx-2"
              style="margin-top: 3px; border-bottom: none"
            >
              <button
                class="buttons-group"
                @click="toggleEditProyectForm(proyect)"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasEditProyectForm"
                aria-controls="offcanvasEditProyectForm"
              >
                <PencilSquareIcon
                  class="text-blue-500 d-flex justify-content-center"
                  style="color: #517fa4; width: 20px; height: 20px"
                ></PencilSquareIcon>
              </button>
              <button
                class="buttons-group delete"
                @click="deleteProyectFunction(proyect.id)"
              >
                <TrashIcon
                  class="text-blue-500 d-flex justify-content-center"
                  style="width: 20px; height: 20px"
                ></TrashIcon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="showDeleteAlert"
        class="alert alert-dark alert-dismissible fade show"
        role="alert"
      >
        El proyecto {{ deletedProyectName }} ha sido eliminado.
        <button
          type="button"
          class="btn-close"
          @click="showDeleteAlert = false"
          aria-label="Close"
        ></button>
      </div>
      <!-- Alerta de eliminación -->
    </div>
    <div
      class="offcanvas offcanvas-end"
      style="width: 60%"
      data-bs-scroll="true"
      tabindex="-1"
      id="offcanvasEditProyectForm"
      aria-labelledby="offcanvasEditProyectFormLabel"
      ref="offcanvasEditProyectFormRef"
    >
      <div class="offcanvas-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="cancelEdit"
        ></button>
      </div>
      <div
        class="offcanvas-body"
        style="padding: 2% 8% 2% 8%; height: 100%"
        v-if="showEditProyectForm"
      >
        <CreateProyectForm
          :projectData="selectedProyect"
          @updateProject="handleKeySaved"
        />
      </div>
      <!-- *Mensaje -->
      <div
        v-if="showSuccessMessage"
        class="offcanvas-body"
        style="text-align: center; margin-top: 8rem"
      >
        <CheckCircleIcon
          style="width: 5rem; height: 5rem; color: #517fa4"
        ></CheckCircleIcon>
        <h4 class="create-title mt-3">El Proyecto se editó con éxito.</h4>
        <button
          class="globals-border-button"
          data-bs-dismiss="offcanvas"
          style="margin-top: 3rem"
        >
          Salir
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.pill {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #517fa4;
  color: whitesmoke;
  padding: 0px 10px 0px 10px;
  font-size: smaller;
  margin: 0 5px 0 5px;
}
.actions-column {
  position: sticky;
  right: 0;
  margin-left: 4px;
  margin-right: 4px;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
}
.delete:hover {
  background-color: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
}
.rounded-lg {
  border-radius: 10px;
  overflow: hidden;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px 8px 6px 8px;
  margin-top: 3px;
  margin: 4px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  width: auto;
}

th {
  background-color: #ddd;
  color: #243949;
  text-align: center;
  justify-content: start;
  padding: 15px 10px;
  margin: 3px 2px 3px 2px;
}

.under-th {
  height: 10px;
  font-size: x-small;
  padding: 2px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
</style>
