<script setup>
import { useRoute } from 'vue-router';
import CreateChannelForm from '../../components/Channels/CreateChannelForm.vue';


const route = useRoute()
console.log("RUTA CREATE Channel PAPA")

</script>
<template>
    <div v-if="route.name === 'DashboardChannelCreate'" class="">
        <h5 class="create-title my-4">Crear Canal</h5>
        <div>
            <CreateChannelForm></CreateChannelForm>
        </div>
    </div>
</template>