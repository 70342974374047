<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { CheckCircleIcon, KeyIcon } from '@heroicons/vue/24/solid';
import DataTable from '@/components/Data/Structure/DataTable'
import CreateKeyForm from '@/components/Data/Structure/CreateKeyForm'
import CreateAttributeForm from '@/components/Data/Structure/CreateAttributeForm.vue';

console.log("RUTA DATA structure")
const route = useRoute()
const showCreateKeyForm = ref(false);
const showCreateAttributeForm = ref(false);
const offcanvasKeyRef = ref(null);
const offcanvasAttributeRef = ref(null);
const keys = ref([]); // Datos iniciales de la tabla
const newKeyData = ref(null);
const newAttributeData = ref(null);
/* const newKeyData = ref([]); */

function toggleCreateKeyForm() {
  showCreateKeyForm.value = !showCreateKeyForm.value;
}
function toggleCreateAttibuteForm() {
  showCreateAttributeForm.value = !showCreateAttributeForm.value;
}

/* function keySavedHandlerKey(newData) {
  keys.value.push(newData); // Almacenar los nuevos datos en la variable compartida
  showCreateKeyForm.value = false; // Cerrar el formulario 
  const offcanvasElement = document.querySelector('#offcanvasCreateKey');
  offcanvasElement.querySelector('.offcanvas-header .btn-close').click();
} */
/* function keySavedHandlerAttribute(newData) {
  keys.value.push(newData); // Almacenar los nuevos datos en la variable compartida
  showCreateAttributeForm.value = false; // Cerrar el formulario 
  const offcanvasElement = document.querySelector('#offcanvasCreateAttribute');
  offcanvasElement.querySelector('.offcanvas-header .btn-close').click();
} */
const addKey = (newKey) => {
  newKeyData.value = newKey;
  showCreateKeyForm.value = false; // Cerrar el formulario
};
const addAttribute = (newAttribute) => {
  newAttributeData.value = newAttribute;
  showCreateAttributeForm.value = false; // Cerrar el formulario
};

// Función para crear otra llave
const createAnotherKey = () => {
  showCreateKeyForm.value = true;
};
const createAnotherAttribute = () => {
  showCreateAttributeForm.value = true;
};

// Función para cerrar el offcanvas
const closeOffcanvas = () => {
  showCreateAttributeForm.value = false;
};

// Escuchar el evento de tecla Esc para cerrar el offcanvas
const handleEscapeKey = (event) => {
  if (event.key === 'Escape') {
    closeOffcanvas();
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleEscapeKey);
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleEscapeKey);
});

// Watcher para detectar cambios en showCreateAttributeForm
watch(showCreateAttributeForm, (newValue) => {
  if (!newValue) {
    // Si showCreateAttributeForm pasa a false (el formulario se cerró),
    // mostrar el mensaje y opciones
    setTimeout(() => {
      const offcanvasElement = document.querySelector('#offcanvasCreateAttribute');
      offcanvasElement.classList.add('show'); // Mostrar el offcanvas
    }, 100);
  }
});

</script>
<template>
    <div v-if="route.name === 'DataStructure'" class="">
        <h5 class="create-title my-4">Estructura de Datos</h5>
        <article>
            <div class="d-flex gap-5">
                <p style="color: rgb(125, 143, 152); text-align: justify;">
                    Esta configuración te ayudará a definir los datos de tus archivos
                    que usarás como Llaves, y a crear una taxonomía interna que te 
                    ayude a homologar los datos de todas las fuentes que crees en tu catálogo.
                </p>
                <div class="d-flex justify-content-center my-3" style="max-height: 2.8rem;">
                    <button class="globals-button" @click="toggleCreateKeyForm()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCreateKey" aria-controls="offcanvasCreateKey">
                        <KeyIcon class="text-blue-500 d-inline-flex justify-content-center" style="width: 20px; height: 20px; margin-right: 5px;"></KeyIcon>
                        Crear Llave
                    </button>
                </div>
                <div class="d-flex justify-content-center my-3" style="max-height: 2.8rem;">
                    <button class="globals-button" @click="toggleCreateAttibuteForm()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCreateAttribute" aria-controls="offcanvasCreateAttribute">
                        Crear Atributo</button>
                </div>  
            </div>
            <div>
                <DataTable :newKeyData="newKeyData" :newAttributeData="newAttributeData"></DataTable>
            </div>
        </article>
        <div class="offcanvas offcanvas-end" style="width: 40%;" data-bs-scroll="true" tabindex="-1" id="offcanvasCreateKey" aria-labelledby="offcanvasCreateKeyLabel" ref="offcanvasKeyRef">
            <div class="offcanvas-header">
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body" v-if="showCreateKeyForm">
                <CreateKeyForm :offcanvasRef="offcanvasKeyRef" @keySaved="addKey"></CreateKeyForm>
            </div>
            <!-- *Mensaje -->
            <div v-if="!showCreateKeyForm" class="offcanvas-body" style="text-align: center; margin-top: 8rem;">
                <CheckCircleIcon style="width: 5rem; height: 5rem; color: #517fa4;"></CheckCircleIcon>
                <h4 class="create-title mt-3">Llave creada exitosamente.</h4>
                <p>¿Que deseas hacer?</p>
                <div class="d-flex justify-content-around" style="margin-top: 4rem;">
                    <button class="globals-border-button" data-bs-dismiss="offcanvas">Salir</button>
                    <button class="globals-button" @click="createAnotherKey">Crear Nueva Llave</button>
                </div>
            </div>
        </div>
        <!-- !Inicia offcanvas -->
        <div class="offcanvas offcanvas-end" style="width: 40%;" data-bs-scroll="true" tabindex="-1" id="offcanvasCreateAttribute" aria-labelledby="offcanvasCreateAttributeLabel" ref="offcanvasAttributeRef">
            <div class="offcanvas-header">
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body" v-if="showCreateAttributeForm" >
                <CreateAttributeForm :offcanvasRef="offcanvasAttributeRef" @keySaved="addAttribute"></CreateAttributeForm>
            </div>
            <!-- *Mensaje -->
            <div v-if="!showCreateAttributeForm" class="offcanvas-body" style="text-align: center; margin-top: 8rem;">
                <CheckCircleIcon style="width: 5rem; height: 5rem; color: #517fa4;"></CheckCircleIcon>
                <h4 class="create-title mt-3">Atributo creado exitosamente.</h4>
                <p>¿Que deseas hacer?</p>
                <div class="d-flex justify-content-around" style="margin-top: 4rem;">
                    <button class="globals-border-button" data-bs-dismiss="offcanvas">Salir</button>
                    <button class="globals-button" @click="createAnotherAttribute">Crear Nuevo Atributo</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
