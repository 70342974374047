<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue";
import { getPlans, createContract, updateContract } from "@/services/contracts";
import { getClients } from "@/services/clients";
import CreatedContract from "./CreatedContract.vue";

const emailError = ref(false);
const showModal = ref(false);
const isEditMode = ref(false);

const isLoadingPlans = ref(true);
const isLoadingClients = ref(true);

const props = defineProps({
  contractData: Object,
});
const isSubmitting = ref(false);
const emit = defineEmits(["updateContract", "cancelEdit"]);

const contractData = ref({
  clientsId: "",
  contractTitle: "",
  planType: "",
  paymentDate: "",
  clientName: "",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
});

const planTypeOptions = ref([
  { plansId: "", name: "Selecciona tu Tipo de Plan", description: "" },
]);

const clientsOptions = ref([
  { clientsId: "", clientName: "Selecciona un Cliente" },
]);

const formatPhoneDisplay = (phone) => {
  if (!phone) return "";

  let cleaned = ("" + phone).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);

  if (match) {
    return (
      `(${match[1]}` +
      (match[2] ? `) ${match[2]}` : "") +
      (match[3] ? `-${match[3]}` : "")
    );
  }
  return phone;
};

const validateEmail = () => {
  const email = contractData.value.contactEmail;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  emailError.value = !emailPattern.test(email);
};

const formatDateForInput = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const updateClientName = () => {
  const selectedClient = clientsOptions.value.find(
    (client) => client.clientsId === contractData.value.clientsId
  );
  contractData.value.clientName = selectedClient
    ? selectedClient.clientName
    : "";
};

//Validacion
const missingFieldsError = ref(false);

const validateContractData = () => {
  let requiredFields;

  if (isEditMode.value) {
    requiredFields = [
      "contractTitle",
      "planType",
      "paymentDate",
      "contactName",
      "contactPhone",
      "contactEmail",
    ];
  } else {
    // modo creación todos los campos son obligatorios
    requiredFields = [
      "clientsId",
      "contractTitle",
      "planType",
      "paymentDate",
      "contactName",
      "contactPhone",
      "contactEmail",
    ];
  }

  const isValid = requiredFields.every((field) => contractData.value[field]);

  if (!isValid) {
    missingFieldsError.value = true;
    console.log("Faltan datos en algunos campos obligatorios");
  } else {
    missingFieldsError.value = false;
  }

  validateEmail();

  return isValid && !emailError.value;
};

const generateContract = () => {
  if (validateContractData()) {
    if (!contractData.value.planType) {
      contractData.value.planType = props.contractData.planType || "";
    }
    const payload = {
      ...contractData.value,
    };

    if (isEditMode.value) {
      isSubmitting.value = true;
      updateContract(payload)
        .then((response) => {
          console.log("Contrato actualizado exitosamente:", response.data);
          emit("updateContract", response.data);
          showModal.value = true;
        })
        .catch((error) => {
          console.error("Error al actualizar el contrato:", error);
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    } else {
      isSubmitting.value = true;
      createContract(payload)
        .then((response) => {
          console.log("Contrato generado exitosamente:", response.data);
          showModal.value = true;
          emit("contractCreated", response.data);
          resetForm();
        })
        .catch((error) => {
          console.error("Error al crear contrato:", error);
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }
  } else {
    console.log("Hay errores en los datos del formulario o faltan campos.");
  }
};

const resetForm = () => {
  contractData.value = {
    clientsId: "",
    contractTitle: "",
    planType: "",
    paymentDate: "",
    clientName: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
  };
  emailError.value = false;
  isEditMode.value = false;
};

onMounted(() => {
  if (props.contractData && Object.keys(props.contractData).length > 0) {
    console.log("Datos del contrato:", props.contractData);
    isEditMode.value = true;

    const formattedDate = formatDateForInput(props.contractData.paymentDate);
    contractData.value = {
      ...props.contractData,
      paymentDate: formattedDate,
      planType: props.contractData.planType || "",
    };
  }
  //Traer planes
  getPlans()
    .then((response) => {
      const plans = response.data;
      const options = plans.map((plan) => ({
        plansId: plan.plansId,
        name: plan.name,
        description: plan.description,
      }));

      planTypeOptions.value = [
        { plansId: "", name: "Selecciona tu Tipo de Plan", description: "" },
        ...options,
      ];

      if (isEditMode.value && contractData.value.planType) {
        const currentPlan = contractData.value.planType;
        const selectedPlan = options.find((plan) => plan.name === currentPlan);

        if (selectedPlan) {
          // Asignar el planType al valor encontrado en las opciones
          contractData.value.planType = selectedPlan.plansId;
        } else {
          planTypeOptions.value.push({
            plansId: currentPlan,
            name: currentPlan,
            description: "",
          });
        }
      }
    })
    .catch((error) => {
      console.error("Error al obtener los planes:", error);
      planTypeOptions.value = [
        { plansId: "", name: "Error al cargar los planes", description: "" },
      ];
    })
    .finally(() => {
      isLoadingPlans.value = false;
    });

  //Traer clientes

  getClients()
    .then((response) => {
      const clients = response.data;
      console.log("Clientes :", response.data);
      const options = clients.map((client) => ({
        clientsId: client.clientsId,
        clientName: client.companyName,
      }));
      console.log("Options:", options);

      if (isEditMode.value && contractData.value.clientsId) {
        const currentClientId = contractData.value.clientsId;
        const exists = options.some(
          (option) => option.clientsId === currentClientId
        );
        if (!exists) {
          options.push({
            clientsId: currentClientId,
            clientName:
              contractData.value.clientName || "Cliente no disponible",
          });
        }
      }
      clientsOptions.value = [
        { clientsId: "", clientName: "Selecciona un Cliente" },
        ...options,
      ];

      if (isEditMode.value) {
        const selectedClient = options.find(
          (client) => client.clientsId === contractData.value.clientsId
        );
        if (selectedClient) {
          contractData.value.clientName = selectedClient.clientName;
        }
      }
    })
    .catch((error) => {
      console.error("Error al obtener los clientes:", error);
      clientsOptions.value = [
        { clientsId: "", clientName: "Error al cargar los clientes" },
      ];
    })
    .finally(() => {
      isLoadingClients.value = false;
    });
});

const cancelEdit = () => {
  emit("cancelEdit");
  contractData.value = {
    clientsId: "",
    contractTitle: "",
    planType: "",
    paymentDate: "",
    clientName: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
  };
  isEditMode.value = false;
};
</script>
<template>
  <div>
    <article>
      <h2 class="create-title mb-3">
        {{ isEditMode ? `Editar Contrato: ${contractData.contractTitle}` : "" }}
      </h2>
      <form @submit.prevent="generateContract" class="d-flex flex-column gap-3">
        <div class="mb-3">
          <label class="form-label globals-label" style="font-size: 16px"
            >Título del Contrato</label
          >
          <input
            type="text"
            class="form-control globals-input"
            v-model="contractData.contractTitle"
          />
        </div>
        <div class="mb-3 d-flex flex-row w-100 gap-3">
          <div class="w-50">
            <label class="form-label globals-label" style="font-size: 16px"
              >Tipo de plan</label
            >
            <select
              class="form-select form-select-details"
              v-model="contractData.planType"
              :disabled="isLoadingPlans"
              required
            >
              <option
                v-for="option in planTypeOptions"
                :key="option.plansId"
                :value="option.plansId"
              >
                {{ option.name }}
              </option>
            </select>
            <!--  <div v-if="isLoadingPlans" class="text-muted">
              Cargando planes...
            </div>
            <div
              v-else-if="
                planTypeOptions.length === 1 &&
                planTypeOptions[0].name === 'Error al cargar los planes'
              "
              class="text-danger"
            >
              No se pudieron cargar los planes. Intenta de nuevo más tarde.
            </div> -->
          </div>
          <div class="w-50">
            <label class="form-label globals-label" style="font-size: 16px"
              >Fecha de pago</label
            >
            <input
              type="date"
              class="form-control globals-input"
              v-model="contractData.paymentDate"
            />
          </div>
        </div>
        <div class="w-100">
          <label class="form-label globals-label" style="font-size: 16px"
            >Cliente</label
          >
          <div v-if="isEditMode">
            <input
              type="text"
              class="form-control globals-input"
              v-model="contractData.clientName"
              disabled
            />
          </div>
          <div v-else>
            <select
              class="form-select form-select-details"
              v-model.number="contractData.clientsId"
              :disabled="isLoadingClients"
              @change="updateClientName"
            >
              <option
                v-for="option in clientsOptions"
                :key="option.clientsId"
                :value="option.clientsId"
              >
                {{ option.clientName }}
              </option>
            </select>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label globals-label" style="font-size: 16px"
            >Nombre de Contacto</label
          >
          <input
            type="text"
            class="form-control globals-input"
            v-model="contractData.contactName"
          />
        </div>
        <div class="mb-3 d-flex flex-row w-100 gap-3">
          <div class="w-50">
            <label class="form-label globals-label" style="font-size: 16px"
              >Teléfono de Contacto</label
            >
            <input
              class="form-control globals-input"
              :value="formatPhoneDisplay(contractData.contactPhone)"
              @input="
                contractData.contactPhone = $event.target.value.replace(
                  /\D/g,
                  ''
                )
              "
            />
          </div>
          <div class="w-50">
            <label class="form-label globals-label" style="font-size: 16px"
              >Email de Contacto</label
            >
            <input
              type="email"
              class="form-control globals-input"
              v-model="contractData.contactEmail"
              @input="validateEmail"
              :class="{ 'is-invalid': emailError }"
            />
            <div v-if="emailError" class="invalid-feedback">Email inválido</div>
          </div>
        </div>
        <div v-if="missingFieldsError" class="text-danger text-center">
          Todos los campos son obligatorios. Por favor, completa antes de
          generar el contrato.
        </div>

        <div class="d-flex justify-content-center my-3 gap-5">
          <button
            v-if="isEditMode"
            @click="cancelEdit"
            class="globals-border-button"
            data-bs-dismiss="offcanvas"
          >
            Cancelar
          </button>
          <button type="submit" class="globals-button">
            {{ isEditMode ? "Guardar Cambios" : "Generar Contrato" }}
          </button>
        </div>
      </form>
    </article>
    <div v-if="showModal" class="modal-background">
      <div class="modal-content">
        <CreatedContract />
      </div>
    </div>
  </div>
</template>
<style scoped>
.text-error {
  color: red;
  font-size: 0.8em;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  gap: 10px;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin-left: 3.5rem;
  z-index: 100;
  background: white;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
</style>
