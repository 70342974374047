import axios from "axios";
import { dataEnv } from "../../config/index.js";
const API_BASE_URL = dataEnv().API_URL;

/**
 * Function to create rule
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createRule = (payload) => {
  return axios.post(`${API_BASE_URL}/rules`, payload);
};

/**
 * Function to get rule
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getRules = (clientId) => {
  return axios.get(`${API_BASE_URL}/rules/${clientId}/client?clientId=1`);
};

/**
 * Function to edit rule
 * @param {Object} payload
 * @return AxiosPromise
 */
export const editRules = (payload) => {
  return axios.patch(`${API_BASE_URL}/rules`, payload);
};

/**
 * Function to delete rule
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deleteRules = (rulesId, payload) => {
  return axios.delete(`${API_BASE_URL}/rules/${rulesId}`, payload);
};

/**
 * Function to get rule detail
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getRuleDetail = (rulesId) => {
  return axios.get(`${API_BASE_URL}/rules/${rulesId}/info`);
};
