<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  img: String,
  title: String,
  text: String,
  button: String,
  selected: Boolean,
  styleClass: String,
});

const emits = defineEmits(["cardButtonClick"]);

const isSelected = ref(props.selected);

function toggleSeleccionado() {
  isSelected.value = !isSelected.value;
  // Emitir un evento cuando se hace clic en el botón de la tarjeta
  emits("cardButtonClick", props.title);
}
</script>
<template>
  <div :class="['card', 'gap-4', props.styleClass]">
    <header class="d-flex justify-content-between gap-2 align-items-center">
      <div style="width: 5rem; height: 5rem">
        <img class="card-img" :src="img" alt="imagen" />
      </div>
      <h3 class="h3" style="padding-right: 8rem">
        {{ title }}
      </h3>
    </header>
    <article>
      <p class="p">
        {{ text }}
      </p>
    </article>
    <footer class="d-flex justify-content-center">
      <button
        style="max-width: 8rem"
        class="globals-button"
        :class="{ selected: selected }"
        @click="toggleSeleccionado"
      >
        {{ button }}
      </button>
    </footer>
  </div>
</template>
