<script setup>
import { useRoute } from 'vue-router';
import UsersTable from '@/components/Users/UsersTable.vue';

const route = useRoute()
console.log("RUTA edit USER PAPA")

</script>
<template>
    <div class="">
        <h5 class="create-title my-4">Usuarios Registrados</h5>
        <div>
            <UsersTable></UsersTable>
        </div>
    </div>
</template>
