<script setup>
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline';
import { InformationCircleIcon } from '@heroicons/vue/24/solid';
import { ref } from 'vue';
const isComponentVisible = ref(true);
</script>
<template>
    <div style="background: white;" v-if="isComponentVisible" >
        <h5 class="create-title my-4">Generar Credencial</h5>
        <article class="article">
            <h4>Acceso a Golden Contactability S3</h4>
            <p>
                Usa cualquier cliente de conexión a Amazon S3, como Cyberduck, para acceder a Golden Contactability S3
                 y subir tus archivos con la siguiente credencial.
            </p>
            <div class="d-flex flex-column gap-3 w-50 mt-4">
                <div class="order">
                    <h5>Actualizar Credenciales GoldenContactability S3</h5>
                    <a class="a" href="">ACTUALIZAR</a>
                </div>
                <div class="order">
                    <h5>
                        Bucket Name
                        <div class="d-flex align-items-center gap-2">
                            <span class="under">
                                s3://goldencontactability-clients/--  
                            </span>
                            <span><DocumentDuplicateIcon style="width: 20px; height: 20px;"></DocumentDuplicateIcon></span>   
                        </div>
                    </h5>
                    <a class="a" href="">COPIAR</a>
                </div>
                <div class="order">
                    <h5>
                        Access Key ID
                        <div class="d-flex align-items-center gap-2">
                            <span class="under">
                                SKJDAMSAS1223DFDFDSF   
                            </span>
                            <span><DocumentDuplicateIcon style="width: 20px; height: 20px;"></DocumentDuplicateIcon></span>   
                        </div>
                    </h5>
                    <a class="a" href="">COPIAR</a>
                </div>
                <div class="order">
                    <h5>
                        Secure Access Key
                        <div class="d-flex align-items-center gap-2">
                            <span class="under">
                                SKJDAMSAS1223/kjsaAJSJKA29393mnnm    
                            </span>
                            <span><DocumentDuplicateIcon style="width: 20px; height: 20px;"></DocumentDuplicateIcon></span>   
                        </div>
                    </h5>
                    <a class="a" href="">COPIAR</a>
                </div>
            </div>
        </article>
        <div class="info">
            <div class="d-flex align-items-center gap-2 mb-1">
                <InformationCircleIcon style="width: 30px; height: 30px;"></InformationCircleIcon>
                <h4 class="m-0 mt-2">Información</h4>
            </div>
            <p class="m-0">Una vez hayas subido tus archivos, tu equipo podrá tener acceso a ellos en 
                <span style="font-weight: 700;">Menú > Esquemas > Subir Esquema</span>. Aparecerá un acceso directo a tus 
                archivos con <span style="font-weight: 700;">Golden Contactability S3</span>.
            </p>
        </div>

    </div>
</template>
<style scoped>
.a{
    color: #517fa4;
    text-decoration: underline;
    font-weight: 600; 
    &:hover {
        color: #99bbd6;
        transition: all ease-in-out 0.3s;
    }
}
.article{
    background-color: rgb(239, 242, 245);
    border-radius: 15px;
    color: #243949;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
}
.under {
    display: block;
    color: gray;
    font-size: smaller;
    font-weight: 300;
    margin: 0;
}
.order {
    display: flex;
    justify-content: space-between;
}
.info {
    background-color: #364a58;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
    color: aliceblue;
    border-radius: 15px ;
    padding: 10px 20px;

}
</style>