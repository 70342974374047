<script setup>
import { ref, defineEmits, onMounted, watch, computed } from 'vue';
import csvIcon from '@/assets/csvIcon.svg';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import CardFontAttribute from '@/components/common/CardFontAttribute.vue';
import CreatingFont from '@/components/Data/Schemes/CreatingFont.vue';
import Papa from 'papaparse';

const file = ref(null);
const title = ref('');
const filename = ref('');
const delimiter = ref('');

let csvData= ref([]);

let columnTitles = ref([]);
const showSecondSection = ref(false);
const nameConnection = ref ('');
const fontDescription = ref ('');
const fontCategory = ref ('');

const showThirdSection = ref(false);
const creatingFontVisible = ref(false);

const submitForm = () => {
    if (file.value && !showSecondSection.value) {
        showSecondSection.value = true;
    } else if (file.value && showSecondSection.value) {
        console.log('Uploading file:', file.value);
        console.log('Title:', title.value);
        console.log('Delimiter:', delimiter.value);
        console.log('CSV Data:', csvData);
        // enviar datos a bd
    }
};

const updateFilename = () => {
  if (file.value) {
    filename.value = file.value.name;
  }
};
// Procesar archivo CSV
const handleSuccess = (file) => {
    if (file) {
        console.log('Archivo subido exitosamente', file);
        console.log('Procesando CSV data...');
        Papa.parse(file.value, {
            header: true,
            skipEmptyLines: true,
            delimiter: delimiter === '' ? ',' : delimiter,
            complete: (results) => {
                console.log('CSV data:', results.data);
                csvData.value = results.data;
                // Asignar los títulos de las columnas a columnTitles
                columnTitles.value = results.meta.fields;
                console.log('COLUMN TITLES data:', results.meta.fields);
                // Iterar en el array
                cardAttributes.value.forEach(card => {
                    console.log(`Valores de ${card.attribute}:`);
                    csvData.value.forEach(object => {
                        // Verificar si el objeto tiene la propiedad correspondiente al atributo en cardAttributes
                        if (object.hasOwnProperty(card.attribute)) {
                            // Imprimir el valor de la propiedad
                            console.log('- ', object[card.attribute]);
                        }
                    });
                });
                
            },
            error: (error) => {
                console.log('Error al procesar el archivo CSV:', error);
            },
        });
    } else {
        console.log('Error al subir el archivo')
    }
}

const handleFileChange = (event) => {
    file.value = event.target.files[0];
    updateFilename();
    handleSuccess(file);
};

onMounted(() => {
    scrollToSecondSection();
});

const submitSecondForm = () => {
    if (nameConnection.value && !showThirdSection.value) {
        showThirdSection.value = true;
    } else if (nameConnection.value && showThirdSection.value){
        console.log('Connection name:', nameConnection.value);
        console.log('Description:', fontDescription.value);
        console.log('Category:', fontCategory.value);
        // Manejar el envío del segundo formulario
    }
};

const submitThirdForm = () => {
    if (nameConnection.value && showThirdSection.value && selectedColumnData.value.length > 0) {
    console.log('Selected column data:', columnDataFull.value);
  }
};

const emits = defineEmits(['closeModal']);
// restablecer los valores iniciales y cerrar la ventana 
const resetAndCloseModal = () => {
  reset();
  emits('closeModal');
};

const reset = () => {
    file.value = null;
    title.value = '';
    filename.value = '';
    delimiter.value = '';
    nameConnection.value = '';
    fontDescription.value = '';
    fontCategory.value = '';
    showSecondSection.value = false;
};

// Verificar campos step 1
const areFieldsComplete = () => {
  return !!file.value && !!filename.value && !!title.value && !!delimiter.value;
};
console.log('corroborando:', areFieldsComplete())
// Verificar campos step 2
const areFieldsCompleteSecond = () => {
  return !!nameConnection.value && !!fontDescription.value && !!fontCategory.value;
};
console.log('corroborando step2:', areFieldsCompleteSecond())

const scrollToSecondSection = () => {
    setTimeout(() => {
        const secondSection = document.getElementById('secondSection');
        if (secondSection) {
            window.scrollTo({
                top: secondSection.offsetTop,
                behavior: 'smooth'
            });
        }
    }, 200);
};

// Observa el cambio en showSecondSection y llama a scrollToSecondSection cuando cambia a true
watch(showSecondSection, (newValue) => {
    if (newValue) {
        scrollToSecondSection();
    }
});

const scrollToThirdSection = () => {
    setTimeout(() => {
        const thirdSection = document.getElementById('thirdSection');
        if (thirdSection) {
            window.scrollTo({
                top: thirdSection.offsetTop,
                behavior: 'smooth'
            });
        }
    }, 200);
};

// Observa el cambio en showSecondSection y llama a scrollToSecondSection cuando cambia a true
watch(showThirdSection, (newValue) => {
    if (newValue) {
        scrollToThirdSection();
    }
});

const isDropdownOpen = ref(false);
const selectedKey = ref(null);
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
}
/* Click fuera del dropdown */
onMounted(() => { 
  document.addEventListener('click', (event) => {
    if (!event.target.closest('.dropdown')) {
      isDropdownOpen.value = false;
    }
  });
});
function selectKey(key) {
  selectedKey.value = key;
}
/* Attributes Cards */
const cardAttributes = ref ([
    {
      attribute: 'EMISORAS',
      selected: false
    },
    {
      attribute: 'Género',
      selected: false
    },
    {
      attribute: 'ESTADO',
      selected: false
    },
    {
      attribute: 'MDI',
      selected: false
    },
    {
      attribute: 'MDI Android' ,
      selected: false
    },
    {
      attribute: 'Phone' ,
      selected: false
    },
    {
      attribute: 'Rango de Edad' ,
      selected: false
    },
    {
      attribute: 'Empresa' ,
      selected: false
    },
  ]);
const searchTerm = ref('');
const filteredCardAttributes = computed(() => {
  return cardAttributes.value.filter(card => card.attribute.toLowerCase().includes(searchTerm.value.toLowerCase()));
});

const selectedAttributeData = ref([]);
const selectedColumnTitle = ref('');
const selectedColumnData = ref([]);

const handleSelectAttribute = (selectedAttribute, selectedTitle) => {
    console.log('Atributo seleccionado:', selectedAttribute);
    console.log('Título seleccionado:', selectedTitle);
    emits('select', { attribute: selectedAttribute, title: selectedTitle });
    if (selectedTitle) {
    const selectedValues = props.element.filter(
      (item) => item.column_title === selectedTitle
    );
    console.log('Selected values:', selectedValues);
    selectedAttributeData[selectedAttribute] = selectedValues;
    selectedColumnTitle.value = selectedTitle;
    emits('update:element', selectedAttributeData);
  }

};

const columnDataFull = ref([]);
const isAttributeLinked = ref(false);
// Pasa la inforrmacion de la columna seleccionada desde CardFontAttribute
const handleSelectedTitle = (title) => {
    console.log('Título seleccionado en el componente padre:', title);
    // Buscar la información de la columna que coincida con el título seleccionado
    const columnData = csvData.value.map(object => object[title]);
    console.log('Información de la columna:', columnData);
    columnDataFull.value = columnData;
    // Guardar la información de la columna seleccionada en el ref
    selectedColumnData.value = columnData;
    // Emitir un evento con los primeros valores de la columna seleccionada
    emits('selectedColumnData', columnData.slice(0, 3));
};

const handleColumnLink = (columnData) => {
    console.log('Column data vinculated:', columnData);
    selectedColumnData.value = columnData;
    isAttributeLinked.value = true;
}

// Guardar información en array al hacer clic en "Crear Fuente"
const createFont = () => {
    if (areFieldsComplete() && areFieldsCompleteSecond()) {
         // Obtener los atributos seleccionados
        const selectedAttributes = cardAttributes.value.filter(card => card.selected).map(card => card.attribute);
        const fontData = {
            file: file.value,
            title: title.value,
            filename: filename.value,
            csvData: csvData,
            delimiter: delimiter.value,
            nameConnection: nameConnection.value,
            fontDescription: fontDescription.value,
            fontCategory: fontCategory.value,
            selectedAttributes: selectedAttributes, 
        };
        // Guardar el array de información
        console.log('Información de la fuente:', fontData);
        creatingFontVisible.value = true;
        // Abrir ventana de "Creando Fuente de Datos"
    } else {
        console.log('Todos los campos deben estar completos antes de crear la fuente.');
    }
};

</script>

<template>
    <div style="background: white;">
        <article>
            <h5 class="create-title mt-1 mb-4">Esquemas / Selecciona la plataforma / Ajustes CSV</h5>
            <section>
                <div>
                    <div class="d-flex gap-2 align-items-center m-0">
                        <p class="circle pt-1">1</p>
                        <p style="margin: 0; font-weight: 600; color: #243949 ;">Selecciona el archivo de tu fuente</p>
                    </div>
                </div>
                <!-- *Primer formulario -->
                <form @submit.prevent="submitForm">
                    <div style="padding-top: 2rem; padding-bottom: 2rem; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px; ">
                        <div class="font-section">
                            <img :src="csvIcon" alt="CSV icon" style="width: 70px; height: 70px;">
                            <!-- !Aqui sube archivo CSV -->
                            <label for="fileInput" class="file-label d-flex flex-column justify-content-center gap-2">
                                <input id="fileInput" type="file" accept=".csv" @change="handleFileChange" style="display: none"/>
                                <div class="d-flex justify-content-center align-items-center"style="height: 3rem">
                                    <span class="font-archive text-truncate">{{ filename }}</span>
                                </div>
                                <div class="d-flex flex-column align-items-center justify-content-center gap-1 w-100">
                                    <span class="file-btn">Seleccionar archivo</span>
                                    <p class="font-footnote">Solo archivos CSV. El tamaño no debe exceder los 12MB.</p>
                                </div>
                            </label>
                        </div>
                        <div class="font-section">
                            <label class="globals-label" for="title">¿Las columnas tienen título?</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="false" v-model="title">
                                <label class="form-check-label" for="inlineRadio1">No</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="true" v-model="title">
                                <label class="form-check-label" for="inlineRadio2">Si</label>
                            </div>
                        </div>
                        <div class="font-section gap-5 d-flex flex-column" style="height: auto; padding: 20px;">
                            <div class="d-flex gap-3 p-3">
                                <div class="d-flex flex-column justify-content-center" style="width: 50%;">
                                    <label class="globals-label mb-2" for="delimiter">Indica el delimitador</label>
                                    <input
                                    class="form-control globals-input"
                                    type="text"
                                    id="delimiter"
                                    v-model="delimiter"
                                    name="delimiter"
                                    placeholder="Ej. ! , -"
                                    />
                                </div>
                                <div style="width: 50%; display: flex; align-items: center;">
                                    <p class="pt-3" style="margin:0; font-size: smaller; color: #8e9fad; text-align: left;">
                                        Indícanos el carácter delimitador por el cual están divididas tus columnas. 
                                        Por lo general, coma o tabulación. El valor predeterminado es coma. 
                                    </p>
                                </div>
                            </div>
                            <button 
                            class="globals-button" 
                            type="submit" 
                            @click="submitForm"
                            :disabled="!areFieldsComplete()"
                            :class="{ 'disabled-button': !areFieldsComplete() }">
                            Cargar Fuente
                            </button>
                        </div>
                    </div>
                </form>
            </section>
            <!-- *Second Section -->
                <section id="secondSection" v-if="showSecondSection" class="mt-5">
                    <div>
                        <div class="d-flex gap-2 align-items-center m-0">
                            <p class="circle pt-1">2</p>
                            <p style="margin: 0; font-weight: 600; color: #243949 ;">Configura los datos de tu fuente</p>
                        </div>
                    </div>
                    <!-- *Segundo formulario --> 
                    <form @submit.prevent="submitSecondForm">
                        <div style="height: auto; padding-top: 2rem; padding-bottom: 2rem; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px; ">
                            <div class="font-section" style="height: auto;">
                                <div class="d-flex flex-column w-100 p-4 gap-4">
                                    <div class="d-flex flex-column justify-content-center">
                                        <label class="globals-label" for="">Nombre de la conexión<span class="required">*</span></label>
                                        <input
                                        v-model="nameConnection"
                                        class="form-control globals-input"
                                        type="text"
                                        id="connectionName"
                                        name="connectionName"
                                        placeholder="Ingresa Nombre"
                                        required
                                        />
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <label class="globals-label " for="">Descripción de la fuente<span class="required">*</span></label>
                                        <textarea
                                        v-model="fontDescription"
                                        class="form-control globals-input"
                                        type="text"
                                        id="connectionDescription"
                                        name="connectionDescription"
                                        placeholder="Ingresa una breve descripción."
                                        required
                                        />
                                    </div>
                                    <div class="d-flex flex-column justify-content-center" style="width: auto;">
                                        <label class="globals-label mb-1" for="connectionType">Categoria de la Fuente <span class="required">*</span></label>
                                        <div class="custom-select">
                                            <select v-model="fontCategory" required id="connectionType" name="connectionType" class="form-control form-select-details">
                                                <option value="" disabled selected>Selecciona una categoría</option>
                                                <option value="option1" class="dropdown-item">Categoría 1</option>
                                                <option value="option2" class="dropdown-item">Categoría 2</option>
                                                <option value="option3" class="dropdown-item" >Categoría 3</option>
                                            </select>
                                            <div class="arrow-down"></div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center w-100 ">
                                        <button 
                                        class="globals-button" 
                                        type="submit" 
                                        @click="submitSecondForm"
                                        :disabled="!areFieldsCompleteSecond()"
                                        :class="{ 'disabled-button': !areFieldsCompleteSecond() }">
                                        Siguiente
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                <!-- *Third Section -->
                <section id="thirdSection" v-if="showThirdSection" class="mt-5">
                    <div>
                        <div class="d-flex gap-2 align-items-center m-0">
                            <p class="circle pt-1">3</p>
                            <p style="margin: 0; font-weight: 600; color: #243949 ;">Atributos de la fuente</p>
                        </div>
                    </div>
                    <!-- *Tercer Formulario -->
                    <form @submit.prevent="submitThirdForm">
                        <div style="height: auto; padding-top: 2rem; padding-bottom: 2rem; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px; ">
                            <div class="font-section flex-column" style="height: auto; width: 80%;">
                                <div class="d-flex align-items-center gap-3 my-3">
                                    <p style="width: 70%;color: rgb(125, 143, 152); font-size: larger; font-weight: 600; margin-bottom: 0;">Vincula las columnas de tu CSV con tus atributos</p>
                                    <div class="w-auto">
                                        <div class="dropdown z-2 form-control globals-input">
                                            <label class="globals-label text-center" style="font-size: medium;" for="connectionType">Estatus</label>
                                            <div class="dropdown-toggle d-flex gap-3 align-items-center" style="font-size: small; gap: 10px; color: #99bbd6;"
                                            @click="toggleDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                            {{ selectedKey ? selectedKey : 'Todos' }}
                                            </div>
                                            <ul class="dropdown-menu  mt-3 px-4" :class="{ show: isDropdownOpen }">
                                                <li><a class="dropdown-item" @click="selectKey('Tipo 1')">Tipo 1</a></li>
                                                <li><a class="dropdown-item" @click="selectKey('Tipo 2')">Tipo 2</a></li>
                                                <li><a class="dropdown-item" @click="selectKey('Tipo 3')">Tipo 3</a></li>
                                                <li><a class="dropdown-item" @click="selectKey('Tipo 4')">Tipo 4</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div style="margin-top: 20px;">
                                        <input
                                        class="form-control globals-input input-with-icon"
                                        style="height: 3.5rem;"
                                        type="text"
                                        id="searchAttribute"
                                        name="searchAttribute"
                                        placeholder="Buscar Atributos"
                                        v-model="searchTerm"
                                        />
                                        <div class="position-relative search-icon">
                                            <MagnifyingGlassIcon class="text-blue-500 d-flex justify-content-center" style="width: 20px; height: 20px;"></MagnifyingGlassIcon>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-grid-container">
                                    <CardFontAttribute class="card-grid-item"
                                    v-for ="(card, index) in filteredCardAttributes"
                                    :key="index"
                                    :attribute="card.attribute"
                                    :selected="card.selected"
                                    :element="selectedAttributeData"
                                    :columnTitles="columnTitles"
                                    @select="handleSelectAttribute"
                                    @selectedTitle="handleSelectedTitle"
                                    @update:element="(newElement) => selectedAttributeData = newElement"
                                    :selectedColumnData="selectedColumnData"
                                    :onColumnLink="handleColumnLink"
                                    :isAttributeLinked="isAttributeLinked"
                                    >
                                    </CardFontAttribute>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                <!-- TODO:Enviar/descartar formulario  -->
            <div class="d-flex gap-5 py-4 justify-content-center">
                <button class="globals-border-button" style="font-weight: 700;" type="button" @click="resetAndCloseModal()">
                    DESCARTAR
                </button>
                <button
                class="globals-button"
                style="font-weight: 700;"
                type="submit" 
                @click="createFont"
                :disabled="!areFieldsComplete() || !areFieldsCompleteSecond()"
                :class="{ 'disabled-button': !areFieldsComplete() || !areFieldsCompleteSecond() }">
                    CREAR FUENTE
                </button>
            </div>
        </article>
        <div class="modal-background" v-if="creatingFontVisible" @click.self="creatingFontVisible = false"></div>
            <div class="modal-content" v-if="creatingFontVisible">
                <CreatingFont/>
            </div>
<!--         <div v-if="creatingFontVisible" class="d-flex justify-content-center">
            <CreatingFont></CreatingFont>
        </div> -->
    </div>
</template>
<style scoped>
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    display: flex;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    margin-left: 3.5rem;
    z-index: 100;
    background: white;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.card-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.card-grid-item {
  border: 1px solid #d5d4d4;
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
}
.input-with-icon {
  padding-right: 40px;
}

.search-icon {
  position:absolute;
  top: -40px;
  transform: translateX(80%);
  pointer-events: none;
}
.required {
    color: red;
    margin-left: 5px;
}
.circle {
width: 25px;
height: 25px;
border-radius: 50%;
background-color: #243949;
color: #fff;
display: flex;
align-items: center;
justify-content: center;
font-size: 15px;
font-weight: bolder;
margin: 0;
}

.custom-select {
    position: relative;
}

.custom-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    cursor: pointer;
}

.arrow-down {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000;
}
</style>