import axios from "axios";

import { dataEnv } from "../../config/index.js";
const API_BASE_URL = dataEnv().API_URL;

/* https://api-golden.hexagondata.com/api/v1/auth/signup */

/**
 * Function to know if the user is authenticated
 * @return Boolean
 */
export const isAuthenticated = () => {
  const token = localStorage.getItem("authToken");
  const resp = token ? true : false;
  return resp;
};

/**
 * Function to signup
 * @param {Object} payload
 * @return AxiosPromise
 */
export const signupAccount = (payload) => {
  return axios.post(`${API_BASE_URL}/auth/signup`, payload);
};

/**
 * Function to login
 * @param {Object} payload
 * @return AxiosPromise
 * https://api-golden.hexagondata.com/api/v1/auth/login
 */
export const getLogin = (payload) => {
  return axios.post(`${API_BASE_URL}/auth/login`, payload);
};

/**
 * Function to get User
 * @param {Object} payload
 * @return AxiosPromise
 * https://api-golden.hexagondata.com/api/v1/auth/me
 */
/* export const getUser= (payload) => {
  return axios.get(`${API_BASE_URL}/auth/me`,payload)
} */
export const getUser = async (authToken) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
