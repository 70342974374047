<script setup>
import { ref, reactive, onMounted } from "vue";
import { createRule } from "@/services/rules";
import { getProjects } from "@/services/projects";
import {
  getProjectChannels,
  /* postTimesToContactChannels */
} from "@/services/channels";
import CreatedRule from "@/components/Rules/CreatedRule";

const days = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

/* const hours = Array.from({ length: 24 }, (_, index) => {
  const hour = index.toString().padStart(2, "0");
  return `${hour}:00`;
}).filter((hour) => {
  const hourValue = parseInt(hour.split(":")[0]);
  return hourValue >= 8 && hourValue <= 20;
}); */

const projects = ref([]);
const projectsLoaded = ref(false);
const projectHasNoChannels = ref(false);

onMounted(async () => {
  const clientId = localStorage.getItem("cx");
  console.log("Client ID:", clientId);
  if (!clientId) {
    console.error("Client ID not found in localStorage");
    return;
  }
  try {
    const response = await getProjects(clientId);
    console.log("Fetched Projects:", response.data);
    if (response.data.length) {
      projects.value = response.data;
      projectsLoaded.value = true;
    }
    console.log("PROYECTS:", projects.value);
  } catch (error) {
    console.error("Error fetching rules:", error);
  }
});

const showDates = ref(false);
const showTimes = ref(false);
const ruleCreated = ref(false);
const missingFieldsError = ref(false);
const formSubmitted = ref(false);

const ruleData = reactive({
  name: "",
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
  startTime: "",
  endTime: "",
  country: "",
  emailTimes: "",
  smsTimes: "",
  callCenterTimes: "",
  showDates: showDates.value,
  notExpire: !showDates.value,
  expire: showDates.value,
  validityStartDate: "",
  validityEndDate: "",
  contactType: "month",
  isMonth: true,
  isWeek: false,
  timesContactDaily: "",
  clientsId: localStorage.getItem("cx"), //?
  keytypesId: 1, //?
  catperiodId: 2, //?
  projectsId: "",
  channels: [],
});

// Validación
function validateForm() {
  formSubmitted.value = true;
  const requiredFields = ["name", "projectsId"];

  const isValid = requiredFields.every((field) => ruleData[field]);

  const isDaySelected =
    ruleData.monday ||
    ruleData.tuesday ||
    ruleData.wednesday ||
    ruleData.thursday ||
    ruleData.friday ||
    ruleData.saturday ||
    ruleData.sunday;

  if (!isValid || !isDaySelected) {
    missingFieldsError.value = true;
    console.log("Faltan campos obligatorios o no se seleccionó un día");
    return false;
  } else {
    missingFieldsError.value = false;
    return true;
  }
}

// Watch for changes in the selected project
/* watch(
  () => ruleData.projects_id,
  (newVal) => {
    const selectedProject = projects.value.find(
      (project) => project.id === newVal
    );
    console.log("Selected Project:", selectedProject);
  }
); */
const findChannels = async (elem) => {
  console.log(elem.target.value);
  const response = await getProjectChannels(ruleData.projectsId);
  console.log("Fetched Channels:", response.data);
  ruleData.channels = response.data;
  // Revisar si el proyecto tiene canales
  if (response.data.length === 0) {
    projectHasNoChannels.value = true;
  } else {
    projectHasNoChannels.value = false;
  }
};

async function handleSubmit() {
  formSubmitted.value = true;

  if (!validateForm()) {
    return;
  }
  console.log("Form submitted!");
  console.log("Rule Data:", ruleData);
  if (ruleData.contactType === "month") {
    ruleData.isMonth = true;
    ruleData.isWeek = false;
  } else {
    ruleData.isMonth = false;
    ruleData.isWeek = true;
  }

  const payload = {
    startDate: showDates.value ? ruleData.validityStartDate : null,
    endDate: showDates.value ? ruleData.validityEndDate : null,
    monday: ruleData.monday,
    tuesday: ruleData.tuesday,
    wednesday: ruleData.wednesday,
    thursday: ruleData.thursday,
    friday: ruleData.friday,
    saturday: ruleData.saturday,
    sunday: ruleData.sunday,
    notExpire: !ruleData.showDates,
    expire: ruleData.showDates,
    isMonth: ruleData.isMonth,
    isWeek: ruleData.isWeek,
    timesContactDaily: ruleData.timesContactDaily,
    endSchedule: ruleData.endTime,
    startSchedule: ruleData.startTime,
    clientsId: ruleData.clientsId,
    name: ruleData.name,
    country: ruleData.country,
    projectsId: ruleData.projectsId,
    channels: ruleData.channels,
    //keytypesId: ruleData.keytypesId, //?
    //catperiodId: ruleData.catperiodId, //?
  };
  console.log("Payload:", payload);
  try {
    const response = await createRule(payload);
    console.log("Regla creada exitosamente:", response.data);
    ruleCreated.value = true;

    const rulesId = response.data.id;
    console.log("rulesId:", rulesId);
  } catch (error) {
    console.error(
      "Error al crear la regla o enviar información de veces a contactar:",
      error
    );
  }
}

// checkbox changes
const toggleVigencia = (value) => {
  if (value) {
    showDates.value = true;
  } else {
    showDates.value = false;
  }
};

const toggleModalidad = (value) => {
  if (value) {
    showTimes.value = true;
  } else {
    showTimes.value = false;
  }
};

const errorMessages = ref({});

function validateAndUpdate(event, property) {
  const inputValue = parseInt(event.target.value, 10);
  if (inputValue > 31) {
    ruleData[property] = 31;
    errorMessages.value[property] = `No es posible contactar más de 31 veces`;
  } else {
    ruleData[property] = inputValue;
    errorMessages.value[property] = "";
  }
}
function validateAndUpdateWeek(event, property) {
  const inputValue = parseInt(event.target.value, 10);
  if (inputValue > 7) {
    ruleData[property] = 7;
    errorMessages.value[property] = `No es posible contactar más de 7 veces`;
  } else {
    ruleData[property] = inputValue;
    errorMessages.value[property] = "";
  }
}
</script>
<template>
  <div>
    <article>
      <form @submit.prevent="handleSubmit">
        <div class="mb-3">
          <label class="form-label globals-label" style="font-size: 16px"
            >Nombre</label
          >
          <input
            type="text"
            class="form-control globals-input"
            placeholder="Nombre"
            v-model="ruleData.name"
          />
        </div>
        <section class="my-4">
          <p class="form-check-label globals-label">Selecciona la frecuencia</p>
          <div class="grid-container">
            <div
              class="mb-3 form-check"
              v-for="(day, index) in days"
              :key="index"
            >
              <input
                type="checkbox"
                class="form-check-input"
                :id="'checkbox' + index"
                v-model="
                  ruleData[
                    day
                      .toLowerCase()
                      .replace('lunes', 'monday')
                      .replace('martes', 'tuesday')
                      .replace('miércoles', 'wednesday')
                      .replace('jueves', 'thursday')
                      .replace('viernes', 'friday')
                      .replace('sábado', 'saturday')
                      .replace('domingo', 'sunday')
                  ]
                "
              />
              <label class="form-check-label" :for="'checkbox' + index">{{
                day
              }}</label>
            </div>
          </div>
        </section>

        <!--div class="my-4">
          <label
            for="exampleInputEmail2"
            class="form-label globals-label"
            style="font-size: 16px"
            >Horario</label
          >
          <div class="d-flex gap-4 align-items-center">
            <p class="form-label globals-label pt-2">De</p>
            <div>
              <select
                id="start-time"
                class="form-select form-select-details"
                v-model="ruleData.startTime"
              >
                <option v-for="hour in hours" :key="hour" :value="hour">
                  {{ hour }}
                </option>
              </select>
            </div>
            <p class="form-label globals-label pt-2">a</p>
            <div>
              <select
                id="end-time"
                class="form-select form-select-details"
                v-model="ruleData.endTime"
              >
                <option v-for="hour in hours" :key="hour" :value="hour">
                  {{ hour }}
                </option>
              </select>
            </div>
          </div>
        </div-->

        <div class="py-3">
          <label
            for="exampleInputEmail2"
            class="form-label globals-label"
            style="font-size: 16px"
            >Selecciona la vigencia
          </label>
          <div class="d-flex justify-content-start gap-5">
            <div class="mb-3 form-check">
              <input
                type="radio"
                class="form-check-input"
                :checked="!showDates"
                @change="toggleVigencia(false)"
              />
              <label class="form-check-label">Sin Vigencia</label>
            </div>
            <div class="mb-3 form-check">
              <input
                type="radio"
                class="form-check-input"
                v-model="showDates"
                :checked="showDates"
                @change="toggleVigencia(true)"
              />
              <label class="form-check-label">Vigencia</label>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center gap-4 my-4"
            v-if="showDates"
          >
            <p class="form-label globals-label" style="font-weight: 600">
              Vigencia de
            </p>
            <div class="mb-3">
              <input
                type="date"
                class="form-control globals-input"
                id="exampleInputEmail3"
                placeholder="Fecha Inicial"
                v-model="ruleData.validityStartDate"
              />
            </div>
            <p class="form-label globals-label" style="font-weight: 600">a</p>
            <div class="mb-3">
              <input
                type="date"
                class="form-control globals-input"
                id="exampleInputEmail3"
                placeholder="Fecha Final"
                v-model="ruleData.validityEndDate"
              />
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail3" class="form-label globals-label"
            >Elige tu proyecto:</label
          >
          <select
            id="project"
            class="form-select form-select-details"
            v-model="ruleData.projectsId"
            @change="findChannels($event)"
          >
            <option value="">Selecciona un proyecto</option>
            <option
              v-for="project in projects"
              :key="project.projectsId"
              :value="project.projectsId"
            >
              {{ project.name }}
            </option>
          </select>
          <div class="mt-2" v-if="projectHasNoChannels">
            <p class="text-error">
              Este proyecto no pueder seleccionado, ya que
              <span style="font-weight: 600">no</span> contiene canales.
              Selecciona otro proyecto.
            </p>
          </div>
        </div>

        <section class="row gap-4 my-4">
          <div class="py-3">
            <label
              for="exampleInputEmail2"
              class="form-label globals-label"
              style="font-size: 16px"
              >Modalidad de contacto</label
            >
            <div class="d-flex justify-content-start gap-5">
              <div class="mb-3 form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  :checked="ruleData.isMonth"
                  @change="toggleModalidad(false)"
                  v-model="ruleData.contactType"
                  value="month"
                />
                <label class="form-check-label">Mensual</label>
              </div>
              <div class="mb-3 form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  :checked="ruleData.isWeek"
                  @change="toggleModalidad(true)"
                  v-model="ruleData.contactType"
                  value="week"
                />
                <label class="form-check-label">Semanal</label>
              </div>
            </div>

            <div
              class="d-flex align-items-center justify-content-center gap-4 my-4"
              v-if="!showTimes"
            >
              <div class="col">
                <p class="form-label globals-label">
                  Veces a contactar por MES
                </p>
                <div>
                  <!-- !CONTACTO MENSUAL -->
                  <div
                    v-for="(channel, index) in ruleData.channels"
                    :key="index"
                  >
                    <div class="mb-3">
                      <label
                        :for="`channel-${index}`"
                        class="form-label globals-label pt-2 text-nowrap"
                        style="width: 50%; font-size: 16px"
                      >
                        {{ channel.name }}
                      </label>
                      <input
                        type="number"
                        :id="`channel-${index}`"
                        class="form-control form-select-details"
                        style="width: 50%"
                        min="1"
                        max="31"
                        :value="ruleData[channel.name.toLowerCase() + 'Time']"
                        @input="
                          validateAndUpdate(
                            $event,
                            channel.name.toLowerCase() + 'Time'
                          )
                        "
                        v-model.lazy="ruleData.channels[0].timesContact"
                      />
                      <span
                        v-if="
                          errorMessages[channel.name.toLowerCase() + 'Time']
                        "
                        class="text-error"
                      >
                        {{ errorMessages[channel.name.toLowerCase() + "Time"] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- !CONTACTO SEMANAL -->
            <div
              class="d-flex align-items-center justify-content-center gap-4 my-4"
              v-if="showTimes"
            >
              <div class="col">
                <p class="form-label globals-label">
                  Veces a contactar por SEMANA
                </p>
                <div>
                  <div
                    v-for="(channel, index) in ruleData.channels"
                    :key="index"
                  >
                    <div class="mb-3">
                      <label
                        :for="`channel-${index}`"
                        class="form-label globals-label pt-2 text-nowrap"
                        style="width: 50%; font-size: 16px"
                      >
                        {{ channel.name }}
                      </label>
                      <input
                        type="number"
                        :id="`channel-${index}`"
                        class="form-control form-select-details"
                        style="width: 50%"
                        min="1"
                        max="7"
                        :value="channel.timesContact"
                        @input="
                          validateAndUpdateWeek(
                            $event,
                            channel.name.toLowerCase() + 'Time'
                          )
                        "
                        v-model.lazy="channel.timesContact"
                      />
                      <span
                        v-if="
                          errorMessages[channel.name.toLowerCase() + 'Time']
                        "
                        class="text-error"
                      >
                        {{ errorMessages[channel.name.toLowerCase() + "Time"] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          v-if="formSubmitted && missingFieldsError"
          class="text-danger text-center my-3"
        >
          Todos los campos son obligatorios. Por favor, completa antes de
          generar la regla.
        </div>
      </form>
      <div class="d-flex justify-content-center my-5">
        <button class="globals-button" @click="handleSubmit">
          Crear Regla
        </button>
      </div>
    </article>
    <div v-if="ruleCreated" class="modal-background">
      <div class="modal-content">
        <CreatedRule></CreatedRule>
      </div>
    </div>
  </div>
</template>
<style scoped>
.text-error {
  color: red;
  font-size: 0.8em;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  gap: 10px;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin-left: 3.5rem;
  z-index: 100;
  background: white;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
</style>
