  
<script setup>
import { ref, onMounted, defineProps, watch  } from 'vue';
import { KeyIcon, TrashIcon, PencilSquareIcon } from '@heroicons/vue/24/solid';
import { getAttributes, deleteAttribute } from '@/services/data';

const isDropdownOpen = ref(false);
/* const { data } = defineProps(); */
const props = defineProps(['newAttributeData', 'newKeyData']);
  
const attributes = ref([]);
const showDeleteAlert = ref(false);
const deletedAttributeName = ref('');


//obtener atributos
onMounted(async () => {
  const client_id = '1'; //provisional
  const response = await getAttributes(client_id);
  console.log('Fetched attributes:', response);
  if (response.data.length) {
    attributes.value = response.data;
  }
});


/* Click fuera del dropdown */
onMounted(() => { 
  document.addEventListener('click', (event) => {
    if (!event.target.closest('.dropdown')) {
      isDropdownOpen.value = false;
    }
  });
});

const deleteAttributeFunction = async (id, name) => {
  try {
    await deleteAttribute(id);
    attributes.value = attributes.value.filter((attribute) => attribute.id !== id);
    deletedAttributeName.value = name;
    showDeleteAlert.value = true;
    setTimeout(() => {
      showDeleteAlert.value = false;
    }, 3000); // Ocultar la alerta después de 3 segundos
  } catch (error) {
    console.error('Error al eliminar proyecto:', error);
  }
};


const toggleDropdown = () => {
  isDropdownOpen.value =!isDropdownOpen.value;
};

/* const updateKey = (newKeyData) => {
  keys.value.push(newKeyData);
}; */

</script>
<template>
    <div class="rounded-lg">
        <table class="table" style="max-height: 200px; overflow: auto;">
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>
                <div class="dropdown z-2">
                    <div class="dropdown-toggle d-flex gap-3 align-items-center"
                    @click="toggleDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        Tipo de dato
                    </div>
                    <ul class="dropdown-menu  mt-3 px-4 z-2" :class="{ show: isDropdownOpen }">
                        <li><a class="dropdown-item" href="#">Llave</a></li>
                        <li><a class="dropdown-item" href="#">Atributo</a></li>
                    </ul>
                </div>
              </th>
              <th>Tipo de llave</th>
              <th>Descripción</th>
              <th class="actions-column"></th>
            </tr>
          </thead>
         <!--  <tbody>
            <tr v-for="item in keys" :key="item.id"> 
              <td>
                <div class="d-flex justify-content-center">
                    <KeyIcon class="text-blue-500 d-inline-flex justify-content-center" style="color: #517fa4; width: 20px; height: 20px; margin-right: 5px;"></KeyIcon>                   
                </div>
              </td>
              <td>{{ item.nombre }}</td>
              <td>{{ 'Llave' }}</td>
              <td>{{ item.tipoLlave }}</td>
              <td>{{ item.descripcion }}</td>
              <td class="d-flex gap-3 actions-column mx-2" style="margin-top: 3px; border-bottom: none;">
                <button class="buttons-group"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasEditRuleForm" aria-controls="offcanvasEditRuleForm">
                  <PencilSquareIcon class="text-blue-500 d-flex justify-content-center" style="color: #517fa4; width: 20px; height: 20px;"></PencilSquareIcon>
                </button>
                <button class="buttons-group delete"  >
                    <TrashIcon class="text-blue-500 d-flex justify-content-center" style="width: 20px; height: 20px;"></TrashIcon>
                </button>
              </td>
            </tr>
          </tbody> -->
          <!-- Attributes -->
          <tbody>
            <tr v-for="attribute in attributes" :key="attribute.id"> 
              <td>
                <div class="d-flex justify-content-center">
                   <template v-if="attribute.keytypes_id !== 4">
                      <KeyIcon class="text-blue-500 d-inline-flex justify-content-center" style="color: #517fa4; width: 20px; height: 20px; margin-right: 5px;"></KeyIcon>
                    </template>
                </div>
              </td>
              <td style="text-transform: capitalize; font-weight: 600;">{{ attribute.name }}</td>
              <td v-text="attribute.keytypes_id === 4 ? 'Atributo' : 'Llave'"></td>
              <td>
                <span :class="{ 'no-apply': attribute.keytypes_id === 4 }" v-text="attribute.keytypes_id  === 1 ? 'Email' : (attribute.keytypes_id  === 2 ? 'Phone' : (attribute.keytypes_id === 3 ? 'Custom Key' : 'No Aplica'))"></span>
              </td>
              <td>{{ attribute.description }}</td>
              <td class="d-flex gap-3 actions-column mx-2" style="margin-top: 3px; border-bottom: none;">
                <button class="buttons-group"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasEditRuleForm" aria-controls="offcanvasEditRuleForm">
                  <PencilSquareIcon class="text-blue-500 d-flex justify-content-center" style="color: #517fa4; width: 20px; height: 20px;"></PencilSquareIcon>
                </button>
                <button class="buttons-group delete" @click="deleteAttributeFunction(attribute.id)" >
                    <TrashIcon class="text-blue-500 d-flex justify-content-center" style="width: 20px; height: 20px;"></TrashIcon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="showDeleteAlert" class="alert alert-dark alert-dismissible fade show" role="alert">
              ¡El elemento {{ deletedAttributeName }} ha sido eliminado exitosamente!
              <button type="button" class="btn-close" @click="showDeleteAlert = false" aria-label="Close"></button>
            </div>
    </div>
  </template>  
<style scoped>
.actions-column {
  position: sticky;
  right: 0;
  margin-left: 4px;
  margin-right: 4px;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer; 
}
.delete:hover {
    background-color: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
  }
.no-apply {
    color: rgb(179, 179, 179);
}
.rounded-lg {
    border-radius: 10px; 
    overflow: hidden;
}
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #ddd;
    color: #243949;
  }
  
</style>