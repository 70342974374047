import axios from "axios";

import { dataEnv } from "../../config/index.js";
const API_BASE_URL = dataEnv().API_URL;

/**
 * Function to get message type per project
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getReportsbyChannels = () => {
  return axios.get(`${API_BASE_URL}/report/channel/rules/projects`);
};
