<script setup>
import { editClient } from "@/services/clients";
import CreatedClient from "./CreatedClient.vue";
import { ref, defineEmits, defineProps } from "vue";

const props = defineProps(["newKeyData"]);
const emit = defineEmits(["keySaved", "cancelEdit"]);

const isDropdownOpen = ref(false);
const emailError = ref("");
const formError = ref("");

const clientData = ref({
  clientsId:
    props.newKeyData && props.newKeyData.clientsId
      ? props.newKeyData.clientsId
      : "",
  companyName:
    props.newKeyData && props.newKeyData.companyName
      ? props.newKeyData.companyName
      : "",
  contactName: {
    name:
      props.newKeyData && props.newKeyData.contactName
        ? props.newKeyData.contactName.split(" ")[0]
        : "",
    lastName:
      props.newKeyData && props.newKeyData.contactName
        ? props.newKeyData.contactName.split(" ")[1]
        : "",
  },
  contactPhone:
    props.newKeyData && props.newKeyData.phone ? props.newKeyData.phone : "",
  contactEmail:
    props.newKeyData && props.newKeyData.email ? props.newKeyData.email : "",
});

function saveUserAndEmit(clientsId) {
  if (isFormComplete()) {
    if (validateEmail()) {
      const updatedClientData = {
        clientId: clientData.value.clientsId,
        companyName: clientData.value.companyName,
        contactName: `${clientData.value.contactName.name} ${clientData.value.contactName.lastName}`,
        contactPhone: clientData.value.contactPhone,
        contactEmail: clientData.value.contactEmail,
      };
      console.log("Datos a enviar al servidor:", updatedClientData);

      editClient(clientsId, updatedClientData)
        .then((response) => {
          console.log("Cliente actualizado con éxito:", updatedClientData);
          if (typeof response.data === "object") {
            emit("keySaved", response.data);
          } else if (typeof response.data === "string") {
            console.log("Cliente actualizado correctamente");
            emit("keySaved", { message: response.data });
          }

          isDropdownOpen.value = false;
        })
        .catch((error) => {
          console.error("Error al actualizar cliente:", error);
        });
    } else {
      console.error("El correo electrónico no es válido.");
    }
  } else {
    formError.value = "Por favor, completa todos los campos para continuar.";
  }
}

function validateEmail() {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = emailRegex.test(clientData.value.contactEmail);
  if (!isValid) {
    emailError.value = "Por favor, ingresa un correo electrónico válido.";
  } else {
    emailError.value = "";
  }
  console.log("EMAILERROR STATUS:", emailError.value);
  return isValid;
}

function isFormComplete() {
  return (
    clientData.value.companyName &&
    clientData.value.contactName.name &&
    clientData.value.contactName.lastName &&
    clientData.value.contactPhone &&
    clientData.value.contactEmail
  );
}

function resetEmailError() {
  emailError.value = "";
  formError.value = "";
}

const cancelEdit = () => {
  emit("cancelEdit");
};
</script>
<template>
  <div>
    <article>
      <h5 class="create-title my-4">Editar Cliente</h5>
      <form @submit.prevent="handleSubmitClient">
        <div class="mb-3">
          <label
            for="exampleInputEmail1"
            class="form-label globals-label"
            style="font-size: 16px"
            >Nombre de la Empresa</label
          >
          <input
            type="email"
            class="form-control globals-input text-capitalize"
            id="exampleInputEmail1"
            placeholder="Nombre de la Empresa"
            v-model="clientData.companyName"
          />
        </div>
        <div>
          <label
            for="exampleInputEmail2"
            class="form-label globals-label"
            style="font-size: 16px"
            >Nombre de Contacto</label
          >
          <div class="mb-3 d-flex gap-2">
            <input
              type="email"
              class="form-control globals-input text-capitalize"
              id="exampleInputEmail2"
              placeholder="Nombre(s)"
              v-model="clientData.contactName.name"
            />
            <input
              type="email"
              class="form-control globals-input text-capitalize"
              id="exampleInputEmail2"
              placeholder="Apellido(s)"
              v-model="clientData.contactName.lastName"
            />
          </div>
        </div>
        <div class="mb-3">
          <label
            for="exampleInputEmail1"
            class="form-label globals-label"
            style="font-size: 16px"
            >Teléfono de Contacto</label
          >
          <input
            type="email"
            class="form-control globals-input"
            id="exampleInputEmail1"
            placeholder="Teléfono"
            v-model="clientData.contactPhone"
          />
        </div>
        <div class="mb-3">
          <label
            for="exampleInputEmail3"
            class="form-label globals-label"
            style="font-size: 16px"
            >Email</label
          >
          <input
            type="email"
            class="form-control globals-input"
            id="exampleInputEmail3"
            placeholder="Email"
            v-model="clientData.contactEmail"
            @input="resetEmailError"
          />
          <span v-if="emailError" class="text-danger" style="font-size: 14px">{{
            emailError
          }}</span>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 3rem;
          "
        >
          <span v-if="formError" class="text-danger" style="font-size: 14px">{{
            formError
          }}</span>
        </div>
      </form>
      <div class="d-flex justify-content-around my-5">
        <button
          class="globals-border-button"
          @click="cancelEdit"
          data-bs-dismiss="offcanvas"
        >
          Cancelar
        </button>
        <button
          class="globals-button"
          @click="saveUserAndEmit(clientData.clientsId)"
        >
          Guardar Cambios
        </button>
      </div>
    </article>
    <div v-if="clientCreated" class="modal-background">
      <div class="modal-content">
        <CreatedClient></CreatedClient>
      </div>
    </div>
  </div>
</template>
