<script setup>
import SelectApi from './SelectApi.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router'

const route = useRoute()

/* const showCreateProyect = computed(() => {
  return route.name === 'DashboardProyectCreate';
}); */

const showSelectApi = computed(() => {
  return route.name === 'DashboardApiSection';
});
const showApiSection = false
console.log("DASHBOARD API SECTION");
</script>
<template>
    <div class="d-flex justify-content-end" style="">
        <div class="px-4" style="width: 75%;">
            <h2 class="dashboard-title" v-show="!showApiSection">Sección API</h2>   
            <SelectApi v-if="showSelectApi" :route="route"></SelectApi>         
        </div>
    </div>
</template>
<style scoped>
</style>