<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import CreateRule from "./CreateContract.vue";
import ContractTable from "@/components/Contracts/ContractTable.vue";

const route = useRoute();

const showCreateContract = computed(() => {
  return route.name === "DashboardContractCreate";
});

const showTableContract = computed(() => {
  return !showCreateContract.value && route.name === "DashboardContracts";
});

console.log("DASHBOARD Contract");
</script>
<template>
  <div class="d-flex justify-content-end" style="">
    <div class="px-4" style="width: 75%">
      <h2 class="dashboard-title">Contratos</h2>
      <CreateRule v-if="showCreateContract" :route="route"></CreateRule>
      <div v-if="showTableContract" :route="route">
        <h5 class="create-title my-4">Contratos Vigentes</h5>
        <ContractTable />
      </div>
    </div>
  </div>
</template>
<style scoped></style>
