<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/24/solid";

const props = defineProps({
  attribute: String,
  selectedColumnData: Array,
  columnPreviews: {
    type: Array,
    default: () => [],
  },
  selectedColumn: String,
});

console.log("columnPreviews en CardFontList:", props.columnPreviews);

// Definir emits correctamente
const emitEvent = defineEmits(["selectColumn"]);

// Computed para verificar si esta columna está seleccionada
const isSelected = computed(() => props.selectedColumn === props.attribute);

const selectColumn = () => {
  if (!isSelected.value) {
    emitEvent("selectColumn", props.attribute);
  }
};
</script>
<template>
  <div class="card gap-4">
    <header class="d-flex justify-content-start gap-2 align-items-center">
      <h4 style="font-size: large; font-weight: 600; color: #517fa4">
        {{ attribute }}
      </h4>
    </header>
    <article style="min-height: 4rem">
      <div class="column-text p-2">
        <ul class="list-group list-group-flush column-text">
          <li
            v-for="(item, index) in columnPreviews"
            :key="index"
            class="list-group-item"
            style="color: rgb(183, 185, 192)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </article>
    <footer class="d-flex justify-content-center">
      <button
        class="globals-button"
        :class="{ 'border-button': isSelected }"
        @click="selectColumn"
      >
        <span v-if="isSelected">
          <CheckCircleIcon
            style="color: #77a25e; width: 38px; height: 38px"
          ></CheckCircleIcon>
          <p style="margin-bottom: 0; margin-top: 8px; font-weight: 600">
            Columna Seleccionada
          </p>
        </span>
        <span v-else>Seleccionar Columna</span>
      </button>
    </footer>
  </div>
</template>
<style scoped>
.border-button {
  color: #243949;
  border-color: #517fa4;
  border-radius: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 8px;
  width: 200px;
  background: white;
  transition: transform 0.2s ease-in-out;
  text-wrap: nowrap;
  &:hover {
    color: #182631;
    transform: scale(0.9);
  }
}

.slow-fade-enter-active,
.slow-fade-leave-active {
  transition: opacity 1s ease;
}
.slow-fade-enter, .slow-fade-leave-to /* .slow-fade-leave-active in <2.1.8 */ {
  opacity: -1;
}
.column-text {
  border-radius: 10px;
  font-size: smaller;
  font-weight: 400;
}

.column-text::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.column-text::-webkit-scrollbar-track {
  background-color: transparent;
}

.column-text::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.column-text::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
