import axios from "axios";
import { dataEnv } from "../../config/index.js";
const API_BASE_URL = dataEnv().API_URL;

/**
 * Function to get plans
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getPlans = () => {
  return axios.get(`${API_BASE_URL}/plans`);
};

/**
 * Function to create contract
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createContract = (payload) => {
  return axios.post(`${API_BASE_URL}/contracts`, payload);
};

/**
 * Function to get contracts
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getContracts = () => {
  return axios.get(`${API_BASE_URL}/contracts`);
};

/**
 * Function to update contracts
 * @param {Object} payload
 * @return AxiosPromise
 */
export const updateContract = (payload) => {
  return axios.patch(`${API_BASE_URL}/contracts`, payload);
};
