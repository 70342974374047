<script setup>
import { ref, onMounted, watch, onUnmounted, defineProps } from "vue";
import {
  PencilSquareIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/vue/24/solid";
import { getChannels, deleteChannel } from "@/services/channels";

import CreateChannelForm from "./CreateChannelForm.vue";

const isDropdownOpen = ref(false);
const showEditChannelForm = ref(false);
const offcanvasEditChannelFormRef = ref(null);
const { data } = defineProps();

const selectedChannel = ref(null);
const channels = ref([]);
const showSuccessMessage = ref(false);
const isChannelEdited = ref(false);
const showDeleteAlert = ref(false);
const deletedChannelName = ref("");

onMounted(async () => {
  const clientId = localStorage.getItem("cx");
  const response = await getChannels(clientId);
  console.log("Fetched channels:", response);
  if (response.data.length) {
    channels.value = response.data;
  }
});

const deleteChannelFunction = async (id, name) => {
  try {
    await deleteChannel(id);
    channels.value = channels.value.filter((channel) => channel.id !== id);
    deletedChannelName.value = name;
    showDeleteAlert.value = true;
    setTimeout(() => {
      showDeleteAlert.value = false;
    }, 3000); // Ocultar la alerta después de 3 segundos
  } catch (error) {
    console.error("Error al eliminar canal:", error);
  }
};

function toggleEditChannelForm(channel) {
  selectedChannel.value = channel;
  console.log("Selected Channel:", selectedChannel.value);
  showEditChannelForm.value = true;
  showSuccessMessage.value = false;
}
/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

const handleKeySaved = (newData) => {
  console.log("Datos del canal actualizada:", data);
  /* newData.value = newData;
  showEditChannelForm.value = false; */
  // Actualizar la regla en la lista
  const index = channels.value.findIndex(
    (channel) => channel.id === newData.id
  );
  if (index !== -1) {
    channels.value[index] = newData;
  } else {
    channels.value.push(newData);
  }
  showEditChannelForm.value = false;
  isChannelEdited.value = true;
  showSuccessMessage.value = true;
};

const closeOffcanvas = () => {
  showEditChannelForm.value = false;
  if (!isChannelEdited.value) {
    showSuccessMessage.value = false;
  }
};

const handleEscape = (event) => {
  if (event.key === "Escape") {
    closeOffcanvas();
  }
};
onMounted(() => {
  window.addEventListener("keydown", handleEscape);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscape);
});

// Watcher para detectar cambios en showEditChannelForm
watch(showEditChannelForm, (newValue) => {
  if (!newValue) {
    // Si showEditRuleForm pasa a false (el formulario se cerró),
    // mostrar el mensaje y opciones
    setTimeout(() => {
      const offcanvasElement = document.querySelector(
        "#offcanvasEditChannelForm"
      );
      offcanvasElement.classList.add("show"); // Mostrar el offcanvas
    }, 100);
  }
});
</script>
<template>
  <div>
    <div class="rounded-lg table-container" style="max-width: 60rem">
      <table class="table">
        <thead>
          <tr>
            <th>Nombre del Canal</th>
            <th>Descripción</th>
            <th>Proyecto Asignado</th>
            <th class="actions-column"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="channel in channels" :key="channel.id">
            <td>{{ channel.name }}</td>
            <td>{{ channel.description }}</td>
            <td>{{ channel.project ? channel.project.name : "" }}</td>
            <td
              class="d-flex gap-3 justify-content-around actions-column mx-2"
              style="margin-top: 3px; border-bottom: none"
            >
              <button
                class="buttons-group"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasEditChannelForm"
                aria-controls="offcanvasEditChannelForm"
                @click="toggleEditChannelForm(channel)"
              >
                <PencilSquareIcon
                  class="text-blue-500 d-flex justify-content-center"
                  style="color: #517fa4; width: 23px; height: 23px"
                ></PencilSquareIcon>
              </button>
              <button
                class="buttons-group delete"
                @click="deleteChannelFunction(channel.channelsId)"
              >
                <TrashIcon
                  class="text-blue-500 d-flex justify-content-center"
                  style="width: 23px; height: 23px"
                ></TrashIcon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="showDeleteAlert"
        class="alert alert-dark alert-dismissible fade show"
        role="alert"
      >
        El Canal {{ deletedChannelName }} ha sido eliminado exitosamente.
        <button
          type="button"
          class="btn-close"
          @click="showDeleteAlert = false"
          aria-label="Close"
        ></button>
      </div>
      <!-- Alerta de eliminación -->
    </div>
    <div
      class="offcanvas offcanvas-end"
      style="width: 45%"
      data-bs-scroll="true"
      tabindex="-1"
      id="offcanvasEditChannelForm"
      aria-labelledby="offcanvasEditChannelFormLabel"
      ref="offcanvasEditChannelFormRef"
    >
      <div class="offcanvas-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body p-4" v-if="showEditChannelForm">
        <CreateChannelForm
          :offcanvasRef="offcanvasEditChannelFormRef"
          :channelDataProp="selectedChannel"
          @updateChannel="handleKeySaved"
          @cancelEdit="closeOffcanvas"
        ></CreateChannelForm>
      </div>
      <!-- *Mensaje -->
      <div
        v-if="showSuccessMessage"
        class="offcanvas-body"
        style="text-align: center; margin-top: 8rem"
      >
        <CheckCircleIcon
          style="width: 5rem; height: 5rem; color: #517fa4"
        ></CheckCircleIcon>
        <h4 class="create-title mt-3">El Canal se editó con éxito.</h4>
        <button
          class="globals-border-button"
          data-bs-dismiss="offcanvas"
          style="margin-top: 3rem"
        >
          Salir
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.actions-column {
  position: sticky;
  right: 0;
  margin-left: 4px;
  margin-right: 4px;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
}
.delete:hover {
  background-color: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
}
.rounded-lg {
  border-radius: 10px;
  overflow: hidden;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px 15px 6px 15px;
  margin-top: 3px;
  margin: 4px;
  text-align: start;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

th {
  background-color: #ddd;
  color: #243949;
  text-align: start;
  padding-top: 3px;
  padding-bottom: 4px;
  margin: 3px 2px 3px 2px;
}

.under-th {
  height: 10px;
  font-size: x-small;
  padding: 2px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
</style>
