import axios from "axios";
import { dataEnv } from '../../config/index.js'
const API_BASE_URL= dataEnv().API_URL

/**
 * Function to create user
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createUser = (payload) => {
  return axios.post(`${API_BASE_URL}/user`, payload);
};

/**
 * Function to get users
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getUsers = (client_id) => {
  return axios.get(`${API_BASE_URL}/user/${client_id}/client`);
};

/**
 * Function to edit user
 * @param {Object} payload
 * @return AxiosPromise
 */
export const editUser = (user_id, payload) => {
  return axios.patch(`${API_BASE_URL}/user/${user_id}`, payload);
};

/**
 * Function to delete user
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deleteUser = (user_id, payload) => {
  return axios.delete(`${API_BASE_URL}/user/${user_id}`, payload);
};
