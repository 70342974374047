<script setup>
import CreateContractForm from "@/components/Contracts/CreateContractForm.vue";
import { useRoute } from "vue-router";

const route = useRoute();
console.log("Contract CREATE PAPA");
</script>
<template>
  <div v-if="route.name === 'DashboardContractCreate'" class="">
    <h5 class="create-title my-4">Generar Contrato</h5>
    <div>
      <CreateContractForm />
    </div>
  </div>
</template>
