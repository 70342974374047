<script setup>
import { useRoute } from 'vue-router';
import ClientsTable from '@/components/Clients/ClientsTable.vue';

const route = useRoute()
console.log("RUTA CREATE Client PAPA")

</script>
<template>
    <div class="">
        <h5 class="create-title my-4">Clientes Registrados</h5>
        <div>
            <ClientsTable></ClientsTable>
        </div>
    </div>
</template>