<script setup>
import { createAttribute } from '@/services/data';
import { ref, onMounted, defineEmits, defineProps, reactive } from 'vue';

const emit = defineEmits(['keySaved']);
// add nueva prop para la tabla
const props = defineProps(['newAttributeData']);

const isDropdownOpen = ref(false);
const attributeCreated = ref(false);

const attributeData= reactive ({
  name: '',
  keytypes_id: '',
  clients_id: '',
  user_id: '',
  description: ''
})

function handleSubmit() {
    console.log('Form submitted!');
    console.log('Rule Data:', attributeData);

    const payload = {
      name: attributeData.name,
      keytypes_id: 4,
      clients_id: localStorage.getItem('cx'),
      user_id: 1,
      description: attributeData.description
    };
    console.log('Payload:', payload);
    // Llamar a la función para crear la regla en la bd
    createAttribute(payload)
        .then(response => {
        console.log('Atributo creado exitosamente:', response.data);
        attributeCreated.value = true; 
        // EmitIr el evento
        emit('keySaved', response.data);
          // Cerrar el componente
          isDropdownOpen.value = false;
        })
        .catch(error => {
        console.error('Error al crear el atributo:', error);
        if (error.response) {
            // El servidor respondió con un código de error
            console.log('Código de estado HTTP:', error.response.status);
            console.log('Respuesta del servidor:', error.response.data);
        } else if (error.request) {
            // La solicitud se hizo pero no se recibió respuesta
            console.log('No se recibió respuesta del servidor');
        } else {
            // Error al configurar la solicitud
            console.log('Error al configurar la solicitud:', error.message);
        }
        });

}

/* Click fuera del dropdown */
onMounted(() => { 
  document.addEventListener('click', (event) => {
    if (!event.target.closest('.dropdown')) {
      isDropdownOpen.value = false;
    }
  });
}); 

</script>
<template>
        <div class="modal-form">
            <article>
                <div class="mb-4">
                    <h2 class="create-title">Nuevo Atributo</h2>
                    <h5>Configura los datos de tu atributo</h5>
                </div>
                <form class="my-3" @submit.prevent="handleSubmit">
                    <div class="mb-3">
                        <label for="nombre" class="form-label globals-label" style="font-size: 16px;">Nombre del Atributo</label>
                        <input v-model="attributeData.name" type="name" class="form-control globals-input" id="nombreLlave" ref="nombreLlave"  placeholder="Asigna un nombre a tu Atributo">
                    </div>
                    <div class="mb-3">
                        <label for="descripcion" class="form-label globals-label" style="font-size: 16px;">Descripción</label>
                        <textarea v-model="attributeData.description" id="descripcion" ref="descripcion" class="form-control globals-input rows-5 overflow-auto" style="border-radius: 10px;" placeholder="Escribe una breve descripción"></textarea>
                    </div>
                    <div class="d-flex justify-content-center my-5" style="max-height: 2.8rem;">
                            <button class="globals-button" @click="handleSubmit">
                                Crear Atributo
                            </button>
                    </div>
                </form>
            </article>
        </div>
</template>
<style scoped>
.modal-form {
  background-color: white;
  padding: 20px;
  width: 100%;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr)); /* Ajusta el ancho de las columnas automáticamente */
  gap: 10px; /* Espacio entre los elementos */
}
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    display: flex;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    margin-left: 3.5rem;
    z-index: 100;
    background: white;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

</style>