<script setup>
import { computed } from "vue";
import { useRoute } from 'vue-router'
import CreateClient from './CreateClient.vue';
import EditClient from "./EditClient.vue";

const route = useRoute()

const showCreateClient = computed(() => {
  return route.name === 'DashboardClientCreate';
});

const showEditClient = computed(() => {
  return !showCreateClient.value && (route.name === 'DashboardClients' || route.name === 'DashboardClientEdit');
});

console.log("DASHBOARD CLIENTS");
</script>
<template>
    <div class="d-flex justify-content-end" style="">
        <div class="px-4" style="width: 75%;">
            <h2 class="dashboard-title">Clientes</h2>
            <CreateClient v-if="showCreateClient" :route="route" ></CreateClient>
            <EditClient v-if="showEditClient" :route="route" ></EditClient>
        </div>
    </div>
</template>
<style scoped>
</style>
