<script setup>
import { ref } from 'vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import GenerateCredential from '@/components/Settings/GenerateCredential.vue'

const searchTerm = ref('');
/* const filteredCardAttributes = computed(() => {
  return cardAttributes.value.filter(card => card.attribute.toLowerCase().includes(searchTerm.value.toLowerCase()));
}); */
const showGenerateCredential = ref(false);
function generateCredential() {
  showGenerateCredential.value = !showGenerateCredential.value;
}
</script>
<template>
    <div>
        <h2 class="create-title my-4" style="font-size: xx-large;">Credenciales</h2>
        <div class="d-flex align-items-center justify-content-between">
            <h5 style="color: #243949;">Selecciona la plataforma y genera conexiones.</h5>
            <div class="position-relative">
                <div style="margin-top: 20px;margin-right: 5rem; max-width: 20rem;">
                    <input
                    class="form-control globals-input input-with-icon"
                    style="height: 2.5rem;"
                    type="text"
                    id="searchAttribute"
                    name="searchAttribute"
                    placeholder="Busca por nombre de plataforma"
                    v-model="searchTerm"
                    />
                    <div class="search-icon">
                        <MagnifyingGlassIcon class="text-blue-500 d-flex justify-content-center" style="width: 20px; height: 20px;"></MagnifyingGlassIcon>
                    </div>
                </div>
            </div>
        </div>

        <div class="match-rate mt-4" style="margin-left: 1rem;">
            <div class="d-flex justify-content-around align-items-center gap-3">
                <div style="width: 21rem;">
                    <div class="d-flex justify-content-center" style="position: relative;">
                        <div style="background: linear-gradient(to right, #243949 0%, #517fa4 100%); border-radius: 50%; width: 12rem; height: 12rem;"></div>
                        <div class="d-flex flex-column text-center"style="position: absolute;  bottom: 20px;">
                            <h3 style="color: rgb(216, 224, 228); font-weight: 600; text-wrap: nowrap;">Golden Contactability</h3>
                            <h2 style="color: rgb(216, 224, 228); font-weight: 800; font-size: 2.3rem;" >S3</h2>
                        </div>
                    </div>
                </div>
                <p style="width: 15rem; color: white; text-align: justify; font-size: medium; margin: 0;">
                    Genera tu credencial para poder tener acceso a Golden Contactability S3
                    y poder subir tus archivos de Fuentes mayores a 15MB.
                </p>
            </div>
            <div class="d-flex mt-5" style="max-height: 2.8rem;">
                <button class="globals-border-button-dark" @click="generateCredential">
                    Sources
                </button>
            </div>
        </div>
    </div>
    <transition name="slide-fade-up">
        <div v-show="showGenerateCredential" class="position-absolute h-100 create-font-overlay" style="top: 7rem; width: 70%;" @click.self="generateCredential">
            <div class="position-relative">
                <GenerateCredential class=""></GenerateCredential>
            </div>
        </div>
    </transition>
</template>
<style scoped>
.input-with-icon {
  padding-right: 40px;
}

.search-icon {
  position:absolute;
  top: 30px;
  left: 220px;
  /* transform: translateX(85%); */
  pointer-events: none;
}
.match-rate{
    background-color: #182631;
    border-radius: 15px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 42rem;
}
.slide-fade-up-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-up-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-up-enter-from, .slide-fade-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.create-font-overlay {
  background-color: white;
}
</style>