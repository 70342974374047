import axios from "axios";
import { dataEnv } from '../../config/index.js'
const API_BASE_URL= dataEnv().API_URL

//STRUCTURE
/**
 * Function to create attribute
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createAttribute = (payload) => {
    return axios.post(`${API_BASE_URL}/attributesdatasource`,payload)
  }

/**
 * Function to get attribute
 * @param {Object} payload
 * @return AxiosPromise
 */
export const getAttributes = (client_id) => {
    return axios.get(`${API_BASE_URL}/attributesdatasource/client/${client_id}`)
  }

  /**
 * Function to delete attribute
 * @param {Object} payload
 * @return AxiosPromise
 */
export const deleteAttribute = (attributesdatasource_id, payload) => {
    return axios.delete(`${API_BASE_URL}/attributesdatasource/${attributesdatasource_id}`, payload)
  }

//key
/**
 * Function to create key
 * @param {Object} payload
 * @return AxiosPromise
 */
export const createKey = (payload) => {
    return axios.post(`${API_BASE_URL}/keytypes`,payload)
  }
