<script setup>
import { createClient } from "@/services/clients";
import { ref, reactive } from "vue";
import CreatedClient from "../Clients/CreatedClient.vue";

const clientCreated = ref(false);
const emailError = ref("");
const formError = ref("");

const clientData = reactive({
  clientsId: "",
  companyName: "",
  contactName: {
    name: "",
    lastName: "",
  },
  contactPhone: "",
  contactEmail: "",
});

function handleSubmitClient() {
  if (isFormComplete()) {
    console.log("Form submitted!");
    console.log("Client Data:", clientData);

    if (validateEmail()) {
      console.log("Email es válido");
      const payload = {
        clientsId: localStorage.getItem("cx"),
        companyName: clientData.companyName,
        contactName: `${clientData.contactName.name} ${clientData.contactName.lastName}`,
        contactPhone: clientData.contactPhone,
        contactEmail: clientData.contactEmail,
      };
      console.log("Payload:", payload);

      createClient(payload)
        .then((response) => {
          console.log("Client creado exitosamente:", response.data);
          clientCreated.value = true;
        })
        .catch((error) => {
          console.error("Error al crear usuario:", error);
          if (error.response) {
            console.log("Código de estado HTTP:", error.response.status);
            console.log("Respuesta del servidor:", error.response.data);
          } else if (error.request) {
            console.log("No se recibió respuesta del servidor");
          } else {
            console.log("Error al configurar la solicitud:", error.message);
          }
        });
    } else {
      console.error("El correo electrónico no es válido.");
    }
  } else {
    formError.value = "Por favor, completa todos los campos para continuar.";
  }
}

// validar formato de correo electrónico
function validateEmail() {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = emailRegex.test(clientData.contactEmail);
  if (!isValid) {
    emailError.value = "Por favor, ingresa un correo electrónico válido.";
  } else {
    emailError.value = "";
  }
  console.log("EMAILERROR STATUS:", emailError.value);
  return isValid;
}

function isFormComplete() {
  return (
    clientData.companyName &&
    clientData.contactName.name &&
    clientData.contactName.lastName &&
    clientData.contactPhone &&
    clientData.contactEmail
  );
}

function resetEmailError() {
  emailError.value = "";
  formError.value = "";
}
</script>

<template>
  <div>
    <article>
      <form @submit.prevent="handleSubmitClient">
        <div class="mb-3">
          <label
            for="companyName"
            class="form-label globals-label"
            style="font-size: 16px"
            >Nombre de la Empresa</label
          >
          <input
            type="text"
            class="form-control globals-input text-capitalize"
            id="companyName"
            placeholder="Nombre de la Empresa"
            v-model="clientData.companyName"
          />
        </div>
        <div>
          <label
            for="contactName"
            class="form-label globals-label"
            style="font-size: 16px"
            >Nombre de Contacto</label
          >
          <div class="mb-3 d-flex gap-2">
            <input
              type="text"
              class="form-control globals-input text-capitalize"
              id="contactFirstName"
              placeholder="Nombre"
              v-model="clientData.contactName.name"
            />
            <input
              type="text"
              class="form-control globals-input text-capitalize"
              id="contactLastName"
              placeholder="Apellido"
              v-model="clientData.contactName.lastName"
            />
          </div>
        </div>
        <div class="mb-3">
          <label
            for="contactPhone"
            class="form-label globals-label"
            style="font-size: 16px"
            >Teléfono de Contacto</label
          >
          <input
            type="text"
            class="form-control globals-input"
            id="contactPhone"
            placeholder="Teléfono"
            v-model="clientData.contactPhone"
          />
        </div>
        <div class="mb-3">
          <label
            for="contactEmail"
            class="form-label globals-label"
            style="font-size: 16px"
            >Email</label
          >
          <input
            type="email"
            class="form-control globals-input"
            id="contactEmail"
            placeholder="Email"
            v-model="clientData.contactEmail"
            @input="resetEmailError"
          />
          <span v-if="emailError" class="text-danger" style="font-size: 14px">{{
            emailError
          }}</span>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 3rem;
          "
        >
          <span v-if="formError" class="text-danger" style="font-size: 14px">{{
            formError
          }}</span>
        </div>
        <div class="d-flex justify-content-center my-3">
          <button type="submit" class="globals-button">Crear Cliente</button>
        </div>
      </form>
    </article>
    <div v-if="clientCreated" class="modal-background">
      <div class="modal-content">
        <CreatedClient></CreatedClient>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  gap: 10px;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin-left: 3.5rem;
  z-index: 100;
  background: white;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
</style>
