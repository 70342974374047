<script setup>
import { ref, onMounted } from "vue";
import Card from "@/components/common/Card.vue";
import csvIcon from "@/assets/csvIcon.svg";

import { ChevronLeftIcon } from "@heroicons/vue/24/solid";
import UploadCSVStep2 from "./UploadCSVStep2.vue";

const isDropdownOpen = ref(false);
/* const selectedKey = ref(null); */
const isComponentVisible = ref(true);
/* const keys = ref({});

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
}; */

/* Click fuera del dropdown */
onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!event.target.closest(".dropdown")) {
      isDropdownOpen.value = false;
    }
  });
});

/* function selectKey(key) {
  selectedKey.value = key;
} */

function handleRegresarClick() {
  isComponentVisible.value = false;
}

const handleCloseModal = () => {
  isComponentVisible.value = false; // Cerrar la ventana modal
};

const cardElements = ref([
  {
    img: csvIcon,
    title: "Archivo CSV",
    text: "Carga las audiencias de tus eventos, webinars, o cualquier base de datos de forma manual para activarlas en tus campañas de medios digitales.",
    button: "Conectar",
    selected: false,
  },
]);

const showComponent1 = ref(false);
const showComponent2 = ref(false);
const showComponent3 = ref(false);

function handleCardButtonClick(title) {
  showComponent1.value = false;
  showComponent2.value = false;
  showComponent3.value = false;
  switch (title) {
    case "Archivo CSV":
      showComponent1.value = true;
      break;
  }
}
</script>
<template>
  <div style="background: white" v-if="isComponentVisible">
    <article style="width: 100%">
      <h5 class="create-title my-4">Listas / Selecciona la plataforma</h5>
      <div class="d-flex gap-5">
        <p
          style="
            margin-top: 20px;
            color: rgb(125, 143, 152);
            text-align: justify;
          "
        >
          Selecciona la plataforma para subir una fuente.
        </p>
        <!-- <div class="w-auto pt-3">
          <div class="dropdown z-2 form-control globals-input">
            <div
              class="dropdown-toggle d-flex gap-3 align-items-center"
              style="font-size: small; gap: 10px; color: #99bbd6"
              @click="toggleDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ selectedKey ? selectedKey : "Tipo de Fuente" }}
            </div>
            <ul
              class="dropdown-menu mt-3 px-4"
              :class="{ show: isDropdownOpen }"
            >
              <li>
                <a class="dropdown-item" @click="selectKey('Tipo 1')">Tipo 1</a>
              </li>
              <li>
                <a class="dropdown-item" @click="selectKey('Tipo 2')">Tipo 2</a>
              </li>
              <li>
                <a class="dropdown-item" @click="selectKey('Tipo 3')">Tipo 3</a>
              </li>
              <li>
                <a class="dropdown-item" @click="selectKey('Tipo 4')">Tipo 4</a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
      <section class="card-section">
        <div class="card-row">
          <div
            style="
              width: 70rem;
              margin-right: 3rem;
              display: flex;
              justify-content: center;
              margin-bottom: 8rem;
            "
          >
            <Card
              v-for="(card, index) in cardElements"
              :key="index"
              :img="card.img"
              :title="card.title"
              :text="card.text"
              :button="card.button"
              :selected="card.selected"
              styleClass="card-style"
              @cardButtonClick="handleCardButtonClick(card.title)"
            />
          </div>
        </div>
      </section>
      <div>
        <button class="buttons-group d-flex mb-3" @click="handleRegresarClick">
          <ChevronLeftIcon
            class="text-blue-500 d-flex justify-content-center"
            style="width: 20px; height: 20px"
          ></ChevronLeftIcon>
          <p class="text">Regresar</p>
        </button>
      </div>
    </article>
    <transition name="slide-fade-up">
      <!-- *Component 1 -->
      <div
        v-if="showComponent1"
        v-show="showComponent1"
        class="position-absolute w-100 h-100 create-font-overlay"
        style="top: 0px; z-index: 2"
        @click.self="handleCardButtonClick"
      >
        <div class="position-relative">
          <UploadCSVStep2 @closeModal="handleCloseModal"></UploadCSVStep2>
        </div>
      </div>
    </transition>
    <!-- <div v-if="showComponent1">
                <UploadCSVStep2></UploadCSVStep2>
            </div> -->
    <div v-if="showComponent2">
      <!-- Componente 2 -->
    </div>
    <div v-if="showComponent3">
      <!-- Componente 3 -->
    </div>
  </div>
</template>
<style scoped>
.slide-fade-up-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-up-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-up-enter-from,
.slide-fade-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.create-font-overlay {
  background-color: white;
  width: 100%;
}
.buttons-group {
  border: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    color: #99bbd6;
  }
}
.text {
  font-size: large;
  font-weight: 600;
  color: #243949;
  &:hover {
    color: #99bbd6;
  }
}
.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.card-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.card-row .card {
  flex: 1;
  margin-bottom: 30px;
  width: 100%;
}

.card-style {
  max-width: 30rem;
}
</style>
