<script setup>
import { onMounted, ref } from 'vue';
import { getProjects } from '@/services/projects';
import { DocumentDuplicateIcon} from '@heroicons/vue/24/outline';

const proyects = ref([]);
const selectedProject = ref(0);
const selectedKey = ref(0);

const selectedMessage = ref(0)
const selectedCode = ref('')
const selectedDate = ref('');
const method_endpoint= ref('POST /api/v1/contact-service')
const url= 'https://api-golden.hexagondata.com/api/v1/contact_service'

onMounted(async () => {
  const client_id = localStorage.getItem("cx");
  const response = await getProjects(client_id);
  console.log("Fetched Projects:", response);
  if (response.data.length) {
    proyects.value = response.data;
  }
  console.log("PROYECTS:", proyects.value);
});

const channelsArray = ref([]);
const messageArray = ref([]);
const handleProjectChange = async (event) => {
   console.log(event.target.value)
    const projectId = event.target.value;
    selectedProject.value = projectId //proyects.value.find(project => project.projectsId === parseInt(projectId));
    console.log('Selected Project:', selectedProject.value); 
    const response = await getProjectsDetails(projectId);
    console.log("Fetched Channels:", response.data.channels);
    channelsArray.value = response.data.channels;
    messageArray.value = response.data.communications
};

const handleKeyChange = async (event, text) => {
    const keyId = event.target.value;
    console.log('text', keyId, text)
  // selectedKey.value = keys.find(key => key.id === parseInt(keyId));
  console.log("Selected Key:", selectedKey.value);
};

const handleTypeChange = async (event) => {
    const keyId = event.target.value;
    console.log(event.target)
};

const handleDateChange = async (event) => {
  selectedDate.value = event.target.value;
  console.log("Selected Date:", selectedDate.value);
};

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

const isTooltipVisible = false;
</script>
<template>
    <div class="article">
        <div class="container-01">
            <h5 class="mb-3 mt-2" style="color: white; font-size: x-large;">Selecciona tu API</h5>
            <div>
                <div class="mb-3 d-flex flex-column gap-2">
                    <p class="titles">Proyecto</p>
                    <select id="week" class="form-select form-select-details text-capitalize" @change="handleProjectChange">
                        <option value="0" style="font-weight: 400; color: #99bbd6;" disabled selected>Selecciona un proyecto</option>
                        <option v-for="proyect in proyects" :key="proyect.projectsId" :value="proyect.projectsId">{{ proyect.name }}</option>
                    </select>
                </div>    
            </div>
            <div>
                <div class="mb-3 d-flex flex-column gap-2">
                    <p class="titles">Canal</p>
                    <select id="channels" class="form-select form-select-details text-capitalize" v-model="selectedKey" @change="handleKeyChange">
                        <option value="0" style="font-weight: 400; color: #99bbd6;" disabled selected>Selecciona una llave</option>
                        <option v-for="channel in channelsArray" :key="channel.channelsId" :value="channel.channelsId">{{ channel.name }}</option>
                    </select>
                </div>
            </div>
            <div>
                <div class="mb-3 d-flex flex-column gap-2">
                    <p class="titles">Tipo de Mensaje</p>
                    <select id="channels" class="form-select form-select-details text-capitalize" v-model="selectedMessage" @change="handleTypeChange">
                        <option value="0" style="font-weight: 400; color: #99bbd6;" disabled selected>Selecciona una opción</option>
                        <option v-for="type in messageArray" :key="type.id" :value="{ id: type.id, text: type.acronym }">{{ type.acronym }}</option>
                    </select>
                </div>
            </div>
            <div>
                <p class="titles">Fecha</p>
                <div class="mb-3"> 
                    <input type="date" class="form-control globals-input" id="exampleInputEmail3" placeholder="Fecha" @change="handleDateChange">
                </div>
            </div>
        </div>
        <div class="container-02">
            <h3 class="mb-3 mt-2" style="color: #243949; font-weight: 600;">SERVICIO DE CONTROL DE CONTACTABILIDAD</h3>
            <h4>Descripción</h4>
            <p>Validación para poder contactar a un usuario.</p>
            <h4 class="mb-3" style="color: #243949; font-size: x-large;">SERVICIO A CONTACTAR</h4>
            <h4> Method and Endpoint</h4>
            <div class="base">
                {{ method_endpoint }} 
                <button @click="copyToClipboard(method_endpoint)"  data-bs-toggle="tooltip" title="Copiar en portapapeles">
                    <DocumentDuplicateIcon style="height: 20px; width: 20px; color: #517fa4;"></DocumentDuplicateIcon>
                </button>
                <div v-if="isTooltipVisible" class="tooltip">
                Este es un tooltip personalizado.
                </div>
            </div>
            <h4> HOST URL</h4>
            <div class="base">
                https://api-golden.hexagondata.com
                <button @click="copyToClipboard('https://xjpa-y4aw-ouvs.n7.xano.io')"  data-bs-toggle="tooltip" title="Copiar en portapapeles">
                    <DocumentDuplicateIcon style="height: 20px; width: 20px; color: #517fa4;"></DocumentDuplicateIcon>
                </button>
            </div>
            <h4> Request URL</h4>
            <div class="base">
                {{ url }}
                <button @click="copyToClipboard(url)"  data-bs-toggle="tooltip" title="Copiar en portapapeles">
                    <DocumentDuplicateIcon style="height: 20px; width: 20px; color: #517fa4;"></DocumentDuplicateIcon>
                </button>
            </div>
            <h4 > Ejemplo</h4>
            <div class="base-02">
                <div>
                    <span class="curl">curl</span>
                </div>
                <div>
                    <div><span class="letter">POST</span> /api/v1/contact-service HTTP/1.1</div>
                    <div><span class="letter">Host:</span> api-golden.hexagondata.com </div>
                    <div><span class="letter">Content-Type:</span> application/json </div>
                    <div>{{ url }}</div>
                </div>
            </div>
            <h4 > Data </h4>
            <div class="base"> 
                <!-- AQUI VENDRA LA INFORMACION SELECCIONADA -->
                <div style="font-size: larger;">
                    {<br>
                        <span class="data">
                            "project": <span class="number">{{ selectedProject ? selectedProject : '' }}</span> ,<br>
                        </span>
                        <span class="data">
                            "channel": <span class="number">{{ selectedKey ? selectedKey : '' }}</span> ,<br>
                        </span>
                        <span class="data">
                            "value": "{ <span class="number">valor_usuario</span> }",<br>
                        </span>
                        <span class="data">
                            "code":  <span class="number">{{ selectedMessage ? selectedMessage.text : '' }}</span> ,<br>
                        </span>
                        <span class="data">
                            "date": "<span class="number">{{ selectedDate }}</span>"<br>
                        </span>

                    }
                </div>
            </div>
            <div class="mt-4">
                <h4 class="mt-2" style="color: #243949; font-size: x-large;">SERVICIO DE CONTROL DE SEGUIMIENTO</h4>
                <h4 class="mb-3" style="color: #243949; font-size: x-large;">Servicio Usuario Contactado </h4>
            </div>
            <h4> Method and Endpoint</h4>
            <div class="base">
                POST /api/v1/contact-service-full
                <button @click="copyToClipboard(method_endpoint)"  data-bs-toggle="tooltip" title="Copiar en portapapeles">
                    <DocumentDuplicateIcon style="height: 20px; width: 20px; color: #517fa4;"></DocumentDuplicateIcon>
                </button>
                <div v-if="isTooltipVisible" class="tooltip">
                Este es un tooltip personalizado.
                </div>
            </div>
            <h4> HOST URL</h4>
            <div class="base">
                 https://api-golden.hexagondata.com/api/v1/contact-service-full
                <button @click="copyToClipboard(' https://api-golden.hexagondata.com/api/v1/contact-service-full')"  data-bs-toggle="tooltip" title="Copiar en portapapeles">
                    <DocumentDuplicateIcon style="height: 20px; width: 20px; color: #517fa4;"></DocumentDuplicateIcon>
                </button>
            </div>
            <h4> Request URL</h4>
            <div class="base">
               https://api-golden.hexagondata.com/api/v1/contact-service-full
                <button @click="copyToClipboard('https://api-golden.hexagondata.com/api/v1/is-contactability')"  data-bs-toggle="tooltip" title="Copiar en portapapeles">
                    <DocumentDuplicateIcon style="height: 20px; width: 20px; color: #517fa4;"></DocumentDuplicateIcon>
                </button>
            </div>
            <h4 > Ejemplo</h4>
            <div class="base-02">
                <div>
                    <span class="curl">curl</span>
                </div>
                <div>
                    <div><span class="letter">POST</span> /api/v1/is-contactability HTTP/1.1</div>
                    <div><span class="letter">Host:</span> api-golden.hexagondata.com </div>
                    <div><span class="letter">Content-Type:</span> application/json </div>
                    <div>https://api-golden.hexagondata.com/api/v1/is-contactability</div>
                </div>
            </div>
            <h4 > Data </h4>
            <div class="base"> 
                <!-- AQUI VENDRA LA INFORMACION SELECCIONADA -->
                <div style="font-size: larger;">
                    {<br>
                        <span class="data">
                            "project": <span class="number">{{ selectedProject ? selectedProject.id : '' }}</span> ,<br>
                        </span>
                        <span class="data">
                            "channel": <span class="number">{{ selectedKey ? selectedKey : '' }}</span> ,<br>
                        </span>
                        <span class="data">
                            "value": "{ <span class="number">valor_usuario</span> }",<br>
                        </span>
                        <span class="data">
                            "code":  <span class="number">{{ selectedMessage ? selectedMessage.text : '' }}</span> ,<br>
                        </span>
                        <span class="data">
                            "date": "<span class="number">{{ selectedDate }}</span>"<br>
                        </span>
          }
        </div>
      </div>
      <div class="mt-4">
        <h4 class="mt-2" style="color: #243949; font-size: x-large">
          SERVICIO DE CONTROL DE SEGUIMIENTO
        </h4>
        <h4 class="mb-3" style="color: #243949; font-size: x-large">
          Servicio Usuario Contactado
        </h4>
      </div>
      <h4>Method and Endpoint</h4>
      <div class="base">
        POST /api:0gS8KoXP/is-contactability
        <button
          @click="copyToClipboard(method_endpoint)"
          data-bs-toggle="tooltip"
          title="Copiar en portapapeles"
        >
          <DocumentDuplicateIcon
            style="height: 20px; width: 20px; color: #517fa4"
          ></DocumentDuplicateIcon>
        </button>
        <div v-if="isTooltipVisible" class="tooltip">
          Este es un tooltip personalizado.
        </div>
      </div>
      <h4>HOST URL</h4>
      <div class="base">
        https://xjpa-y4aw-ouvs.n7.xano.io
        <button
          @click="copyToClipboard('https://xjpa-y4aw-ouvs.n7.xano.io')"
          data-bs-toggle="tooltip"
          title="Copiar en portapapeles"
        >
          <DocumentDuplicateIcon
            style="height: 20px; width: 20px; color: #517fa4"
          ></DocumentDuplicateIcon>
        </button>
      </div>
      <h4>Request URL</h4>
      <div class="base">
        https://api-golden.hexagondata.com/api/v1/is-contactability
        <button
          @click="
            copyToClipboard(
              'https://api-golden.hexagondata.com/api/v1/is-contactability'
            )
          "
          data-bs-toggle="tooltip"
          title="Copiar en portapapeles"
        >
          <DocumentDuplicateIcon
            style="height: 20px; width: 20px; color: #517fa4"
          ></DocumentDuplicateIcon>
        </button>
      </div>
      <h4>Ejemplo</h4>
      <div class="base-02">
        <div>
          <span class="curl">curl</span>
        </div>
        <div>
          <div>
            <span class="letter">POST</span> /api:0gS8KoXP/is-contactability
            HTTP/1.1
          </div>
          <div><span class="letter">Host:</span> xjpa-y4aw-ouvs.n7.xano.io</div>
          <div><span class="letter">Content-Type:</span> application/json</div>
          <div>https://api-golden.hexagondata.com/api/v1/is-contactability</div>
        </div>
      </div>
      <h4>Data</h4>
      <div class="base">
        <!-- AQUI VENDRA LA INFORMACION SELECCIONADA -->
        <div style="font-size: larger">
          {<br />
          <span class="data">
            "client":
            <span class="number">{{
              selectedProject ? selectedProject.clients_id : ""
            }}</span>
            ,<br />
          </span>
          <span class="data">
            "project":
            <span class="number">{{
              selectedProject ? selectedProject.id : ""
            }}</span>
            ,<br />
          </span>
          <span class="data">
            "channel":
            <span class="number">{{ selectedKey ? selectedKey : "" }}</span>
            ,<br />
          </span>
          <span class="data">
            "value": "{ <span class="number">valor_usuario</span> }",<br />
          </span>
          <span class="data">
            "code":
            <span class="number">{{
              selectedMessage ? selectedMessage.text : ""
            }}</span>
            ,<br />
          </span>
          <span class="data">
            "date": "<span class="number">{{ selectedDate }}</span
            >"<br />
          </span>

          }
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.data {
  padding-left: 1rem;
  .number {
    color: #517fa4;
    font-weight: 600;
  }
}
.letter {
  color: rgb(255, 94, 0);
  margin-right: 10px;
}
.curl {
  margin-right: 15px;
  color: rgb(255, 183, 0);
}
.container-02 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 2rem;
  width: 70%;
  h4 {
    color: #243949;
    font-weight: 600;
  }
  .base {
    display: flex;
    justify-content: space-between;
    background: white;
    border-radius: 5px;
    color: grey;
    padding: 5px 10px;
    font-family: "Hind", sans-serif;
    font-style: oblique;
    width: auto;
    max-width: 80%;
    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
  .base-02 {
    display: flex;
    background: #182631;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    font-family: "Hind", sans-serif;
    font-style: oblique;
    width: auto;
    max-width: 80%;
  }
}
.titles {
  font-size: medium;
  font-weight: 600;
}
.article {
  display: flex;
  background-color: rgb(239, 242, 245);
  border-radius: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 10px;
}
.container-01 {
  background: #243949;
  border-radius: 20px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: larger;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
  padding: 4rem 2rem;
}
</style>
